import React from 'react';
import Header from '../../_components/header/header';
import Footer from '../../_components/footer/footer';
import { Col, Row, Container } from 'reactstrap';
import LeftMenu from '../../_components/cards/LeftMenu';
import WalletCard from '../../_components/cards/WalletCard';
import graphQLRequest from '../../services/graphQlRequest';
import myProfile from '../../services/Queries/profile/myProfile';
import { getValue } from '../../utils/lodash';
import { selectCurrentUser } from '../../redux/_selectors/userSelectors';
import { connect } from 'react-redux';
class Wallet extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            walletInfo:{}
        }
    }
    componentDidMount(){
        window.scroll({ top: 0, behavior: "smooth" });
        this.getWalletInfo()
        if (
            !this.props.auth.isAuthenticated &&
            getValue(localStorage, "jwt.length", 0) === 0
          ) {
            this.props.history.push("/");
          }
    }
    
    getWalletInfo = async() =>{
        let resp = await graphQLRequest(myProfile);
        if(resp){
            this.setState({
                walletInfo:getValue(resp,"myProfile.wallet_info",{})
            })
        }
    }
    render() {
        return (
            <>
                {/* <Header /> */}
                <Container fluid={true}>
                    <section className="dashboard-page dashboard-page-resp">
                        <h2 className="dashboard-heading">My Wallet</h2>
                        <Row><Col md={{ size: "3" }} className="d-none d-md-block">
                                <LeftMenu />
                            </Col>
                            <Col md={{ size: "9" }}>
                                <WalletCard 
                                walletInfo = {getValue(this.state,"walletInfo",{})}
                                />
                            </Col>
                            
                        </Row>
                    </section>
                </Container>
                {/* <Footer /> */}
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      auth: selectCurrentUser(state),
    };
  };
  export default connect(mapStateToProps)(Wallet);
