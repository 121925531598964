// ******************************************** //
// *********** Payment page   **************** //
// ******************************************** //

import React, { Component, useState, useRef, useEffect } from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import PaymentCard from "../../_components/cards/PaymentCard";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";
import { connect } from "react-redux";
import { getValue } from "../../utils/lodash";
import graphQLRequest from "../../services/graphQlRequest";
import myCart from "../../services/Queries/cart/common/myCart";
import fetchPlaceOrder from "../../services/Queries/cart/fetchPlaceOrder";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import myProfile from "../../services/Queries/profile/myProfile";
import verifyOrder from "../../services/Queries/cart/verifyOrder";
import fetchUpdateCartWalletDiscount from "../../services/Queries/cart/fetchUpdateCartWalletDiscount";
import fetchUpdateCartBasicInfo from "../../services/Queries/cart/fetchUpdateCartBasicInfo";

class PaymentPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.formEl = React.createRef();
    this.state = {
      shippingAddress: [],
      cartDetails: [],
      orderInfo: {},
      userDetails: {},
      is_donated: true,
      walletInput: "",
      inputFields: null,
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getCartData();
    this.getProfile();
    // this.placeOrderMethod();
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    }
    const payment_status = new URLSearchParams(this.props.location.search).get(
      "status"
    );
    if (payment_status === "FAILED") {
      toast.error(new URLSearchParams(this.props.location.search).get("error"));
    }
  }

  componentDidUpdate() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
    }
  }
  getCartData = async () => {
    let resp = await graphQLRequest(myCart);
    if (resp) {
      this.setState({
        cartDetails: getValue(resp, "myCart.cart", []),
        is_donated: getValue(resp, "myCart.cart.is_donated", false),
      });
    }
  };

  placeOrderMethod = async () => {
    let resp = await fetchPlaceOrder(Cookies.get("accountCartId"));
    if (resp) {
      this.setState({
        orderInfo: getValue(resp, "placeOrder", {}),
      });
    }
    this.setState({ ...this.state });
  };

  updateCart = async () => {
    let request = {
      firstname: getValue(this.state, "userDetails.firstname", ""),
      lastname: getValue(this.state, "userDetails.lastname", ""),
      email: getValue(this.state, "userDetails.user.email", ""),
      phone: getValue(this.state, "userDetails.phone", ""),
    };
    let basicInfo = await fetchUpdateCartBasicInfo(
      Cookies.get("accountCartId"),
      request
    );
  };

  RequestOrderPayment = async () => {
    this.updateCart();
    let resp = await fetchPlaceOrder(Cookies.get("accountCartId"));
    if (resp && resp.placeOrder) {
      this.setState({ inputFields: resp.placeOrder });
      this.formEl.current.submit();
    }
  };

  getProfile = async () => {
    let resp = await graphQLRequest(myProfile);
    if (resp) {
      this.setState({
        userDetails: getValue(resp, "myProfile", {}),
      });
    }
  };

  updateWallet = async () => {
    if (this.state.walletInput) {
      const resp = await fetchUpdateCartWalletDiscount(
        Cookies.get("accountCartId"),
        parseFloat(this.state.walletInput)
      );
      if (resp) {
        this.getCartData();
        window.location.reload();
      }
    } else {
      toast.error("Please add wallet amount");
    }
  };

  handleChange = (e) => {
    this.setState({
      walletInput: e.target.value,
    });
  };

  render() {
    return (
      <div>
        {/* <Header /> */}
        <PaymentCard
          RequestOrderPayment={this.RequestOrderPayment}
          placeOrderMethod={this.placeOrderMethod}
          orderInfo={this.state.orderInfo}
          userDetails={this.state.userDetails}
          cartDetails={this.state.cartDetails}
          product={this.state.cartDetails.items}
          is_donated={this.state.is_donated}
          walletInput={this.state.walletInput}
          updateWallet={this.updateWallet}
          handleChange={this.handleChange}
        />
        {/* <Footer /> */}
        <form
          hidden
          ref={this.formEl}
          method="POST"
          action="https://test.cashfree.com/billpay/checkout/post/submit"
        >
          {this.state.inputFields &&
            Object.keys(this.state.inputFields).map((key, index) => (
              <input
                key={index}
                name={key}
                value={this.state.inputFields[key]}
              />
            ))}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};

export default connect(mapStateToProps)(PaymentPage);
