// ******************************************** //
// ***********  Orders Page   ******************* //
// ******************************************** //

import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import { Col, Row, Container } from "reactstrap";
import LeftMenu from "../../_components/cards/LeftMenu";
import OrdersCard from "../../_components/cards/OrdersCard";
import graphQLRequest from "../../services/graphQlRequest";
import myOrders from "../../services/Queries/myOders/myOrders";
import { getValue, setValue } from "../../utils/lodash";
import fetchCancelOrder from "../../services/Queries/cancelOrder/fetchCancelOrder";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { NavLink } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";

class Orders extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      myOrders: [],
      cancellationReason: "",
      totalCount: 0,
      limit: 10,
      pageNumber: 0,
      activePage: 1,
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getMyOrders();
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    }
  }
  getMyOrders = async () => {
    let data = {
      limit: this.state.limit,
      start: this.state.pageNumber,
      sort: "createdAt:desc",
    };
    let resp = await graphQLRequest(myOrders, data);
    if (resp) {
      this.setState({
        myOrders: getValue(resp, "myOrders", []),
        totalCount: getValue(resp, "myOrders.total_count", 0),
      });
    }
  };

  cancelOrder = async (id) => {
    let resp = await fetchCancelOrder(id, this.state.cancellationReason);
    if (resp) {
      toast.success("Order cancelled successfully");
      this.getMyOrders();
    } else {
      // toast.error("Something went wrong, Please try later");
    }
  };

  handlePageChange = (pageNumber) => {
    setValue(this.state, "activePage", pageNumber);
    if (parseInt(pageNumber) === 1) {
      setValue(this.state, "pageNumber", pageNumber);
    } else {
      let currentPage =
        (parseInt(pageNumber) - 1) * 10 +
        (parseInt(pageNumber) - parseInt(pageNumber));
      setValue(this.state, "pageNumber", currentPage);
    }
    this.getMyOrders();
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <>
        {/* <Header /> */}
        <Container fluid={true}>
          <section className="dashboard-page dashboard-page-resp">
            <h2 className="dashboard-heading">My Orders</h2>
            <Row>
              <Col md={{ size: "3" }} className="d-none d-md-block">
                <LeftMenu />
              </Col>
              <Col md={{ size: "9" }}>
                {getValue(this.state, "myOrders.orders.length", 0) !== 0 ? (
                  getValue(this.state, "myOrders.orders", []).map(
                    (order, key) => {
                      return (
                        <div key={key}>
                          <OrdersCard
                            order={order}
                            cancelOrder={this.cancelOrder}
                            cancellationReason={this.state.cancellationReason}
                            handleChange={this.handleChange}
                          />
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="mt-4 text-center">
                    <div className="cart-empty">
                      <img src="/images/empty-cart.PNG" alt="" width="110" />
                    </div>
                    <h6 className="text-center mt-5">
                      You currently have no items in your cart, Explore our
                      <br />
                      products and get your chance to win amazing luxury prizes
                    </h6>
                    <div className="mt-5 text-center">
                      <NavLink to="/campaigns">
                        <Button color="white-light" className="px-5 py-2">
                          See All Campaigns
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                )}
                {getValue(this.state, "totalCount", 0) > 10 ? (
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={this.state.totalCount}
                    pageRangeDisplayed={5}
                    onChange={this.handlePageChange}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </section>
        </Container>
        {/* <Footer /> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};
export default connect(mapStateToProps)(Orders);
