import React from 'react';
class SearchSVG extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24.621" height="25.621" viewBox="0 0 24.621 25.621">
                <g id="Search_Icon" data-name="Search Icon" transform="translate(-1549 -39)">
                    <g id="Ellipse_191" data-name="Ellipse 191" transform="translate(1549 39)" fill="none" stroke="#fff" stroke-width="3">
                        <circle cx="11" cy="11" r="11" stroke="none" />
                        <circle cx="11" cy="11" r="9.5" fill="none" />
                    </g>
                    <line id="Line_82" data-name="Line 82" x2="4" y2="4" transform="translate(1567.5 58.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3" />
                </g>
            </svg>
        )
    }
}
export default SearchSVG