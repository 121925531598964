import graphQLRequest from "../../graphQlRequest.js";
import removeFromWishlist from "./removeFromWishlist.js";

/**
 * remove a wishlist by its campiagnid
 *
 * @param {String} campiagnid - The campiagnid to update
 * @returns {Object} wishlist - remove from wishlist
 */ 

export default async function fetchRemoveFromWishlist(campaign_id){
    if (!campaign_id) return { removeFromWishlist: {} };

    const resp = await graphQLRequest(removeFromWishlist, {
            campaign_id
    });

    return resp && resp;
}