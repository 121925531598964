import React from 'react';
import { getValue } from '../../utils/lodash';
class WalletCard extends React.Component {
    render() {
        return (
            <div className="card wallet-card">
                <div className="wallet-card-padding">
                    <img src="/images/wallet.png" className="wallet-image" alt="" width="40" />
                    <h3 className="mb-4">{getValue(this.props,"walletInfo.available_points",0)} Points</h3>
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Earned through purchases</p>
                        <p className="mb-2">{getValue(this.props,"walletInfo.points_by_purchase",0)}</p>
                    </div>
                    <hr className="mt-0 mb-2" />
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Earned through referals</p>
                        <p className="mb-2">{getValue(this.props,"walletInfo.points_by_referral",0)}</p>
                    </div>
                    {/* <hr className="mt-0 mb-2" />
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Total earned</p>
                        <p className="mb-2">0</p>
                    </div> */}
                    <hr className="mt-0 mb-2" />
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Spent</p>
                        <p className="mb-2">{getValue(this.props,"walletInfo.spent_points",0)}</p>
                    </div>
                    <hr className="mt-0 mb-2" />
                </div>
            </div>
        )
    }
}
export default WalletCard