import graphQLRequest from "../../graphQlRequest.js";
import cancelOrder from "./cancelOrder.js";

export default async function fetchCancelOrder(id,cancellation_reason) {
  // if (!is_donated) return { cart: {} };
  const resp = await graphQLRequest(cancelOrder, {
    input: {
      where:{
          id
      },
      data:{
        cancellation_reason
      }
    },
  });

  return resp && resp;
}
