export default `
mutation updateCartBasicInfo($input:updateCartBasicInfoInput!) { 
    updateCartBasicInfo(input:$input){ 
       cart{
           _id
           basic_info{
               _id
               firstname
               lastname
               email
               phone
           }
       }
    }
}
`;
