import React from 'react';
class FavSVG extends React.Component {
    render() {
        return (
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.5745 22.7021L10.8962 21.141C4.93532 15.6179 1 11.9752 1 7.50472C1 3.86208 3.80102 1 7.36596 1C9.37991 1 11.3129 1.95797 12.5745 3.4718C13.8361 1.95797 15.769 1 17.783 1C21.3479 1 24.1489 3.86208 24.1489 7.50472C24.1489 11.9752 20.2136 15.6179 14.2528 21.1528L12.5745 22.7021Z" stroke="black" />
            </svg>
        )
    }
}
export default FavSVG