// ******************************************** //
// *********** Shipping page   **************** //
// ******************************************** //

import React, { Component } from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import ShippingCard from "../../_components/cards/ShippingCard";
import MapContact from "../../_components/sections/MapContact";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";
import { connect } from "react-redux";
import { getValue, setValue } from "../../utils/lodash";
import csc from "country-state-city";
import SimpleReactValidator from "simple-react-validator";
import { RequestModel } from "../../_components/common/RequestModel";
import { handleOnChange } from "../../utils/handleOnChange";
import fetchUpdateCartShippingInfo from "../../services/Queries/cart/fetchUpdateCartShippingInfo";
import Cookies from "js-cookie";
import graphQLRequest from "../../services/graphQlRequest";
import myCart from "../../services/Queries/cart/common/myCart";
import { toast } from "react-toastify";
import myProfile from "../../services/Queries/profile/myProfile";
import removeAddress from "../../services/Queries/profile/removeAddress";
import fetchUpdateCartBasicInfo from "../../services/Queries/cart/fetchUpdateCartBasicInfo";

class Shipping extends Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator();
    this.state = {
      request: RequestModel.SHIPPING_ADDRESS,
      // country-state-city
      country: "",
      state: "",
      city: "",
      countries: [],
      states: [],
      cities: [],
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      address: false,
      addresses: [],
      isDonate:false,
      cartInfo:[]
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    Cookies.set("basicInfo", true);

    // country-state-city
    const countries = csc.getAllCountries();
    this.setState({ countries: countries });
    this.getCart();
    this.getProfile();
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    }

    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        country: "India",
      },
    }));
  }

  getCart = async () => {
    let resp = await graphQLRequest(myCart);
    if (resp) {
      this.setState({
        cartInfo:getValue(resp,"myCart.cart",''),
        isDonate:getValue(resp,"myCart.cart.is_donated",''),
        address: getValue(resp, "myCart.cart.shipping_info.address_line_1", "")
          ? true
          : false,
      });
    }
  };
  getProfile = async () => {
    let resp = await graphQLRequest(myProfile);
    if (resp) {
      this.setState({
        addresses: getValue(resp, "myProfile", []),
      });
    }
  };

  // country-state-city starts //
  handleCountries = (e) => {
    let name = this.state.countries.filter((con) => {
      if (con.id === e.target.value) {
        return con.name;
      }
    });
    if (name) {
      this.setState((prevState) => ({
        ...prevState,
        request: {
          ...prevState.request,
          country: name[0].name,
        },
      }));

      const states = csc.getStatesOfCountry(e.target.value);
      this.setState({ states: states });
    }
  };

  handleRegion = (e) => {
    let name = this.state.states.filter((con) => {
      if (con.id === e.target.value) {
        return con.name;
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        state: name[0].name,
      },
    }));
    const cities = csc.getCitiesOfState(e.target.value);
    this.setState({ cities });
  };
  handleCity = (e) => {
    let name = this.state.cities.filter((con) => {
      if (con.id === e.target.value) {
        return con.name;
      }
    });
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        city: name[0].name,
      },
    }));
  };
  // country state city ends //

  // handle submit
  handleSubmit = async (e) => {
    // if (typeof window !== "undefined") {
    //   console.log(window.dataLayer);
    //   window.dataLayer = window.dataLayer || [];
    //   let itemss = []
    //   getValue(this.state,'cartInfo.items',[]).map((item)=>{
    //     itemss.push({
    //       item_name:item.item.product.title,
    //       item_id:item.id,
    //       price:item.price,
    //       quantity:item.quantity,
    //     })
    //   })
    //   window.dataLayer.push({
    //     event: "begin_checkout",
    //       currency: 'INR',

    //     ecommerce: {
    //       items:itemss,
    //     },
    //   });
    // }

    e.preventDefault();
    let request = {
      firstname: this.state.addresses.firstname,
      lastname: this.state.addresses.lastname,
      email: this.state.addresses.user.email,
      phone: this.state.addresses.phone,
    };
    let basicInfo = await fetchUpdateCartBasicInfo(
      Cookies.get("accountCartId"),
      request
    );
    if (basicInfo) {
      let data = this.state.request;
      if (this.validator.allValid()) {
        let resp = await fetchUpdateCartShippingInfo(
          Cookies.get("accountCartId"),
          data
        );
        if (resp) {
          toast.success("Updated successfully");
          setTimeout(() => {
            this.props.history.replace("/payment");
          }, 1000);
        } else {
          // toast.error("Something went wrong, please try later");
        }
      } else {
        this.validator.showMessages();
        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        this.forceUpdate();
      }
    } else {
      // toast.error("Something went wrong, Please try later");
    }
  };

  // handle change
  handleChange = (e, path) => {
    handleOnChange(e, path, this.state.request);
    this.setState({
      ...this.state,
    });
  };

  handleRemoveAddress = async (id) => {
    let ids = {
      address_id: id,
    };
    let resp = await graphQLRequest(removeAddress, ids);
    if (resp) {
      this.getProfile();
    } else {
      this.getProfile();
    }
  };

  handleEditAddress = async (id) => {
    setValue(this.state, "request.country", "");
    let resp = getValue(this.state, "addresses.addresses", []).filter(
      (item) => item._id === id
    );
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        address_line_1: resp[0].address_line_1,
        address_line_2: resp[0].address_line_2,
        pincode: resp[0].pincode,
        country: resp[0].country,
        state: resp[0].state,
        city: resp[0].city,
      },
    }));
  };

  componentDidUpdate(preProps, preState) {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
    }
    let { country } = this.state.request;
    if (country !== preState.request.country) {
      const countries = csc.getAllCountries();
      const findCountry = countries.filter(
        (ele) =>
          ele.name.toLowerCase() === this.state.request.country.toLowerCase()
      );
      if (findCountry.length !== 0) {
        this.setState({
          selectedCountry: findCountry[0].id,
        });
        const getStates = csc.getStatesOfCountry(
          findCountry && findCountry[0].id
        );
        this.setState({ states: getStates });
        if (this.state.request.state) {
          const findState = getStates.filter(
            (x) =>
              x.name.toLowerCase() === this.state.request.state.toLowerCase()
          );
          if (findState.length !== 0) {
            this.setState({
              selectedState: findState[0].id,
            });
          }
          if (this.state.request.city && findState.length !== 0) {
            const getCities = csc.getCitiesOfState(findState[0].id);
            this.setState({ cities: getCities });

            const findCity = getCities.filter(
              (y) =>
                y.name.toLowerCase() === this.state.request.city.toLowerCase()
            );
            if (findCity.length !== 0) {
              this.setState({
                selectedCity: findCity[0].id,
              });
            }
          }
        }
      }
    }
  }
  render() {
    return (
      <div>
        {/* <Header /> */}
        <ShippingCard
          request={this.state.request}
          validator={this.validator}
          countries={this.state.countries}
          states={this.state.states}
          cities={this.state.cities}
          selectedCountry={this.state.selectedCountry}
          selectedState={this.state.selectedState}
          selectedCity={this.state.selectedCity}
          addresses={this.state.addresses}
          isDonate={this.state.isDonate}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          handleCity={this.handleCity}
          handleCountries={this.handleCountries}
          handleRegion={this.handleRegion}
          handleRemoveAddress={this.handleRemoveAddress}
          handleEditAddress={this.handleEditAddress}
        />

        {/* <Footer /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};

export default connect(mapStateToProps)(Shipping);
