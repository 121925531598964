export default `
mutation updateMyProfile($input:updateUserProfileInput!) { 
    updateMyProfile(input:$input){ 
        firstname
        lastname
        gender
        language
        nationality
        country
        phone
        }
    }
`;