import React from "react";
import { Button, Col, Row, Modal } from "reactstrap";
import { getValue } from "../../utils/lodash";
import { API_URL } from "../../env";
import Input from "reactstrap/lib/Input";
class OrdersCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modal: false,
      id: "",
    };
  }

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      id: id,
    });
  };
  cancelOrder = () => {
    this.props.cancelOrder(this.state.id);
    this.toggle();
  };

  render() {
    return (
      <>
        <div className="card orders-card">
          {getValue(this.props, "order.items", []).map((order, key) => {
            return (
              <>
                <Row className="d-none d-md-flex">
                  <Col md="6">
                    <Row key={key} className="mb-3">
                      <Col xs="6">
                        <img
                          src={
                            API_URL +
                            getValue(
                              order,
                              "campaign.product.images[0].url",
                              ""
                            )
                          }
                          alt=""
                          className="img-fluid"
                        />
                      </Col>
                      <Col
                        xs="6"
                        className="d-flex flex-column justify-content-center"
                      >
                        <h4>
                          ₹ {getValue(order, "campaign.product.price", "")}
                        </h4>
                        <p>{getValue(order, "campaign.product.title", "")}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col xs="6">
                        <img
                          src={
                            API_URL +
                            getValue(order, "campaign.reward.images[0].url", "")
                          }
                          alt=""
                          className="img-fluid"
                        />
                      </Col>
                      <Col
                        xs="6"
                        className="d-flex flex-column justify-content-center"
                      >
                        <h4>Prize</h4>
                        <p>{getValue(order, "campaign.reward.title", "")}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="d-md-none">
                  <Col>
                    <div className="img-prod-2">
                      <img
                        className="gift-img1"
                        src={
                          API_URL + getValue(order, "campaign.image.url", "")
                        }
                        alt=""
                      />
                      {/* <img
                        className="prod-img1"
                        src={
                          API_URL +
                          getValue(order, "campaign.product.images[0].url", "")
                        }
                        alt=""
                      /> */}
                    </div>
                  </Col>
                  <Col className="d-flex flex-column justify-content-center">
                    <h5 className="mb-0">
                      {getValue(order, "campaign.product.title", "")}
                    </h5>
                    <h4 className="mb-0">
                      {getValue(order, "campaign.reward.title", "")}
                    </h4>
                    <h4 className="mb-0 text-blue">
                      ₹ {getValue(order, "campaign.product.price", "")}
                    </h4>
                  </Col>
                </Row>
                <hr className="mb-md-4 mt-3 mt-md-2 mt-3" />
              </>
            );
          })}
          <div className="">
            {/* <h5>
              Order Status:{" "}
              <span className="text-blue">
                {getValue(this.props, "order.order_status", "")
                  ? getValue(this.props, "order.order_status", "")
                  : "-"}
              </span>
            </h5> */}
            <h5>
              {!getValue(this.props, "order.is_donated") ? (
                <>
                  {" "}
                  Tracking Number:{" "}
                  <span className="text-blue">
                    {getValue(this.props, "order.tracking_number", "")
                      ? getValue(this.props, "order.tracking_number", "")
                      : "-"}
                  </span>
                </>
              ) : (
                ""
              )}
            </h5>
            <h5>
              {!getValue(this.props, "order.is_donated") ? (
                <>
                  Tracking Url:{" "}
                  <a
                    className="text-blue"
                    href={getValue(this.props, "order.tracking_link", "")}
                    target="blank"
                  >
                    {getValue(this.props, "order.tracking_link", "")
                      ? getValue(this.props, "order.tracking_link", "")
                      : "-"}
                  </a>
                </>
              ) : (
                ""
              )}
            </h5>
            <h5>
              {!getValue(this.props, "order.is_donated") ? (
                <>
                  Shipping cost:{" "}
                  <a className="text-blue">
                    {getValue(this.props, "order.shipping_price", "") ? (
                      <>Rs {getValue(this.props, "order.shipping_price", "")}</>
                    ) : (
                      "Rs 0"
                    )}
                  </a>
                </>
              ) : (
                ""
              )}
            </h5>
            <h5>
              Order id:{" "}
              <span className="text-blue">
                {getValue(this.props, "order.order_id", "")}
              </span>
            </h5>
            <h5>
              {!getValue(this.props, "order.is_donated") ? (
                <>
                  Shipping Info:{" "}
                  <span className="text-blue">
                    {getValue(
                      this.props,
                      "order.shipping_info.address_line_1",
                      ""
                    )}
                    ,{" "}
                    {getValue(
                      this.props,
                      "order.shipping_info.address_line_2",
                      ""
                    )}
                    , {getValue(this.props, "order.shipping_info.country", "")},{" "}
                    {getValue(this.props, "order.shipping_info.state", "")},{" "}
                    {getValue(this.props, "order.shipping_info.city", "")}
                  </span>
                </>
              ) : (
                ""
              )}
            </h5>

            {/* {getValue(this.props, "order.order_status", "") !== "Cancelled" ? (
              <Button
                className="mt-1"
                color="red"
                onClick={() =>
                  this.toggle(getValue(this.props, "order._id", ""))
                }
              >
                Cancel Order
              </Button>
            ) : (
              ""
            )} */}
            <center>
              <p style={{ fontSize: "20px" }}>
                {getValue(this.props, "order.is_donated") ? (
                  <p style={{ color: "red" }}>Donated</p>
                ) : (
                  <p style={{ color: "green" }}>
                    {" "}
                    {getValue(this.props, "order.order_status", "")}
                  </p>
                )}
              </p>
            </center>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="remove-mdal"
        >
          <div className="campaign-product popup">
            <div className="campaign-details">
              <h5 className="mb-2">
                Are you sure you want to cancel this item from your orders?
              </h5>
              <Input
                type="textarea"
                value={this.props.cancellationReason}
                placeholder="Cancellation comments"
                name="cancellationReason"
                onChange={this.props.handleChange}
              />
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-nothing text-red"
                  onClick={this.cancelOrder}
                >
                  Yes, Cancel
                </button>
                <button
                  className="btn btn-nothing text-blue"
                  onClick={this.toggle}
                >
                  Keep
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default OrdersCard;
