// ******************************************** //
// ***********  user Actions  ***************** //
// ******************************************** //

import * as actionTypes from "../_constants/index";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { API_URL } from "../../env";
import setAuthToken from "../../utils";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { getValue } from "../../utils/lodash";

// FOR REGISTER
const fetchRegisterBegin = () => {
  return {
    type: actionTypes.REGISTER_BEGIN,
  };
};
const fetchRegisterSuccess = (user) => {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    payload: user,
  };
};
const fetchRegisterFailure = (user) => {
  return {
    type: actionTypes.REGISTER_FAILURE,
    payload: user,
  };
};

// FOR LOGIN
const fetchLoginBegin = () => {
  return {
    type: actionTypes.LOGIN_BEGIN,
  };
};
const fetchLoginSuccess = (user) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: user,
  };
};
const fetchLoginFailure = (user) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    payload: user,
  };
};

// Set logged in user
const setCurrentUser = (decoded) => {
  return {
    type: actionTypes.SET_CURRENT_USER,
    payload: decoded,
  };
};

// ACTION CREATORS

const userRegister = (
  username,
  firstname,
  lastname,
  phone,
  email,
  password
) => async (dispatch) => {
  dispatch(fetchRegisterBegin());
  if (typeof window === "undefined") {
    return;
  }
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(`${API_URL}/auth/local/register`, {
          username,
          firstname,
          lastname,
          phone,
          email,
          password,
        })
        .then((res) => {
          const { jwt } = res.data;
          localStorage.setItem("jwt", jwt);
          setAuthToken(jwt);
          const decoded = jwt_decode(jwt);
          dispatch(setCurrentUser(decoded));
          dispatch(fetchRegisterSuccess(res.data.user));
        })
        .catch((error) => {
          console.log(error);
          //reject the promise and pass the error object back to the form
          toast.error(
            getValue(
              error,
              "response.message[0].messages[0].message",
              "Something went wrong,Please try later"));
          dispatch(fetchRegisterFailure(error));
        });
    } catch (error) {
      console.log(error);
    }
  });
};

const userLogin = (identifier, password) => async (dispatch) => {
  if (typeof window === "undefined") {
    return;
  }
  return new Promise((resolve, reject) => {
    dispatch(fetchLoginBegin());
    axios
      .post(`${API_URL}/auth/local/`, { identifier, password })
      .then((res) => {
        dispatch(fetchLoginSuccess(res.data.user));
        const { jwt } = res.data;
        localStorage.setItem("jwt", jwt);
        Cookies.set("isActive", true);
        // Set token to Auth header
        setAuthToken(jwt);
        // Decode token to get user data
        const decoded = jwt_decode(jwt);
        // Set current user
        dispatch(setCurrentUser(decoded));
      })
      .catch((error) => {
        toast.error("Email and password did not match");
        dispatch(fetchLoginFailure(error));
      });
  });
};

// Log user out
const userLogout = () => async (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwt");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  Cookies.remove("accountCartId");
  Cookies.set("cartRedirect", true);
  window.location.href = "/";
};

export { userRegister, userLogin, userLogout, setCurrentUser };
