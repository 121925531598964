import React, { useEffect, useState } from "react";
import MapContact from "../../_components/sections/MapContact";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import InputField from "../../_components/common/inputField";
import { getValue } from "../../utils/lodash";
import graphQLRequest from "../../services/graphQlRequest";
import links from "../../services/Queries/social/links";

function ContctUsSection(props) {
  const [siteInfo, setSiteInfo] = useState([]);
  useEffect(() => {
    getInfo()
  }, []);
 const getInfo =async () => {
  let resp = await graphQLRequest(links);
    if (resp) {
      setSiteInfo(getValue(resp, "siteInfo"));
    }
  }
  return (
    <div>
      <Container fluid={true}>
        <h2 className="cnt-heading mt-md-0 mt-3 mb-0 pt-5">Help Center</h2>
        <Row className="faq-page mt-0">
          <Col md="12">
            <Row className="cntfrm">
              <Col md="6">
                <div className="form-group">
                  <InputField
                    className="form-control"
                    inputType="TEXT"
                    placeholder="&nbsp;"
                    label="Name"
                    name="fullname"
                    validator={props.validator}
                    value={getValue(props, "request.fullname", "")}
                    onChange={props.handleChange}
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <InputField
                    className="form-control"
                    inputType="NUMBER"
                    placeholder="&nbsp;"
                    label="Phone number"
                    validator={props.validator}
                    value={getValue(props, "request.phone", "")}
                    onChange={props.handleChange}
                    name="phone"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <InputField
                    className="form-control"
                    inputType="EMAIL"
                    placeholder="&nbsp;"
                    label="Email"
                    validator={props.validator}
                    value={getValue(props, "request.email", "")}
                    onChange={props.handleChange}
                    name="email"
                  />
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <InputField
                    inputType="INPUT_SELECT"
                    label=""
                    placeholder="&nbsp;"
                    onChange={props.handleChange}
                    name="category"
                    setPath="category"
                    textInput="category"
                    valueInput="value"
                    selectedItem={getValue(props, "request.category", "")}
                    value={getValue(props, "request.category", "")}
                    options={props.categoriesList}
                    validator={props.validator}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <InputField
                    className="form-control"
                    inputType="TEXTALPHA"
                    placeholder="&nbsp;"
                    validator={props.validator}
                    value={getValue(props, "request.message", "")}
                    onChange={props.handleChange}
                    name="message"
                    label="Message"
                    rows="5"
                  ></InputField>
                </div>
              </Col>
              <Col md="12">
                <div className="text-center">
                  <button
                    className="btn btn-blue mt-md-4 mt-3 mb-0"
                    onClick={props.handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </Col>
            </Row>
            <div className="contactmap d-none d-md-block">
              <Row>
                <Col md="6">
                  <h2>Get in touch</h2>
                  <h6 className="ftrheding">Email Us at</h6>
                  <h5>
                    <a
                      href={`mailto:${getValue(siteInfo, "email")}`}
                      target="_blank"
                    >
                      {getValue(siteInfo, "email")}{" "}
                    </a>
                  </h5>
                  <h6 className="ftrheding">Call us Now</h6>
                  <h2>
                    <a
                      href={`tel: ${getValue(siteInfo, "phone")}`}
                      target="_blank"
                    >
                      {getValue(siteInfo, "phone")}
                    </a>
                  </h2>
                </Col>
                <Col md="6">
                  <div className="iframe-mp">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15553.198976287997!2d77.52754575!3d12.952661599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1610987997290!5m2!1sen!2sin"
                      width="390"
                      height="250"
                      frameborder="0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                  <Row className="adr mt-md-4 mt-3 mb-0">
                    <Col md="6">
                      <h3>Headquarters</h3>
                    </Col>
                    <Col md="6">
                      <p>
                      {getValue(siteInfo, "address")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="contactmap d-md-none">
              <Row>
                <Col md="6">
                  <h6 className="ftrheding">Call us Now</h6>
                  <h2>
                    <a
                      href={`tel: ${getValue(siteInfo, "phone")}`}
                      target="_blank"
                    >
                      {getValue(siteInfo, "phone")}
                    </a>
                  </h2>
                  <h6 className="ftrheding">Email Us at</h6>
                  <h5>
                    <a
                      href={`mailto:${getValue(siteInfo, "email")}`}
                      target="_blank"
                    >
                      {getValue(siteInfo, "email")}
                    </a>
                  </h5>
                </Col>
                <Col md="6">
                  <div className="iframe-mp">
                    <h6 className="ftrheding">Location</h6>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15553.198976287997!2d77.52754575!3d12.952661599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1610987997290!5m2!1sen!2sin"
                      width="390"
                      height="250"
                      frameborder="0"
                      allowfullscreen=""
                      aria-hidden="false"
                      tabindex="0"
                    ></iframe>
                  </div>
                  <Row className="adr mt-md-4 mt-3 mb-0">
                    <Col md="6">
                      <h3>Headquarters</h3>
                    </Col>
                    <Col md="6">
                      <p>
                      {getValue(siteInfo, "address")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ContctUsSection;
