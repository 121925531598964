import React from "react";
import {
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import products from "../../services/Queries/products/products";
import { getValue } from "../../utils/lodash";
import { API_URL } from "../../env";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import CloseSVG from '../svg/close-svg';
// import showdown from "showdown";

class HomeAllWinners extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      productList: [],
      modal: false,
      image: "",
      index: 0,
    };
  }
  closeModal = () => {
    this.setState({
      modal: false,
    })
  }
  toggle = (image, key) => {
    this.setState({
      modal: !this.state.modal,
      image: image,
      index: key,
    });
  };

  componentDidMount = async () => {
    window.scroll({ top: 0, behavior: "smooth" });
    let productList = await graphQLRequestGlobal(products);
    if (productList) {
      this.setState({ productList: productList.products });
    }
  };
  render() {
    var settings = {
      arrows: true,
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: true,
            dots: true,
            infinite: true,
            speed: 800,
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    // var converter = new showdown.Converter();
    // let productescription = converter.makeHtml(
    //   getValue(this.state, `productList[${this.state.index}].description`)
    // );
    return (
      <section className="home-product">
        <Container fluid={true}>
          <Row>
            <Col sm="8">
              <h2 className="home-heading">Our Products</h2>
            </Col>
            <Col sm="4" className="text-sm-right">
              {/* <h3 className="mt-md-3 mb-md-4"><NavLink to="">See All</NavLink></h3> */}
            </Col>
          </Row>
          <Slider {...settings} className="home-product-slider">
            {getValue(this.state, "productList", []).map((product, key) => {
              return (
                <div
                  className="home-product-card"
                  onClick={() => {
                    this.toggle(`${API_URL}${product.images[0].url}`, key);
                  }}
                >
                  <div className={"color-div color" + (key + 1)}>
                    <img
                      src={`${API_URL}${product.images[0].url}`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h5>{product.title}</h5>
                </div>
              );
            })}
          </Slider>
        </Container>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-lg"
        >
          <Button color="nothing" className="modal-cl" onClick={() => this.closeModal()}><CloseSVG /></Button>
          <div className="campaign-product popup">
            {/* <ul className="campaign-multiple">
                            <li>
                                <Button
                                    color="nothing"
                                // style={{backgroundImage: `url(${API_URL}${image.url})`,}}
                                ></Button>
                            </li>
                            <li>
                                <Button
                                    color="nothing"
                                // style={{backgroundImage: `url(${API_URL}${image.url})`,}}
                                ></Button>
                            </li>
                            <li>
                                <Button
                                    color="nothing"
                                // style={{backgroundImage: `url(${API_URL}${image.url})`,}}
                                ></Button>
                            </li>
                            <li>
                                <Button
                                    color="nothing"
                                // style={{backgroundImage: `url(${API_URL}${image.url})`,}}
                                ></Button>
                            </li>
                        </ul>
                         */}
            <div className="campaign-details">
              <Row>
                <Col md="5">
                  <div
                    className="product-img"
                    style={{
                      backgroundImage: `url(${getValue(
                        this.state,
                        "image",
                        "https://backend.madoverdreams.com/uploads/1_0c2c6d1301.png"
                      )})`,
                    }}
                  >
                    {/* <img
                                            className="img-fluid"
                                            src=
                                            alt=""
                                        /> */}
                  </div>
                </Col>
                <Col
                  md="7"
                  className="d-flex flex-column justify-content-center"
                >
                  <h3 className="mb-2">
                    {getValue(
                      this.state,
                      `productList[${this.state.index}].title`
                    )}
                  </h3>
                  <p>
                    <p
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{
                        __html: getValue(
                          this.state,
                          `productList[${this.state.index}].description`
                        ),
                      }}
                    />
                    {/* {getValue(
                      this.state,
                      `productList[${this.state.index}].description`
                    )} */}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </Modal>
      </section>
    );
  }
}
export default HomeAllWinners;
