// ******************************************** //
// ************** App.js/Main page ************ //
// ******************************************** //

import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./_pages/home/home";
import Cart from "./_pages/cart/cart";
import PersonalDetails from "./_pages/personal-details/personal-details";
import Wishlist from "./_pages/wishlist/wishlist";
import Campaigns from "./_pages/campaigns/campaigns";
import Coupons from "./_pages/coupons/coupons";
import CampaignsList from "./_pages/campaigns-list/campaigns-list";
import Settings from "./_pages/settings/settings";
import Wallet from "./_pages/wallet/wallet";
import Orders from "./_pages/orders/orders";
import HowWeWork from "./_pages/how-we-work/how-we-work";
import About from "./_pages/about/about";
import Charities from "./_pages/charities/charities";
import ContactUs from "./_pages/contact-us/contact-us";
import Register from "./_pages/Register/Register";
import { userLogout, setCurrentUser } from "./redux/_actions/userAction";
import jwt_decode from "jwt-decode";
import store from "./redux/configureStore";
import setAuthToken from "./utils";
import Login from "./_pages/Login/Login";
import SocialLogin from "./_pages/Login/SocialLogin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentPage from "./_pages/payment/payment";
import Shipping from "./_pages/shipping/shipping";
import OrderPlaced from "./_pages/Order-Placed/order-placed";
import FAQ from "./_pages/faq/faq";
import CampaignTC from "./_pages/campaign-tc/campaign-tc";
import PrivacyPolicy from "./_pages/privacy-policy/privacy-policy";
import UserAgreement from "./_pages/user-agreement/user-agreement";
import BasicInfo from "./_pages/basic-info/BasicInfo";
import NotFoundPage from "./_pages/NotFoundPage/NotFoundPage";
import Header from "./_components/header/header";
import Footer from "./_components/footer/footer";
import ForgotPassword from "./_pages/forgot-password/ForgotPassword";
import EmailSent from "./_pages/email-sent/EmailSent";
import ResetPassword from "./_pages/reset-password/ResetPassword";
import PasswordReset from "./_pages/email-sent/PasswordReset";
import CancellationPolicy from "./_pages/cancellation-policy/CancellationPolicy";

class App extends Component {
  // * Authenticating token * //
  componentDidMount() {
    if (localStorage.getItem("jwt")) {
      // Set auth token header auth
      const token = localStorage.getItem("jwt");
      setAuthToken(token);
      // Decode token and get user info and exp
      const decoded = jwt_decode(token);
      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded));
      // Check for expired token
      const currentTime = Date.now() / 1000; // to get in milliseconds
      if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(userLogout());
        // console.log(store.getState());
        // Redirect to login
        window.location.href = "/";
      }
      // if (Cookies.get("anonymousCartId") && Cookies.get("anonymousCartToken")) {
      //   window.location.href = "/cart";
      // }
    }
  }

  render() {
    return (
      <div className="App">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <Switch>
            <>
              <Header />
              <Route exact path="/" component={Home} />
              <Route exact path="/cart" component={Cart} />
              <Route
                exact
                path="/personal-details"
                component={PersonalDetails}
              />
              <Route exact path="/wishlist" component={Wishlist} />
              <Route exact path="/campaigns/:id" component={Campaigns} />
              <Route exact path="/campaigns" component={CampaignsList} />
              <Route exact path="/coupons" component={Coupons} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/wallet" component={Wallet} />
              <Route exact path="/order-details" component={Orders} />
              <Route exact path="/how-it-works" component={HowWeWork} />
              <Route exact path="/payment" component={PaymentPage} />
              <Route exact path="/basic-info" component={BasicInfo} />
              <Route exact path="/shipping" component={Shipping} />
              <Route exact path="/about" component={About} />
              <Route exact path="/cancellation-policy" component={CancellationPolicy} />
              <Route exact path="/faqs" component={FAQ} />
              <Route exact path="/terms-condition" component={CampaignTC} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/user-agreement" component={UserAgreement} />
              <Route
                exact
                path="/order-placed/:order_id"
                component={OrderPlaced}
              />
              <Route exact path="/charities" component={Charities} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/email-sent" component={EmailSent} />
              <Route exact path="/password-reset" component={PasswordReset} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/auth/:authType/:id" component={SocialLogin} />
              <Route path="/404" component={NotFoundPage} />
              <Footer />
            </>
          </Switch>
        </Router>
      </div>
    );
  }
}
export default App;
