import React from "react";
import { Row, Col, Button, Label } from "reactstrap";
import InputField from "../common/inputField";
import { getValue } from "../../utils/lodash";
import Select from "react-select";

class PersonalFormCard extends React.Component {
  render() {
    return (
      <div className="personal-form-card">
        <h2 className="dashboard-heading dashboard-heading-small">
          Profile Details
        </h2>
        <Row>
          <Col md="4">
            <InputField
              inputType="TEXT"
              // validator={this.props.validator}
              placeholder="&nbsp;"
              name="firstname"
              id="firstname"
              value={getValue(this.props.user, "firstname", "")}
              label="First Name"
              setPath="firstname"
              onChange={this.props.handleChange}
            />
            {
              <span className="form-error">
                {this.props.validator.message(
                  "Firstname",
                  getValue(this.props.user, "firstname", ""),
                  "required|alpha"
                )}
              </span>
            }
          </Col>
          <Col md="4">
            <InputField
              inputType="TEXT"
              placeholder="&nbsp;"
              // validator={this.props.validator}
              name="lastname"
              id="lastname"
              value={getValue(this.props.user, "lastname", "")}
              label="Last Name"
              setPath="lastname"
              onChange={this.props.handleChange}
            />
            {
              <span className="form-error">
                {this.props.validator.message(
                  "Lastname",
                  getValue(this.props.user, "lastname", ""),
                  "required|alpha"
                )}
              </span>
            }
          </Col>
        </Row>
        <h2 className="dashboard-heading mt-4 dashboard-heading-small">
          Contact Details
        </h2>
        <Row>
          {/* <Col md="3">
            <div className="form-group">
              <Select
                value={getValue(this.props, "countryCode", "")}
                onChange={(e) => this.props.handleContactDetails(e)}
                options={this.props.countries}
              />
              <div className="form-error">
                {this.props.validator.message(
                  "Contry Code",
                  getValue(this.props, "countryCode", ""),
                  "required"
                )}
              </div>
            </div>
          </Col> */}
          <Col md="2">
            <InputField
              inputType="INPUT_REACT_SELECT_COUNTYCODE"
              label="Country code"
              placeholder="&nbsp;"
              // validator={this.validator}
              onChange={(e) => this.props.handleContactDetails(e)}
              id="country_code"
              name="country_code"
              value={getValue(this.props, "countryCode", "")}
            />
            <div className="form-error">
              {this.props.validator.message(
                "Contry Code",
                getValue(this.props, "countryCode", ""),
                "required"
              )}
            </div>
          </Col>
          <Col md="4">
            <InputField
              // className="form-control"
              inputType="NUMBER"
              placeholder="&nbsp;"
              value={getValue(this.props.user, "phone", "")}
              onChange={this.props.handleChange}
              name="phone"
              setPath="phone"
              label="Phone Number"
              validator={this.props.validator}
            />
          </Col>
          <Col md="4">
            <InputField
              inputType="EMAIL"
              placeholder="&nbsp;"
              // validator={this.props.validator}
              name="email"
              id="email"
              value={getValue(this.props, "email", "")}
              label="Email"
              setPath="email"
              onChange={this.props.handleChangeState}
              // disabled={true}
            />
          </Col>
          <Col md="12" className="mt-md-4 mt-2">
            <Button color="red" onClick={this.props.handleSubmit}>
              Update Profile
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
export default PersonalFormCard;
