export default `
query campaigns($sort:String,$where:JSON){ 
    campaigns(sort:$sort,where:$where) {
      _id
        slug
        title
        description
        points_per_unit
        coupons_per_unit
        total_units
        sold_units
        status
        draw_date
        end_date
        image{
          url
        }
        product{
            slug
            title
          description
          price
          _id
          images{
            url
          }
        }
        reward{
          slug
          title
          description
          price
          images{
            url
          }
        }
    }
  }
`;