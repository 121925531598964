export default `
query myCart($anonymous_cart_token:ID){ 
    myCart(anonymous_cart_token:$anonymous_cart_token) {
      cart{
        _id
        id
        is_donated
        total_coupons
        shipping_price
        wallet_discount_price
        wallet_discount_points
        shipping_info{
          _id
          address_line_1
          address_line_2
          city
          state
          country
          pincode
        }
        basic_info{
        _id
        firstname
        lastname
        email
        phone
        }
        items{
          id
          coupons
          item{
            image{
              url
            }
            status
            draw_date
            end_date
            id
            title
            product{
              slug
              title
              price
              images{
                url
              }
            }
            reward{
              slug
              _id
              title
              description
              price
              images{
                url
              }
  
            }
          }
     
          price
          quantity
        }
        currency_code
        total_price
        subtotal_price
        subtotal_price
        total_price
    }
  }
}
`;
