import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import { Col, Row, Container } from "reactstrap";
import LeftMenu from "../../_components/cards/LeftMenu";
import ActiveCouponsCard from "../../_components/cards/ActiveCouponsCard";
import graphQLRequest from "../../services/graphQlRequest";
import myCoupons from "../../services/Queries/coupons/myCoupons";
import { getValue, setValue } from "../../utils/lodash";
import { NavLink } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";

class Coupons extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      Coupons: [],
      limit: 10,
      pageNumber: 0,
      totalCount: 0,
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    } else {
      this.getCoupons();
    }
  }

  getCoupons = async () => {
    let data = {
      limit: this.state.limit,
      start: this.state.pageNumber,
      sort:"createdAt:desc"
    };

    let resp = await graphQLRequest(myCoupons, data);
    if (resp) {
      this.setState({
        Coupons: getValue(resp, "myCoupons.coupons", []),
        totalCount: getValue(resp, "myCoupons.total_count", 0),
      });
    }
  };
  handlePageChange = (pageNumber) => {
    setValue(this.state, "activePage", pageNumber);
    if (parseInt(pageNumber) === 1) {
      setValue(this.state, "pageNumber", pageNumber);
    } else {
      let currentPage =
        (parseInt(pageNumber) - 1) * 10 +
        (parseInt(pageNumber) - parseInt(pageNumber));
      setValue(this.state, "pageNumber", currentPage);
    }
    this.getCoupons();
  };
  render() {
    return (
      <>
        <Container fluid={true}>
          <section className="dashboard-page dashboard-page-resp">
            <h2 className="dashboard-heading">Active Coupons</h2>
            {getValue(this.state, "Coupons.length", []) !== 0 ? (
              <>
                <Row>
                  <Col md={{ size: "3" }} className="d-none d-md-block">
                    <LeftMenu />
                  </Col>
                  <Col md={{ size: "9" }}>
                    {/* only first item here */}
                    <Row>
                      {getValue(this.state, "Coupons", []).map((item, i) => {
                        return (
                          <Col md="6">
                            <ActiveCouponsCard key={i} coupon={item} />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                {/* <Row>
                  {getValue(this.state, "Coupons", []).map((item, i) => {
                    if (i > 0) {
                      return (
                        <Col md="4">
                          <ActiveCouponsCard coupon={item} className="half" />
                        </Col>
                      );
                    }
                  })}
                </Row> */}
              </>
            ) : (
              <>
                <Row>
                  <Col md={{ size: "3" }} className="d-none d-md-block">
                    <LeftMenu />
                  </Col>
                  <Col md={{ size: "9" }}>
                    <div className="mt-5 text-center">
                      <div className="cart-empty">
                        <img src="/images/empty-cart.PNG" alt="" width="110" />
                      </div>
                      <h6 className="text-center mt-5">
                        You currently have no coupons, Buy any of our products
                        to
                        <br />
                        get complimentary coupons to win luxury prizes
                      </h6>
                      <div className="mt-5 text-center">
                        <NavLink to="/campaigns">
                          <Button color="white-light" className="px-5 py-2">
                            See All Campaigns
                          </Button>
                        </NavLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {/* <Col>
                <h2 className="dashboard-heading">Expired Coupons</h2>
                <div className="expired-coupons">
                  <Row>
                    <Col md="4">
                      <ActiveCouponsCard className="half" />
                    </Col>
                    <Col md="4">
                      <ActiveCouponsCard className="half" />
                    </Col>
                    <Col md="4">
                      <ActiveCouponsCard className="half" />
                    </Col>
                    <Col md="4">
                      <ActiveCouponsCard className="half" />
                    </Col>
                    <Col md="4">
                      <ActiveCouponsCard className="half" />
                    </Col>
                    <Col md="4">
                      <ActiveCouponsCard className="half" />
                    </Col>
                  </Row>
                </div>
              </Col> */}
            {getValue(this.state, "totalCount", 0) > 10 ? (
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={10}
                totalItemsCount={this.state.totalCount}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
              />
            ) : (
              ""
            )}
          </section>
        </Container>
        <div className="d-none d-md-block">{/* <Footer /> */}</div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};
export default connect(mapStateToProps)(Coupons);
