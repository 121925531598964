import React from 'react';
class ComponentLoader extends React.Component {
    render() {
        return (
            <div className="component-loader">
                <div className="loader-container">
                    <img src="/images/loader1.png" className="loader1" alt="" />
                    <h4>Loading<span>...</span></h4>
                </div>
            </div>
        )
    }
}
export default ComponentLoader