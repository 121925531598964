import React from "react";
import { Container, Row, Col } from "reactstrap";
import graphQLRequest from "../../services/graphQlRequest";
import links from "../../services/Queries/social/links";
import { getValue } from "../../utils/lodash";
class HomeApp extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      siteInfo: [],
    };
  }

  componentDidMount = async () => {
    let resp = await graphQLRequest(links);
    if (resp) {
      this.setState({
        siteInfo: getValue(resp, "siteInfo", []),
      });
    }
  };
  render() {
    return (
      <section className="home-app d-none d-md-block">
        <Container fluid={true}>
          <Row>
            <Col md="3">
              <h1 className="home-heading">Get in Touch </h1>
            </Col>
            <Col md="3">
              <p className="home-heading">
                <span>
                  Mail us <br></br>
                  {getValue(this.state, "siteInfo.email")}
                </span>
              </p>
            </Col>
            <Col md="2">
              <p className="home-heading">
                <span>Dial us </span>
                <h2>{getValue(this.state, "siteInfo.phone")}</h2>
              </p>
            </Col>
            <Col md="4">
              <img src="/images/app.png" alt="" className="app-image" />
              {/* <img src="/images/DownlaodApp.png" alt="" className="app-image" /> */}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
export default HomeApp;
