import React from 'react';
class ShareSVG extends React.Component {
    render() {
        return (
            <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.003 7.39848C15.964 7.39848 14.3037 5.73924 14.3037 3.69924C14.3037 1.65924 15.964 0 18.003 0C20.0421 0 21.7022 1.65924 21.7022 3.69924C21.7022 5.73924 20.0421 7.39848 18.003 7.39848ZM18.003 1.4797C16.7797 1.4797 15.7834 2.47495 15.7834 3.69924C15.7834 4.92353 16.7797 5.91879 18.003 5.91879C19.2262 5.91879 20.2225 4.92353 20.2225 3.69924C20.2225 2.47495 19.2262 1.4797 18.003 1.4797Z" fill="black" />
                <path d="M18.003 23.6751C15.964 23.6751 14.3037 22.0159 14.3037 19.9759C14.3037 17.9359 15.964 16.2767 18.003 16.2767C20.0421 16.2767 21.7022 17.9359 21.7022 19.9759C21.7022 22.0159 20.0421 23.6751 18.003 23.6751ZM18.003 17.7564C16.7797 17.7564 15.7834 18.7516 15.7834 19.9759C15.7834 21.2002 16.7797 22.1954 18.003 22.1954C19.2262 22.1954 20.2225 21.2002 20.2225 19.9759C20.2225 18.7516 19.2262 17.7564 18.003 17.7564Z" fill="black" />
                <path d="M3.69924 15.5368C1.66014 15.5368 0 13.8775 0 11.8375C0 9.79755 1.66014 8.13831 3.69924 8.13831C5.73834 8.13831 7.39848 9.79755 7.39848 11.8375C7.39848 13.8775 5.73834 15.5368 3.69924 15.5368ZM3.69924 9.618C2.47603 9.618 1.4797 10.6133 1.4797 11.8375C1.4797 13.0618 2.47603 14.0571 3.69924 14.0571C4.92245 14.0571 5.91879 13.0618 5.91879 11.8375C5.91879 10.6133 4.92245 9.618 3.69924 9.618Z" fill="black" />
                <path d="M6.27491 11.1174C6.01734 11.1174 5.76681 10.9832 5.6317 10.7446C5.42939 10.3895 5.55276 9.93758 5.90788 9.73546L15.0613 4.51696C15.4154 4.31286 15.8682 4.43713 16.0694 4.79332C16.2717 5.14844 16.1483 5.60018 15.7932 5.80249L6.63978 11.0208C6.52435 11.0858 6.39918 11.1174 6.27491 11.1174Z" fill="black" />
                <path d="M15.4273 19.2557C15.303 19.2557 15.1776 19.2241 15.0622 19.1582L5.90877 13.9397C5.55366 13.7376 5.43047 13.2856 5.63259 12.9305C5.83381 12.5745 6.28574 12.4512 6.64085 12.6544L15.7941 17.8727C16.1492 18.075 16.2726 18.5267 16.0705 18.8818C15.9343 19.1215 15.6838 19.2557 15.4273 19.2557Z" fill="black" />
            </svg>
        )
    }
}
export default ShareSVG