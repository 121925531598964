import React, { Component } from "react";
import { getValue } from "../../utils/lodash";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { NavLink } from "react-router-dom";
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import PlaceOrderCart from "./placeOrderCart";
import Cookies from "js-cookie";
import WalletOutlinedSVG from "../svg/wallet-outlined-svg";
import Input from "reactstrap/lib/Input";

class PaymentCard extends Component {
  render() {
    return (
      <Container fluid={true}>
        <section className="dashboard-page payment-p">
          <h2 className="dashboard-heading">Checkout</h2>
          <Row>
            <Col md={{ size: "8" }}>
              {/* {!getValue(this.props, "is_donated") &&
              !Cookies.get("basicInfo") ? ( */}

              {/* {!getValue(this.props, "is_donated") &&
                getValue(this.props, "userDetails.firstname") &&
                getValue(this.props, "userDetails.lastname") &&
                getValue(this.props, "userDetails.user.email") &&
                getValue(this.props, "userDetails.phone") ? (
                  <>
                    <ul className="shipping-steps">

                      <li>
                        <NavLink to="/shipping">
                          <span className="ship-numb">1</span>
                          <span className="ship-text">Shipping Details</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/payment">
                          <span className="ship-numb">2</span>
                          <span className="ship-text">Review & Place Order</span>
                        </NavLink>
                      </li>
                    </ul>
                  </>
                ) : getValue(this.props, "is_donated") &&
                  getValue(this.props, "userDetails.firstname") &&
                  getValue(this.props, "userDetails.lastname") &&
                  getValue(this.props, "userDetails.user.email") &&
                  getValue(this.props, "userDetails.phone") ? (
                    <ul className="shipping-steps">
                      <li>
                        <NavLink to="/shipping">
                          <span className="ship-numb">1</span>
                          <span className="ship-text">Shipping Details</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/payment">
                          <span className="ship-numb">2</span>
                          <span className="ship-text">Review & Place Order</span>
                        </NavLink>
                      </li>
                    </ul>
                  ) : (
                    <ul className="shipping-steps">
                      <li>
                        <NavLink to="/basic-info">
                          <span className="ship-numb">1</span>
                          <span className="ship-text">Basic Details</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/shipping">
                          <span className="ship-numb">1</span>
                          <span className="ship-text">Shipping Details</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/payment">
                          <span className="ship-numb">2</span>
                          <span className="ship-text">Review & Place Order</span>
                        </NavLink>
                      </li>
                    </ul>
                  )} */}

              {getValue(this.props, "cartDetails.is_donated") === false ? (
                <>
                  <h4 className="text-red-light">
                    <b>Shipping Address</b>
                  </h4>
                  <div className="address-card">
                    <h5 className="mb-0">
                      <b>
                        {getValue(
                          this.props,
                          "cartDetails.basic_info.firstname"
                        )}{" "}
                        {getValue(
                          this.props,
                          "cartDetails.basic_info.lastname"
                        )}
                      </b>
                    </h5>
                    <h5 className="mb-3">
                      {getValue(
                        this.props,
                        "cartDetails.shipping_info.address_line_1"
                      )}
                      ,
                      {getValue(
                        this.props,
                        "cartDetails.shipping_info.address_line_2"
                      )}
                      ,
                      {getValue(
                        this.props,
                        "cartDetails.shipping_info.pincode"
                      )}
                      <br></br>
                      {getValue(this.props, "cartDetails.shipping_info.city")}
                      <br></br>
                      {getValue(this.props, "cartDetails.shipping_info.state")}
                      <br></br>
                      {getValue(
                        this.props,
                        "cartDetails.shipping_info.country"
                      )}
                      <br></br>
                    </h5>
                    {/* <Button color="red" className="mb-2 py-2" block>
                  Deliver to this address
                </Button> */}
                    {/* <Row>
                  <Col>
                    <Button className="p-1" color="border" block>
                      Edit
                    </Button>
                  </Col>
                  <Col>
                    <Button className="p-1" color="border" block>
                      Delete
                    </Button>
                  </Col>
                </Row> */}
                  </div>
                </>
              ) : (
                ""
              )}
              <h4 className="text-red-light">
                <b>Items in the Cart</b>
              </h4>

              <PlaceOrderCart product={getValue(this.props, "product", [])} />
            </Col>
            <Col md="4">
              <div className="cart-right-details">
                <div className="cart-table">
                  <h6>Total Coupons</h6>
                  <h6>
                    {getValue(this.props, "cartDetails.total_coupons", 0)}
                  </h6>
                </div>
                <hr className="d-md-none" />
                <div className="cart-table">
                  <h6>Total Products</h6>
                  <h6>{getValue(this.props, "cartDetails.items.length", 0)}</h6>
                </div>

                <hr className="d-none d-md-block" />
                <div className="cart-table mt-4 resp-red-p">
                  <h6 className="mb-0">
                    <WalletOutlinedSVG />{" "}
                    <b className="text-black">
                      Wallet <span className="d-none d-md-inline">Balance</span>
                    </b>
                  </h6>
                  <div className="d-md-none">
                    <Input
                      className="d-md-none"
                      type="number"
                      placeholder="Enter Points"
                      value={this.props.walletInput}
                      onChange={this.props.handleChange}
                    />
                    <button
                      className="btn btn-black"
                      onClick={this.props.updateWallet}
                    >
                      Redeem
                    </button>
                  </div>
                  <h6 className="mb-0 text-red pt-1">
                    <b>
                      {getValue(
                        this.props,
                        "userDetails.wallet_info.available_points",
                        0
                      )}{" "}
                      Points
                    </b>
                  </h6>
                </div>

                <hr className="mt-0 d-none d-md-block" />
                <br />
                <div className="cart-table d-none d-md-flex">
                  <div>
                    <Input
                      type="number"
                      placeholder="Enter Points"
                      value={this.props.walletInput}
                      onChange={this.props.handleChange}
                    />
                  </div>
                  <button
                    className="btn btn-black"
                    onClick={this.props.updateWallet}
                  >
                    Redeem
                  </button>
                </div>
                <br />
                <br />
                <br />

                <div className="cart-table d-none d-md-flex">
                  <h6>Product Cost</h6>
                  <h6>
                    ₹ {getValue(this.props, "cartDetails.subtotal_price", 0.0)}
                  </h6>
                </div>
                {/* <hr className="d-md-none" /> */}
                <div className="cart-table  d-none d-md-flex">
                  <h6>Wallet Discount</h6>
                  <h6>
                    ₹{" "}
                    {getValue(
                      this.props,
                      "cartDetails.wallet_discount_price",
                      0
                    )}
                  </h6>
                </div>

                {getValue(this.props, "cartDetails.shipping_price", 0) ? (
                  <div className="cart-table d-none d-md-flex">
                    <h6>Shipping Cost</h6>
                    <h6>
                      ₹ {getValue(this.props, "cartDetails.shipping_price", 0)}
                    </h6>
                  </div>
                ) : (
                  ""
                )}

                <div class="cart-table d-none d-md-flex">
                  <h4>
                    Payable Amount
                    {/* <br /> */}
                  </h4>
                  <h2>
                    ₹ {getValue(this.props, "cartDetails.total_price", 0)}
                  </h2>
                </div>
                <p class="mt d-none d-md-block">Inclusive of GST</p>

                <div className="mt-2 d-none d-md-block">
                  <Button
                    color="red"
                    id="rzp-button1"
                    block
                    onClick={this.props.RequestOrderPayment}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mob-camp  d-md-none">
            <div className="d-flex justify-content-between">
              <p className="mb-2">Product Cost</p>
              <p className="mb-2">
                ₹ {getValue(this.props, "cartDetails.subtotal_price", 0.0)}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="mb-2">Wallet Discount</p>
              <p className="mb-2">
                ₹ {getValue(this.props, "cartDetails.wallet_discount_price", 0)}
              </p>
            </div>
            {getValue(this.props, "cartDetails.shipping_price", 0) ? (
              <div className="d-flex justify-content-between">
                <p className="mb-2">Shipping Cost</p>
                <p className="mb-2">
                  ₹ {getValue(this.props, "cartDetails.shipping_price", 0)}
                </p>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">Payable Amount</h5>
              <h5 className="mb-0">
                ₹ {getValue(this.props, "cartDetails.total_price", 0)}
              </h5>
            </div>
            <p class="gstinc d-md-none">
              <small>Inclusive of GST</small>
            </p>
            <div>
              <Button
                color="red"
                id="rzp-button2"
                block
                onClick={this.props.RequestOrderPayment}
              >
                Place Order
              </Button>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

export default PaymentCard;
