export default `
mutation resetPassword($password:String!,$passwordConfirmation:String!,$code:String!) { 
    resetPassword(password:$password,passwordConfirmation:$passwordConfirmation,code:$code){ 
            jwt 
            user{
                username
                email
            }
    }
}
`;
