import React from "react";
import { Input, Button } from "reactstrap";
import { getValue } from "../../utils/lodash";
import {
  selectCurrentUser,
  selectCurrentUserStatus,
} from "../../redux/_selectors/userSelectors";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import WalletOutlinedSVG from "../svg/wallet-outlined-svg";

class CartRight extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      expiredArray: [],
    };
  }

  componentDidMount() {}
  handleNotAvailable = () => {
    toast.error(
      "This campaign is no longer available, please remove and continue"
    );
  };

  routeInfo = () => {
    if (
      getValue(this.props, "profileInfo.firstname") &&
      getValue(this.props, "profileInfo.lastname") &&
      getValue(this.props, "profileInfo.phone")
    ) {
      if (getValue(this.props, "cart.is_donated")) {
        return "/shipping";
      } else {
        return "/shipping";
      }
    } else {
      return "/basic-info";
    }
  };

  render() {
    let expiredArray = [];
    getValue(this.props, "cart.items", []).filter((cart) => {
      if (cart.item) {
        expiredArray.push(getValue(cart.item, "status") === "sold_out");
      }
    });
    let totalQuantity = getValue(this.props, "cart.items", []).map((item)=>item.quantity).reduce((a, b) => a + b, 0)
    return (
      <>
        <div className="resp-donate d-md-none mb-0">
          {getValue(this.props, "cart.is_donated") ? (
            <h6>
              You have doubled your tickets by Donating products to our partner
              charity organisations.
            </h6>
          ) : (
            <h6>
              Donating is your choice.Swipe to donate your products to our
              charity organisations.
            </h6>
          )}
          <h6 className="mb-0">
            {/* add active class on toggle */}
            <label
              className={
                "switch donate text-center " +
                (getValue(this.props, "cart.is_donated") ? "active" : "")
              }
            >
              <input
                type="checkbox"
                checked={getValue(this.props, "cart.is_donated", false)}
                onClick={() => {
                  this.props.updateCoupans(
                    !getValue(this.props, "cart.is_donated")
                  );
                }}
              />
              <span className="slider round"></span>
              <span className="switch-text1">
                <img src="/images/smiley2.svg" alt="" /> Don't Donate
              </span>
              <span className="switch-text2">
                <img src="/images/smiley1.svg" alt="" /> Yes, Donate
              </span>
            </label>
          </h6>
        </div>
        <div className="cart-right-details">
          <div className="cart-table">
            <h6>Total Products</h6>
            {/* <h4>{getValue(this.props, "cartItems.length", 0)}</h4> */}
              <h4>{totalQuantity}</h4>
          </div>
          <hr className="d-md-none" />
          <div className="cart-table">
            <h6>Total Coupons</h6>
            <h4>{getValue(this.props, "cart.total_coupons", 0)}</h4>
          </div>
          <hr className="d-none d-md-block" />
          {getValue(this.props, "auth.isAuthenticated", false) ? (
            <>
              <div className="cart-table d-none d-md-flex mt-4">
                <h6 className="mb-0">
                  <WalletOutlinedSVG />{" "}
                  <b className="text-black">Wallet Balance</b>
                </h6>
                <h6 className="mb-0 text-red pt-1">
                  <b>
                    {getValue(this.props, "walletInfo.available_points", 0)}{" "}
                    Points
                  </b>
                </h6>
              </div>
              <p className="mt-0 d-none d-md-block" />
            </>
          ) : (
            ""
          )}
          <div className="text-center d-none d-md-block mt-4">
            {getValue(this.props, "cart.is_donated") ? (
              <h6>
                Thank you for choosing to donate. Your coupons have been
                doubled. We will send above products to our partner charity.
              </h6>
            ) : (
              <h6>
                By donating you get to double your coupons and help our partner
                charity organisations
              </h6>
            )}
            <h6 className="mb-0">
              {/* add active class on toggle */}
              <label
                className={
                  "switch donate " +
                  (getValue(this.props, "cart.is_donated") ? "active" : "")
                }
              >
                <input
                  type="checkbox"
                  checked={getValue(this.props, "cart.is_donated", false)}
                  onClick={() => {
                    this.props.updateCoupans(
                      !getValue(this.props, "cart.is_donated")
                    );
                  }}
                />
                <span className="slider round"></span>
                <span className="switch-text1">
                  <img src="/images/smiley2.svg" alt="" /> Don't Donate
                </span>
                <span className="switch-text2">
                  <img src="/images/smiley1.svg" alt="" /> Yes, Donate
                </span>
              </label>
            </h6>
          </div>
          <div className="cart-table d-none d-md-flex">
            <h3>Sub total</h3>
            <h5>₹ {getValue(this.props, "subTotalPrice", 0.0)}</h5>
          </div>
          {getValue(this.props, "cart.shipping_price", 0) ? (
            <div className="cart-table">
              <h3>Shipping Cost</h3>
              <h5>₹ {getValue(this.props, "cart.shipping_price", 0)}</h5>
            </div>
          ) : (
            ""
          )}
          <hr className="d-md-none" />
          {getValue(this.props, "cart.wallet_discount_price", 0) ? (
            <div className="cart-table">
              <h6>Wallet Discount</h6>
              <h6>₹ {getValue(this.props, "cart.wallet_discount_price", 0)}</h6>
            </div>
          ) : (
            ""
          )}

          <div className="cart-table d-none d-md-flex">
            <h4>Grand Total</h4>
            <h2>₹ {getValue(this.props, "totalPrice", 0.0)}</h2>
          </div>
          <p class="mt d-none d-md-flex">Inclusive of GST</p>
          <div className="mt-2 d-none d-md-block">
            {this.props.auth.isAuthenticated ? (
              expiredArray.includes(true) === false ? (
                <>
                  <NavLink
                    to={
                      getValue(this.props, "cartItems.length", 0) !== 0
                        ? this.routeInfo
                        : "/cart"
                    }
                  >
                    <Button color="red-light" block>
                      Check Out
                    </Button>
                  </NavLink>
                </>
              ) : (
                <Button
                  color="red-light"
                  onClick={this.handleNotAvailable}
                  block
                >
                  Check Out
                </Button>
              )
            ) : (
              <NavLink to="/login">
                <Button color="red-light" block>
                  Login & Check Out
                </Button>
              </NavLink>
            )}
          </div>
        </div>
        <div className="mob-camp  d-md-none">
          <div className="d-flex justify-content-between">
            <h4>Grand Total</h4>
            <h2>₹ {getValue(this.props, "totalPrice", 0.0)}</h2>
          </div>
          <p class="gstinc d-md-none">Inclusive of GST</p>
          <div>
            {this.props.auth.isAuthenticated ? (
              expiredArray.includes(true) === false ? (
                <>
                  <NavLink
                    to={
                      getValue(this.props, "cartItems.length", 0) !== 0
                        ? this.routeInfo
                        : "/cart"
                    }
                  >
                    <Button color="red-light" block>
                      Check Out
                    </Button>
                  </NavLink>
                </>
              ) : (
                <Button
                  color="red-light"
                  onClick={this.handleNotAvailable}
                  block
                >
                  Check Out
                </Button>
              )
            ) : (
              <NavLink to="/login">
                <Button color="red-light" block>
                  Login & Check Out
                </Button>
              </NavLink>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
    user: selectCurrentUserStatus(state),
  };
};

export default connect(mapStateToProps)(CartRight);
