export default `
mutation updateCartItemQuantity($input:updateCartItemQuantityInput!) { 
    updateCartItemQuantity(input:$input){ 
        cart{
            id
            items{
              item{
                title
                product{
                  title
                }
              }
              quantity
              price
            }
          }
    }
}
`;
