import React from "react";
import { Button, Col, Row } from "reactstrap";
import ShareSVG from "../svg/share-svg";
import FavSVG from "../svg/fav-svg";
import { NavLink } from "react-router-dom";
import { getValue } from "../../utils/lodash";
import { API_URL } from "../../env";
import fetchAddToWishlist from "../../services/Queries/wishlist/fetchAddToWishlist";
import fetchRemoveFromWishlist from "../../services/Queries/wishlist/fetchRemoveFromWishlist";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import fetchCreateAnonymousCart from "../../services/Queries/cart/GuestCart/fetchCreateAnonymousCart";
import fetchAddItemsCart from "../../services/Queries/cart/common/fetchAddItemToCart";
import graphQLRequest from "../../services/graphQlRequest";
import myCart from "../../services/Queries/cart/common/myCart";
import {
  selectCurrentUser,
  cartList,
} from "../../redux/_selectors/userSelectors";
import { connect } from "react-redux";
import {
  fetchCartData,
  fetchAnonymousCartData,
} from "../../redux/_actions/cartAcition";
import fetchRemoveItemsCart from "../../services/Queries/cart/common/fetchUpdateItemCart";
import fetchDeleteItemsCart from "../../services/Queries/cart/common/fetchRemoveItemToCart";
import SocialMediaButtons from "../sections/SocialShare";
import Moment from "react-moment";
var dayjs = require("dayjs");

class ProductCardFullClosing extends React.Component {
  constructor() {
    super();
    this.state = {
      IsFavActive: false,
      cartItems: [],
      isSocial: false,
      isLoading: false,
      isLoading1: false,
    };
    this.favActive = this.favActive.bind(this);
  }

  componentDidMount() {
    if (this.props.product) {
      if (getValue(this.props, "ids", []).includes(this.props.product._id)) {
        this.setState({
          IsFavActive: true,
        });
      }
    }
    if (this.props.status) {
      this.setState({
        IsFavActive: this.props.status,
      });
    }
    this.getCartId();
    this.addToWishlistAnonymousUser();
  }

  getCartId = async () => {
    if (Cookies.get("anonymousCartId")) {
      let anonymous_cart_token = {
        anonymous_cart_token: Cookies.get("anonymousCartToken"),
      };
      let resp = await graphQLRequest(myCart, anonymous_cart_token);
      if (resp) {
        Cookies.set("anonymousCartId", getValue(resp, "myCart.cart.id", ""));
      }
    } else {
      if (this.props.auth.isAuthenticated) {
        Cookies.set(
          "accountCartId",
          getValue(this.props, "cart.cart.myCart.cart.id", "")
        );
      }
    }
  };
  toggleSocial = () => {
    this.setState({
      isSocial: !this.state.isSocial,
    });
  };
  favActive = async (id) => {
    if (getValue(localStorage, "jwt")) {
      if (this.state.IsFavActive === false) {
        let resp = await fetchAddToWishlist(id);
        if (resp) {
          // toast.success("Added successfully");
          this.setState({
            IsFavActive: resp.addToMyWishlist.added,
          });
          this.setState({ ...this.state });
        }
      } else {
        let resp = await fetchRemoveFromWishlist(id);
        if (resp) {
          // toast.success("removed successfully");
          // window.location.reload();
          this.setState({
            IsFavActive: false,
            // resp.removeFromWishlist.removed
          });
          this.setState({ ...this.state });
        }
      }
    }
  };

  addToWishlistAnonymousUser = async () => {
    if (Cookies.get("wishlistId") && Cookies.get("wishlistIdset")) {
      let resp = await fetchAddToWishlist(Cookies.get("wishlistId"));
      if (resp) {
        Cookies.remove("wishlistId");
        Cookies.remove("wishlistIdset");
      }
    }
  };

  addToCart = async (id) => {
    if (id) {
      if (
        (!Cookies.get("anonymousCartToken") ||
          !Cookies.get("anonymousCartId")) &&
        !Cookies.get("accountCartId")
      ) {
        let data = {
          campaign_id: id,
          quantity: 1,
        };
        let resp = await fetchCreateAnonymousCart(data);
        if (resp) {
          let input = {
            anonymous_cart_token: resp.createAnonymousCart.anonymous_cart_token,
          };
          this.props.dispatch(fetchAnonymousCartData(input));
          Cookies.set(
            "anonymousCartToken",
            resp.createAnonymousCart.anonymous_cart_token
          );
        }
      } else if (
        Cookies.get("anonymousCartId") &&
        !Cookies.get("accountCartId")
      ) {
        let resp = await fetchAddItemsCart(
          Cookies.get("anonymousCartId"),
          id,
          1,
          Cookies.get("anonymousCartToken")
        );
        if (resp) {
          let input = {
            anonymous_cart_token: Cookies.get("anonymousCartToken"),
          };
          this.props.dispatch(fetchAnonymousCartData(input));
        } else {
          toast.error("Campaign is Expired");
        }
      } else if (
        !Cookies.get("anonymousCartId") &&
        Cookies.get("accountCartId")
      ) {
        let resp = await fetchAddItemsCart(Cookies.get("accountCartId"), id, 1);
        if (resp) {
          this.props.dispatch(fetchCartData());
        } else {
          toast.error("Campaign is Expired");
        }
      }
    }
  };

  getCartData = async () => {
    if (Cookies.get("anonymousCartToken") || Cookies.get("anonymousCartId")) {
      let input = {
        anonymous_cart_token: Cookies.get("anonymousCartToken"),
      };
      this.props.dispatch(fetchAnonymousCartData(input));
    } else {
      this.props.dispatch(fetchCartData());
    }
  };

  updateCartQuantity = async (id, quantity) => {
    try {
      if (
        Cookies.get("anonymousCartToken") &&
        Cookies.get("anonymousCartId") &&
        !Cookies.get("accountCartId")
      ) {
        if (id && quantity) {
          this.setState({ isLoading: true });
          let resp = await fetchRemoveItemsCart(
            Cookies.get("anonymousCartId"),
            id,
            quantity,
            Cookies.get("anonymousCartToken")
          );
          if (resp) {
            this.getCartData();
            this.setState({ isLoading: false });
          }
        }
      } else {
        if (id && quantity) {
          this.setState({ isLoading: true });
          let resp = await fetchRemoveItemsCart(
            Cookies.get("accountCartId"),
            id,
            quantity
          );
          if (resp) {
            this.getCartData();
            this.setState({ isLoading: false });
          }
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  removeItemFromCart = async (id) => {
    if (
      Cookies.get("anonymousCartToken") &&
      Cookies.get("anonymousCartId") &&
      !Cookies.get("accountCartId")
    ) {
      if (id) {
        let resp = await fetchDeleteItemsCart(
          Cookies.get("anonymousCartId"),
          id,
          Cookies.get("anonymousCartToken")
        );
        if (resp) {
          this.getCartData();
        }
      }
    } else {
      if (id) {
        let resp = await fetchDeleteItemsCart(Cookies.get("accountCartId"), id);
        if (resp) {
          this.getCartData();
        }
      }
    }
  };

  updateCartQuantityDec = async (id, quantity) => {
    try {
      if (quantity !== 0) {
        if (
          Cookies.get("anonymousCartToken") &&
          Cookies.get("anonymousCartId") &&
          !Cookies.get("accountCartId")
        ) {
          if (id && quantity) {
            this.setState({ isLoading1: true });
            let resp = await fetchRemoveItemsCart(
              Cookies.get("anonymousCartId"),
              id,
              quantity,
              Cookies.get("anonymousCartToken")
            );
            if (resp) {
              this.setState({ isLoading1: false });
              this.getCartData();
            }
          }
        } else {
          if (id && quantity) {
            this.setState({ isLoading1: true });
            let resp = await fetchRemoveItemsCart(
              Cookies.get("accountCartId"),
              id,
              quantity
            );
            if (resp) {
              this.getCartData();
              this.setState({ isLoading1: false });
            }
          }
        }
      } else {
        this.removeItemFromCart(id);
      }
    } catch (error) {
      this.setState({ isLoading1: false });
    }
  };

  UNSAFE_componentWillReceiveProps(props, state) {
    if (getValue(props, "ids", []).includes(props.product._id)) {
      this.setState({
        IsFavActive: true,
      });
    }
  }
  handleWishList = (id) => {
    if (id) {
      Cookies.set("wishlistId", id);
      Cookies.set("wishlistIdset", true);

      window.location.href = "/login";
    }
  };

  render() {
    let cartItem = getValue(
      this.props,
      "cart.cart.myCart.cart.items",
      []
    ).filter((item) => {
      if (item.item) {
        if (this.props.product._id === item.item.id) {
          return item;
        }
      }
    });
    return (
      <>
        <div className="product-card-full d-none d-md-block">
          <div className="product-actions">
            {getValue(this.props, "product.status", "") !== "sold_out" ? (
              <>
                {getValue(this.props, "product.status", "") ? (
                  <h3 className="close-soon">
                    <span className="closing-soon-dot"></span>{" "}
                    {getValue(this.props, "product.status", "") ===
                    "closing_soon"
                      ? "Closing Soon"
                      : getValue(this.props, "product.status", "")}
                  </h3>
                ) : getValue(this.props, "product.status", "") !==
                  "sold_out" ? (
                  <h3>
                    <span className="available-dot"></span> Available
                  </h3>
                ) : (
                  <h3 className="not-av">
                    <span className="not-available-dot"></span> Not Available
                  </h3>
                )}
              </>
            ) : (
              <h3></h3>
            )}
            {/* closing soon */}
            {/* <h3 className="close-soon">
                <span className="closing-soon-dot"></span> Closing Soon
              </h3> */}
            {/* early access */}
            {/* <h3 className="early-acess">
                <span className="early-acess-dot"></span> Early Access
              </h3> */}
            <p>
              {this.props.auth.isAuthenticated ? (
                <Button
                  color="nothing"
                  onClick={() => {
                    this.favActive(getValue(this.props, "product._id", ""));
                  }}
                  className={this.state.IsFavActive ? "active" : ""}
                >
                  <FavSVG />
                </Button>
              ) : (
                <Button color="nothing">
                  <div
                    onClick={() =>
                      this.handleWishList(
                        getValue(this.props, "product._id", "")
                      )
                    }
                  >
                    <FavSVG />
                  </div>
                </Button>
              )}
              {/* <Button
                color="nothing"
                style={{ position: "relative" }}
                onClick={() => this.toggleSocial()}
              >
                <ShareSVG />
                <div
                  className={
                    "abs-social " + (this.state.isSocial ? "active" : "")
                  }
                >
                  <SocialMediaButtons />
                </div>
              </Button> */}
            </p>
          </div>
          <NavLink
            to={`/campaigns/${getValue(this.props, "product.slug", "")}`}
          >
            <div className="number-circle">
              <div className="number-svg">
                <svg
                  width="160"
                  height="160"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <defs>
                      <linearGradient
                        id="linear"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop offset="5.64%" stopColor="#cc0033" />
                        <stop offset="26.64%" stopColor="#fc3" />
                        <stop offset="50.64%" stopColor="#fc3" />
                        <stop offset="77.66%" stopColor="#2DC565" />
                      </linearGradient>
                    </defs>
                    <circle
                      strokeLinecap="round"
                      strokeDasharray="440"
                      // change this bottom value - min(0%) => 440 and max(100%) => 0
                      strokeDashoffset={
                        440 -
                        (Math.floor(
                          ((parseInt(
                            getValue(this.props, "product.sold_units", 1)
                          ) /
                            parseInt(
                              getValue(this.props, "product.total_units", 1)
                            )) *
                            10000) /
                            100
                        ) /
                          100) *
                          440
                      }
                      stroke={
                        ((parseInt(
                          getValue(this.props, "product.sold_units", 1)
                        ) /
                          parseInt(
                            getValue(this.props, "product.total_units", 1)
                          )) *
                          10000) /
                          100 <
                        34
                          ? "#2DC565"
                          : ((parseInt(
                              getValue(this.props, "product.sold_units", 1)
                            ) /
                              parseInt(
                                getValue(this.props, "product.total_units", 1)
                              )) *
                              10000) /
                              100 <
                            66
                          ? "#fc3"
                          : ((parseInt(
                              getValue(this.props, "product.sold_units", 1)
                            ) /
                              parseInt(
                                getValue(this.props, "product.total_units", 1)
                              )) *
                              10000) /
                              100 <
                            100
                          ? "#cc0033"
                          : ""
                      }
                      className="circle_animation"
                      r="70"
                      cy="80"
                      cx="80"
                      strokeWidth="10"
                      fill="none"
                    />
                  </g>
                </svg>
              </div>
              <div className="number-text">
                {((parseInt(getValue(this.props, "product.sold_units", 1)) /
                  parseInt(getValue(this.props, "product.total_units", 1))) *
                  10000) /
                  100 <
                100 ? (
                  <>
                    <h2 className="home-heading">
                      {Math.floor(
                        ((parseInt(
                          getValue(this.props, "product.sold_units", 1)
                        ) /
                          parseInt(
                            getValue(this.props, "product.total_units", 1)
                          )) *
                          10000) /
                          100
                      )}
                      %
                    </h2>
                    <h3>SOLD</h3>
                  </>
                ) : (
                  <h3 className="sould-out">SOLD OUT</h3>
                )}
              </div>
            </div>
          </NavLink>
          <div className={"product-box "}>
            {/* {getValue(this.props, "product.tags[0].title", "") ? (
              <div className="featured-div">
                {getValue(this.props, "product.tags[0].title", "")}
              </div>
            ) : (
                ""
              )} */}
            <Row>
              <Col
                md="4"
                className={
                  getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : "opacity05"
                }
              >
                <NavLink
                  to={`/campaigns/${getValue(this.props, "product.slug", "")}`}
                >
                  <div className="product-img">
                    <img
                      src={
                        API_URL +
                        getValue(
                          this.props,
                          "product.product.images[0].url",
                          ""
                        )
                      }
                      alt=""
                    />
                  </div>
                  <h4>
                    Buy {getValue(this.props, "product.product.title", "")}
                  </h4>
                  <h3>₹ {getValue(this.props, "product.product.price", "")}</h3>
                </NavLink>
              </Col>
              <Col md="4">
                <div className="product-center">
                  <h5
                    className={
                      getValue(this.props, "product.status", "") !== "sold_out"
                        ? ""
                        : "opacity05"
                    }
                  >
                    <b>{getValue(this.props, "product.sold_units", 0)}</b>{" "}
                    <span style={{ fontWeight: "400" }}>SOLD OUT OF</span>{" "}
                    <b>{getValue(this.props, "product.total_units", 0)}</b>
                  </h5>
                  {!getValue(this.props, "productId", []).includes(
                    getValue(this.props, "product._id", "")
                  ) ? (
                    <Button
                      color="red"
                      onClick={() => {
                        this.addToCart(getValue(this.props, "product._id", ""));
                      }}
                      className={
                        getValue(this.props, "product.status", "") !==
                        "sold_out"
                          ? ""
                          : "d-none"
                      }
                    >
                      {getValue(this.props, "product.status", "") !== "sold_out"
                        ? "ADD TO CART"
                        : "Sold Out"}
                    </Button>
                  ) : (
                    <>
                      {!(getValue(cartItem[0], "quantity", 0) === 0) ? (
                        <>
                          <div className="btn btn-red btn-plus-minus">
                            <Button
                              color="nothing"
                              className="right-plus"
                              onClick={() => {
                                this.updateCartQuantity(
                                  getValue(cartItem[0], "id", ""),
                                  parseInt(
                                    getValue(cartItem[0], "quantity", 0) + 1
                                  )
                                );
                              }}
                            >
                              {!this.state.isLoading ? (
                                <img src="/images/plus.svg" alt="plus" />
                              ) : (
                                "..."
                              )}{" "}
                            </Button>
                            <span className="mid-numb">
                              {getValue(cartItem[0], "quantity", 0)}
                            </span>
                            <Button
                              color="nothing"
                              className="left-minus"
                              onClick={() => {
                                this.updateCartQuantityDec(
                                  getValue(cartItem[0], "id", ""),
                                  parseInt(
                                    getValue(cartItem[0], "quantity", 0) - 1
                                  )
                                );
                              }}
                            >
                              {!this.state.isLoading1 ? (
                                <img src="/images/minus.svg" alt="minus" />
                              ) : (
                                "..."
                              )}{" "}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <Button
                          color="red"
                          onClick={() => {
                            this.addToCart(
                              getValue(this.props, "product._id", "")
                            );
                          }}
                          className={
                            getValue(this.props, "product.status", "") !==
                            "sold_out"
                              ? ""
                              : "d-none"
                          }
                        >
                          {getValue(this.props, "product.status", "") !==
                          "sold_out"
                            ? "ADD TO CART"
                            : "Sold Out"}
                        </Button>
                      )}
                    </>
                  )}
                </div>
                {getValue(this.props, "product.status", "") !== "sold_out" ? (
                  <>
                    <p className="mt-2">
                      Results announced on{" "}
                      <b>
                        {dayjs(
                          getValue(this.props, "product.end_date", "")
                        ).format("MMM DD YYYY")}
                      </b>{" "}
                      or when the campaign is sold out. Whichever is earlier
                    </p>
                  </>
                ) : (
                  <>
                    <div className="sold-out-div">
                      <h5>Draw Date</h5>
                      <h4>
                        {getValue(this.props, "product.draw_date", "")
                          ? dayjs(
                              getValue(this.props, "product.draw_date", "")
                            ).format("MMM DD YYYY")
                          : "Will be announced"}
                      </h4>
                    </div>
                  </>
                )}
              </Col>
              <Col
                md="4"
                className={
                  getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : "opacity05"
                }
              >
                <NavLink
                  to={`/campaigns/${getValue(this.props, "product.slug", "")}`}
                >
                  <div className="product-img">
                    <img
                      src={
                        API_URL +
                        getValue(this.props, "product.reward.images[0].url", "")
                      }
                      alt=""
                    />
                  </div>
                  <h4>Get a chance to WIN</h4>
                  <h3>{getValue(this.props, "product.reward.title", "")}</h3>
                  {/* <h3>
                    ₹ {getValue(this.props, "product.reward.price", "")}
                  </h3> */}
                </NavLink>
              </Col>
            </Row>
          </div>
        </div>
        <div className="product-card-full product-card-full-mob d-md-none">
          <NavLink
            to={`/campaigns/${getValue(this.props, "product.slug", "")}`}
          >
            <div className="number-circle">
              <div className="number-svg">
                <svg
                  width="120"
                  height="120"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <defs>
                      <linearGradient
                        id="linear1"
                        x1="0%"
                        y1="0%"
                        x2="100%"
                        y2="0%"
                      >
                        <stop offset="5.64%" stopColor="#cc0033" />
                        <stop offset="26.64%" stopColor="#fc3" />
                        <stop offset="50.64%" stopColor="#fc3" />
                        <stop offset="77.66%" stopColor="#2DC565" />
                      </linearGradient>
                    </defs>
                    <circle
                      // strokeLinecap="round"
                      strokeDasharray="350"
                      // change this bottom value - min(0%) => 440 and max(100%) => 0
                      // strokeDashoffset="0"
                      strokeDashoffset={
                        350 -
                        (Math.floor(
                          ((parseInt(
                            getValue(this.props, "product.sold_units", 1)
                          ) /
                            parseInt(
                              getValue(this.props, "product.total_units", 1)
                            )) *
                            10000) /
                            100
                        ) /
                          100) *
                          350
                      }
                      stroke={
                        ((parseInt(
                          getValue(this.props, "product.sold_units", 1)
                        ) /
                          parseInt(
                            getValue(this.props, "product.total_units", 1)
                          )) *
                          10000) /
                          100 <
                        34
                          ? "#2DC565"
                          : ((parseInt(
                              getValue(this.props, "product.sold_units", 1)
                            ) /
                              parseInt(
                                getValue(this.props, "product.total_units", 1)
                              )) *
                              10000) /
                              100 <
                            66
                          ? "#fc3"
                          : ((parseInt(
                              getValue(this.props, "product.sold_units", 1)
                            ) /
                              parseInt(
                                getValue(this.props, "product.total_units", 1)
                              )) *
                              10000) /
                              100 <
                            100
                          ? "#cc0033"
                          : ""
                      }
                      className="circle_animation"
                      r="55"
                      cy="60"
                      cx="60"
                      strokeWidth="10"
                      fill="none"
                    />
                  </g>
                </svg>
              </div>
              <div className="number-text">
                {((parseInt(getValue(this.props, "product.sold_units", 1)) /
                  parseInt(getValue(this.props, "product.total_units", 1))) *
                  10000) /
                  100 <
                100 ? (
                  <>
                    <h2 className="home-heading">
                      {Math.floor(
                        ((parseInt(
                          getValue(this.props, "product.sold_units", 1)
                        ) /
                          parseInt(
                            getValue(this.props, "product.total_units", 1)
                          )) *
                          10000) /
                          100
                      )}
                      %
                    </h2>
                    <h3>SOLD</h3>
                  </>
                ) : (
                  <h3 className="sould-out">SOLD OUT</h3>
                )}
              </div>
            </div>
          </NavLink>
          <div className={"product-box "}>
            {getValue(this.props, "product.status", "") !== "sold_out" ? (
              <>
                {getValue(this.props, "product.status", "") ? (
                  <h3 className="close-soon">
                    <span className="closing-soon-dot"></span>{" "}
                    {getValue(this.props, "product.status", "")}
                  </h3>
                ) : getValue(this.props, "product.status", "") !==
                  "sold_out" ? (
                  <h3>
                    <span className="available-dot"></span> Available
                  </h3>
                ) : (
                  <h3 className="not-av">
                    <span className="not-available-dot"></span> Not Available
                  </h3>
                )}
              </>
            ) : (
              <h3></h3>
            )}
            <p className="action-div">
              {this.props.auth.isAuthenticated ? (
                <Button
                  color="nothing"
                  onClick={() => {
                    this.favActive(getValue(this.props, "product._id", ""));
                  }}
                  className={this.state.IsFavActive ? "active" : ""}
                >
                  <FavSVG />
                </Button>
              ) : (
                <Button color="nothing">
                  <NavLink to="/login">
                    <FavSVG />
                  </NavLink>
                </Button>
              )}
            </p>
            <NavLink
              to={`/campaigns/${getValue(this.props, "product.slug", "")}`}
            >
              <h4
                className={
                  "text-blue text-center" +
                  (getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : " opacity05")
                }
              >
                <b>{getValue(this.props, "product.sold_units", 0)}</b> Sold Out
                Of <b>{getValue(this.props, "product.total_units", 0)}</b>
              </h4>
              <div
                className={
                  "img-prod-2 mb-4" +
                  (getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : " opacity05")
                }
              >
                <img
                  className="gift-img1"
                  src={API_URL + getValue(this.props, "product.image.url", "")}
                  alt=""
                />
              </div>
              <h5
                className={
                  "text-center mb-0" +
                  (getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : " opacity05")
                }
              >
                {" "}
                Get a chance to <b>'Win'</b>
              </h5>

              <h3
                className={
                  "mb-2 mt-0" +
                  (getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : " opacity05")
                }
              >
                {getValue(this.props, "product.reward.title", "")}
              </h3>
              <h4
                className={
                  "mt-2 text-black" +
                  (getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : " opacity05")
                }
              >
                Buy{" "}
                <span className="text-blue">
                  {getValue(this.props, "product.product.title", "")}
                </span>{" "}
                for{" "}
                <b className="text-blue">
                  ₹ {getValue(this.props, "product.product.price", "")}
                </b>
              </h4>
            </NavLink>
            {!getValue(this.props, "productId", []).includes(
              getValue(this.props, "product._id", "")
            ) ? (
              <Button
                color="red"
                onClick={() => {
                  this.addToCart(getValue(this.props, "product._id", ""));
                }}
                className={
                  getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : "d-none"
                }
              >
                {getValue(this.props, "product.status", "") !== "sold_out"
                  ? "ADD TO CART"
                  : "Sold Out"}
              </Button>
            ) : (
              <>
                {!(getValue(cartItem[0], "quantity", 0) === 0) ? (
                  <>
                    <div className="btn btn-red btn-plus-minus">
                      <Button
                        color="nothing"
                        className="right-plus"
                        onClick={() => {
                          this.updateCartQuantity(
                            getValue(cartItem[0], "id", ""),
                            parseInt(getValue(cartItem[0], "quantity", 0) + 1)
                          );
                        }}
                      >
                        {!this.state.isLoading ? (
                          <img src="/images/plus.svg" alt="plus" />
                        ) : (
                          "..."
                        )}{" "}
                      </Button>
                      <span className="mid-numb">
                        {getValue(cartItem[0], "quantity", 0)}
                      </span>
                      <Button
                        color="nothing"
                        className="left-minus"
                        onClick={() => {
                          this.updateCartQuantityDec(
                            getValue(cartItem[0], "id", ""),
                            parseInt(getValue(cartItem[0], "quantity", 0) - 1)
                          );
                        }}
                      >
                        {!this.state.isLoading1 ? (
                          <img src="/images/minus.svg" alt="minus" />
                        ) : (
                          "..."
                        )}{" "}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    color="red"
                    onClick={() => {
                      this.addToCart(getValue(this.props, "product._id", ""));
                    }}
                    className={
                      getValue(this.props, "product.status", "") !== "sold_out"
                        ? ""
                        : "d-none"
                    }
                  >
                    {getValue(this.props, "product.status", "") !== "sold_out"
                      ? "ADD TO CART"
                      : "Sold Out"}
                  </Button>
                )}
              </>
            )}
            {getValue(this.props, "product.status", "") !== "sold_out" ? (
              <>
                <p className="mt-2">
                  Results announced on{" "}
                  <b>{getValue(this.props, "product.end_date", "")}</b> or when
                  the campaign is sold out. Whichever is earlier
                </p>
              </>
            ) : (
              <>
                <div className="sold-out-div">
                  <h5>Draw Date</h5>
                  <h4>{getValue(this.props, "product.draw_date", "")}</h4>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
    cart: cartList(state),
  };
};
export default connect(mapStateToProps)(ProductCardFullClosing);
