import React from "react";
import { Row, Col, Button, UncontrolledCollapse, Card, CardBody } from "reactstrap";
class FAQCollapse extends React.Component {
  state = {
    toggle: false
  }
  toggle = () => {
    this.setState({
      toggle: !this.state.toggle
    })
  }
  render() {
    const {i, dangerouslySetInnerHTML, dangerouslySetInnerHTML2} = this.props;
    return (
      <>
      <div className="faq-con">
                    <div>
                      <Button color="nothing" block id={"toggler" + i} onClick={() => this.toggle()} className="faq-btn">
                        <span dangerouslySetInnerHTML={dangerouslySetInnerHTML}></span>
                        {this.state.toggle ? 
                        <span className="min"><img src="/images/minus-png.png" alt="" /></span>:
                        <span className="plu"><img src="/images/plus-png.svg" alt="" /></span>
                      }
                      </Button>
                    </div>
                    <UncontrolledCollapse toggler={"#toggler" + i}>
                      <div className="faq-bott">
                        <p dangerouslySetInnerHTML={dangerouslySetInnerHTML2}></p>
                      </div>
                    </UncontrolledCollapse>
                  </div>
      </>
    );
  }
}
export default FAQCollapse;
