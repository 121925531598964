export default `
query campaigns($id:ID!,$sort:String){ 
    campaigns(where:{categories:{id:$id}},sort:$sort) {
      status
      _id
        title
        description
        points_per_unit
        coupons_per_unit
        total_units
        sold_units
        draw_date
        end_date
        image{
          url
        }
        product{
            title
          description
          price
          images{
            url
          }
        }
        reward{
          title
          description
          price
          images{
            url
          }
        }
    }
  }
`;