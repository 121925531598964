import React from 'react';
import { Container, Row, Col } from 'reactstrap';
class MapContact extends React.Component {
    render() {
        return (
            <section className="map-contact d-none d-md-block">
                <Container fluid={true}>
                    <Row>
                        <Col md="6">
                            <h1 className="home-heading1">For a better shopping experience download our app</h1>

                        </Col>
                        <Col xl="6">
                            <img src="/images/Google-play-store.png" alt="" className="app-image1 " />
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
export default MapContact