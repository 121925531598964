// ******************************************** //
// ********* Personal details Page   ********** //
// ******************************************** //

import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import { Col, Row, Container } from "reactstrap";
import PersonalDetailsCard from "../../_components/cards/PersonalDetailsCard";
import PersonalFormCard from "../../_components/cards/PersonalFormCard";
import LeftMenu from "../../_components/cards/LeftMenu";
import profile from "../../services/Queries/profile/myProfile";
import { getValue, setValue } from "../../utils/lodash";
import graphQLRequest from "../../services/graphQlRequest";
import SimpleReactValidator from "simple-react-validator";
import { handleOnChange } from "../../utils/handleOnChange";
import { RequestModel } from "../../_components/common/RequestModel";
import fetchUpdateProfile from "../../services/Queries/profile/fetchUpdateProfile";
import fetchUpdateEmail from "../../services/Queries/profile/fetchUpdateEmail";
import { toast } from "react-toastify";
import me from "../../services/Queries/profile/me";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";
import { API_URL } from "../../env";
import axios from "axios";
import Cookies from "js-cookie";
import {
  getCountryCallingCode,
  getCountries,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en.json";
import countriesData from "country-telephone-currency-data";
import Flag from "react-world-flags";

class PersonalDetails extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accountInfo: [],
      email: "",
      phoneCode: "",
      countryCode: "",
      countries: [],
      request: RequestModel.PROFILE_REQUEST,
    };
    this.validator = new SimpleReactValidator();
  }
  componentDidMount = async () => {
    window.scroll({ top: 0, behavior: "smooth" });
    if (Cookies.get("accountCartId")) {
      this.getEmail();
      this.getProfileData();
    }
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    }
  };

  getProfileData = async () => {
    let countries = [];
    let options = getCountries().map((item) => {
      countries.push({
        value: "+" + getCountryCallingCode(item) + " " + en[item] + "_" + item,
        label: "+" + getCountryCallingCode(item) + " " + en[item] + "_" + item,
      });
    });
    this.setState({
      countries,
    });
    let resp = await graphQLRequest(profile);
    if (resp) {
      let countriesDatas = countriesData.dialCodeValue.filter(
        (item) =>
          item.value ===
          (getValue(resp, "myProfile.country_code", "")
            ? getValue(resp, "myProfile.country_code", "")
            : "+91")
      );

      this.setState({
        accountInfo: getValue(resp, "myProfile", []),
        email: getValue(resp, "myProfile.user.email", ""),

        countryCode: {
          id: getValue(countriesDatas[0], "id", ""),
          label: (
            <>
              <Flag code={getValue(countriesDatas[0], "id", "")} height="16" />{" "}
              {getValue(countriesDatas[0], "value", "")}
            </>
          ),
          value: getValue(countriesDatas[0], "value", ""),
        },
      });

      this.setState((prevState) => ({
        request: {
          ...prevState.request,
          firstname: getValue(resp, "myProfile.firstname", ""),
          lastname: getValue(resp, "myProfile.lastname", ""),
          phone: getValue(resp, "myProfile.phone", ""),
          country_code: getValue(resp, "myProfile.country_code", ""),
        },
      }));
    }
  };

  getEmail = async () => {
    let resp = await graphQLRequest(me);
    if (resp) {
      this.setState({
        email: getValue(resp, "me.email", ""),
      });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      if (!this.state.email) {
        let request = {
          firstname: this.state.request.firstname,
          lastname: this.state.request.lastname,
          phone: this.state.request.phone,
          country_code: this.state.countryCode.value,
        };
        let resp = await fetchUpdateProfile(request);
        if (resp) {
          toast.success("Updated successfully");
        }
      } else {
        let request1 = {
          email: this.state.email,
        };
        let request = {
          firstname: this.state.request.firstname,
          lastname: this.state.request.lastname,
          phone: this.state.request.phone,
          country_code: this.state.countryCode.value,
        };
        let response = await fetchUpdateProfile(request);
        let resp = await fetchUpdateEmail(this.state.email);
        if (resp) {
          toast.success("Updated successfully");
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  handleChange = (e, path) => {
    if (e.target.name === "phone") {
      const { name, value } = e.target;
      if (value.length <= 10) {
        this.setState((prevState) => ({
          request: {
            ...prevState.request,
            [name]: value,
          },
        }));
      } else {
        // toast.error("s")
      }
    } else {
      handleOnChange(e, path, this.state.request);
      this.setState({
        ...this.state,
      });
    }
  };

  handleChangeState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  onDrop = (files) => {
    console.log(files);
    // this.setState({files})
    const authToken = localStorage.jwt;
    const formData = new FormData();
    Array.from(files).forEach((image) => {
      formData.append("files", image);
    });

    axios
      .post(`${API_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authToken,
        },
      })
      .then(async (res) => {
        setValue(this.state, "request.image", res.data[0]._id);
        let resp = await fetchUpdateProfile(this.state.request);
        if (resp) {
          this.getProfileData();
          toast.success("Profile image updated");
        } else {
          this.getProfileData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target.files);
    const authToken = localStorage.jwt;
    const formData = new FormData();
    Array.from(e.target.files).forEach((image) => {
      formData.append("files", image);
    });

    axios
      .post(`${API_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + authToken,
        },
      })
      .then(async (res) => {
        setValue(this.state, "request.image", res.data[0]._id);
        let resp = await fetchUpdateProfile(this.state.request);
        if (resp) {
          this.getProfileData();
          toast.success("Profile image updated");
        } else {
          this.getProfileData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleContactDetails = (value) => {
    this.setState({
      countryCode: value,
    });
  };

  render() {
    return (
      <>
        {/* <Header productPage={"d-none d-md-block"} /> */}
        <Container fluid={true}>
          <section className="dashboard-page dashboard-page-resp">
            <h2 className="dashboard-heading">My Profile</h2>
            <Row>
              <Col md={{ size: "3" }} className="d-none d-md-block">
                <LeftMenu />
              </Col>
              <Col md={{ size: "9" }}>
                <PersonalDetailsCard
                  accountInfo={this.state.accountInfo}
                  email={this.state.email}
                  onSubmit={this.onSubmit}
                  onDrop={this.onDrop}
                />
                <PersonalFormCard
                  countries={this.state.countries}
                  countryCode={this.state.countryCode}
                  accountInfo={this.state.accountInfo}
                  user={this.state.request}
                  email={this.state.email}
                  validator={this.validator}
                  handleChange={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  handleContactDetails={this.handleContactDetails}
                  handleChangeState={this.handleChangeState}
                />
              </Col>
            </Row>
          </section>
        </Container>
        <div className="d-none d-md-block">{/* <Footer /> */}</div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};
export default connect(mapStateToProps)(PersonalDetails);
