import React from "react";
import { NavLink } from "react-router-dom";
import CouponOutlinedSVG from "../svg/coupon-outlined-svg";
import LogoutSVG from "../svg/logout-svg";
import RightArrowSVG from "../svg/right-arrow-svg";
import SearchSVG from "../svg/search-svg";
import SearchOutlinedSVG from "../svg/search-svg-out";
import UserSVG from "../svg/user-svg";
import UserOutlineSVG from "../svg/user-svg-outline";
import WalletOutlinedSVG from "../svg/wallet-outlined-svg";
import WishlistSVG from "../svg/wishlist-svg";import { connect } from "react-redux";
import WishlistOutlinesSVG from "../svg/wishlist-svg-outlined";
import { userLogout } from "../../redux/_actions/userAction";
import {
  selectCurrentUser
} from "../../redux/_selectors/userSelectors";
class LeftMenu extends React.Component {
  onLogout = (e) => {
    e.preventDefault();
    this.props.dispatch(userLogout());
  };
  render() {
    return (
      <div className="left-menu">
        <ul>
          <li>
            <NavLink to="/personal-details">
              <UserOutlineSVG /> Profile Details
              <span>
                <RightArrowSVG />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/wishlist">
              <WishlistOutlinesSVG /> Wishlist
              <span>
                <RightArrowSVG />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/wallet">
              <WalletOutlinedSVG /> Wallet
              <span>
                <RightArrowSVG />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/coupons">
              <CouponOutlinedSVG /> Coupons
              <span>
                <RightArrowSVG />
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/order-details">
              <SearchOutlinedSVG /> Order Details
              <span>
                <RightArrowSVG />
              </span>
            </NavLink>
          </li>
          <li>
            <a style={{cursor:"pointer"}} onClick={this.onLogout}><LogoutSVG /> Logout<span><RightArrowSVG /></span></a>
          </li>
        </ul>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};
export default connect(mapStateToProps)(LeftMenu);
