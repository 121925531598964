import React from 'react';
class CartSVG extends React.Component {
    render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 17.25H6.42139C6.24574 17.25 6.07567 17.1884 5.94082 17.0758C5.80597 16.9633 5.7149 16.807 5.68348 16.6342L3.18015 2.86584C3.14873 2.69303 3.05766 2.53673 2.92281 2.42419C2.78796 2.31164 2.61789 2.25 2.44225 2.25H0.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.75 21C7.78553 21 8.625 20.1605 8.625 19.125C8.625 18.0895 7.78553 17.25 6.75 17.25C5.71447 17.25 4.875 18.0895 4.875 19.125C4.875 20.1605 5.71447 21 6.75 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18 21C19.0355 21 19.875 20.1605 19.875 19.125C19.875 18.0895 19.0355 17.25 18 17.25C16.9645 17.25 16.125 18.0895 16.125 19.125C16.125 20.1605 16.9645 21 18 21Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.75 6H20.1013C20.2112 6 20.3197 6.02413 20.4192 6.07069C20.5187 6.11725 20.6068 6.1851 20.6772 6.26944C20.7475 6.35378 20.7986 6.45255 20.8266 6.55878C20.8546 6.665 20.8589 6.77608 20.8392 6.88416L19.612 13.6342C19.5806 13.807 19.4895 13.9633 19.3546 14.0758C19.2198 14.1884 19.0497 14.25 18.8741 14.25H5.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



        )
    }
}
export default CartSVG