import React from 'react';
import { Col, Input, Row } from 'reactstrap';
class SettingsCard extends React.Component {
    render() {
        return (
            <div className="card settings-card">
                <Row>
                    <Col md="6">
                        <div className="settings-form">
                            <label htmlFor="language">Language</label>
                            <Input type="select" id="language">
                                <option>English (EN)</option>
                                <option>Spanish (SN)</option>
                            </Input>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="settings-form">
                            <label htmlFor="currency">Currency</label>
                            <Input type="select" id="currency">
                                <option>Rupees (INR)</option>
                                <option>EURO (EU)</option>
                            </Input>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default SettingsCard