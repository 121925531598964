import React from "react";
import { Row, Col, Button, Container } from "reactstrap";
import { getValue } from "../../utils/lodash";
import { API_URL } from "../../env";
import Cookies from "js-cookie";
import fetchCreateAnonymousCart from "../../services/Queries/cart/GuestCart/fetchCreateAnonymousCart";
import {
  fetchAnonymousCartData,
  fetchCartData,
} from "../../redux/_actions/cartAcition";
import fetchAddItemsCart from "../../services/Queries/cart/common/fetchAddItemToCart";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import {
  cartList,
  selectCurrentUser,
} from "../../redux/_selectors/userSelectors";
import fetchRemoveItemsCart from "../../services/Queries/cart/common/fetchUpdateItemCart";
import fetchDeleteItemsCart from "../../services/Queries/cart/common/fetchRemoveItemToCart";
import FavSVG from "../svg/fav-svg";
import fetchAddToWishlist from "../../services/Queries/wishlist/fetchAddToWishlist";
import fetchRemoveFromWishlist from "../../services/Queries/wishlist/fetchRemoveFromWishlist";
import graphQLRequest from "../../services/graphQlRequest";
import myProfile from "../../services/Queries/profile/myProfile";

var dayjs = require("dayjs");

class CampaignProduct extends React.Component {
  constructor() {
    super();
    this.state = {
      url1value: "",
      IsFavActive: false,
      url2value: "",
      tabActive: 1,
      productIds: [],
      wishListIds: [],
      ids: [],
      isLoop: true,
      isLoading: false,
      isLoading1: false,
    };
    this.toggleTab1 = this.toggleTab1.bind(this);
    this.toggleTab2 = this.toggleTab2.bind(this);
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getCart();
    if (getValue(localStorage, "jwt.length", 0) !== 0) {
      this.getData();
    }
    if (this.props.product) {
      if (getValue(this.props, "ids", []).includes(this.props.product._id)) {
        this.setState({
          IsFavActive: true,
        });
      }
    }
    if (this.props.status) {
      this.setState({
        IsFavActive: this.props.status,
      });
    }
  }

  // wishlist
  favActive = async (id) => {
    if (getValue(localStorage, "jwt.length", 0) !== 0) {
      if (!this.state.ids.includes(getValue(this.props, "product._id"))) {
        let resp = await fetchAddToWishlist(id);
        if (resp) {
          // toast.success("Added successfully");
          this.setState({
            IsFavActive: resp.addToMyWishlist.added,
          });
          window.location.reload();
          this.setState({ ...this.state });
        }
      } else {
        let resp = await fetchRemoveFromWishlist(id);
        if (resp) {
          // toast.success("removed successfully");
          window.location.reload();
          this.setState({
            IsFavActive: false,
            // resp.removeFromWishlist.removed
          });
          this.setState({ ...this.state });
        }
      }
    } else {
    }
  };

  getData = async () => {
    let resp = await graphQLRequest(myProfile);
    if (resp) {
      this.setState({
        wishListIds: getValue(resp, "myProfile.wishlist"),
      });
      this.setState({ ...this.state });
      this.wishlistIds();
    }
  };

  wishlistIds = () => {
    if (this.state.wishListIds) {
      this.state.wishListIds.map((item) => this.state.ids.push(item._id));
      this.setState({ ...this.state });
    }
  };
  handleWishList = (id) => {
    if (id) {
      Cookies.set("wishlistId", id);
      Cookies.set("wishlistIdset", true);

      window.location.href = "/login";
    }
  };

  getCart = async () => {
    if (Cookies.get("anonymousCartToken") || Cookies.get("anonymousCartId")) {
      let input = { anonymous_cart_token: Cookies.get("anonymousCartToken") };
      this.props.dispatch(fetchAnonymousCartData(input));
    } else {
      this.props.dispatch(fetchCartData());
    }
  };

  toggleTab1 = () => {
    this.setState({
      tabActive: 1,
    });
  };
  toggleTab2 = () => {
    this.setState({
      tabActive: 2,
    });
  };

  addToCart = async (id) => {
    if (id) {
      if (
        (!Cookies.get("anonymousCartToken") ||
          !Cookies.get("anonymousCartId")) &&
        !Cookies.get("accountCartId")
      ) {
        let data = {
          campaign_id: id,
          quantity: 1,
        };
        let resp = await fetchCreateAnonymousCart(data);
        if (resp) {
          let input = {
            anonymous_cart_token: resp.createAnonymousCart.anonymous_cart_token,
          };
          this.props.dispatch(fetchAnonymousCartData(input));
          Cookies.set(
            "anonymousCartToken",
            resp.createAnonymousCart.anonymous_cart_token
          );
        }
      } else if (
        Cookies.get("anonymousCartId") &&
        !Cookies.get("accountCartId")
      ) {
        let resp = await fetchAddItemsCart(
          Cookies.get("anonymousCartId"),
          id,
          1,
          Cookies.get("anonymousCartToken")
        );
        if (resp) {
          let input = {
            anonymous_cart_token: Cookies.get("anonymousCartToken"),
          };
          this.props.dispatch(fetchAnonymousCartData(input));
        } else {
          toast.error("Product already exist");
        }
      } else if (
        !Cookies.get("anonymousCartId") &&
        Cookies.get("accountCartId")
      ) {
        let resp = await fetchAddItemsCart(Cookies.get("accountCartId"), id, 1);
        if (resp) {
          this.props.dispatch(fetchCartData());
        } else {
          toast.error("Product already exist");
        }
      }
    }
  };

  componentDidUpdate(props, state) {
    if (props.product && this.state.isLoop) {
      this.setState({ isLoop: false });
      if (typeof window !== "undefined") {
        console.log(window.dataLayer);
        window.dataLayer = window.dataLayer || [];
        let viewItem = [];
        viewItem.push({
          item_name: getValue(props, "product.product.title", ""),
          item_id: getValue(props, "product._id", ""),
          price: getValue(props, "product.product.price", ""),
        });
        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            items: viewItem,
          },
        });
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    props.cart &&
      getValue(props, "cart.cart.myCart.cart.items", []).map((pro) => {
        if (pro.item) {
          if (!state.productIds.includes(pro.id)) {
            state.productIds.push(pro.item.id);
          }
        }
      });
  }
  replaceImage = (url1) => {
    this.setState({
      url1value: url1,
    });
  };
  replaceImageProduct = (url1) => {
    this.setState({
      url2value: url1,
    });
  };

  getCartData = async () => {
    if (Cookies.get("anonymousCartToken") || Cookies.get("anonymousCartId")) {
      let input = {
        anonymous_cart_token: Cookies.get("anonymousCartToken"),
      };
      this.props.dispatch(fetchAnonymousCartData(input));
    } else {
      this.props.dispatch(fetchCartData());
    }
  };

  updateCartQuantity = async (id, quantity) => {
    try {
      if (
        Cookies.get("anonymousCartToken") &&
        Cookies.get("anonymousCartId") &&
        !Cookies.get("accountCartId")
      ) {
        if (id && quantity) {
          this.setState({ isLoading: true });
          let resp = await fetchRemoveItemsCart(
            Cookies.get("anonymousCartId"),
            id,
            quantity,
            Cookies.get("anonymousCartToken")
          );
          if (resp) {
            this.getCartData();
            this.setState({ isLoading: false });
          }
        }
      } else {
        if (id && quantity) {
          this.setState({ isLoading: true });
          let resp = await fetchRemoveItemsCart(
            Cookies.get("accountCartId"),
            id,
            quantity
          );
          if (resp) {
            this.getCartData();
            this.setState({ isLoading: false });
          }
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  removeItemFromCart = async (id) => {
    if (
      Cookies.get("anonymousCartToken") &&
      Cookies.get("anonymousCartId") &&
      !Cookies.get("accountCartId")
    ) {
      if (id) {
        let resp = await fetchDeleteItemsCart(
          Cookies.get("anonymousCartId"),
          id,
          Cookies.get("anonymousCartToken")
        );
        if (resp) {
          this.getCartData();
        }
      }
    } else {
      if (id) {
        let resp = await fetchDeleteItemsCart(Cookies.get("accountCartId"), id);
        if (resp) {
          this.getCartData();
        }
      }
    }
  };

  updateCartQuantityDec = async (id, quantity) => {
    try {
      if (quantity !== 0) {
        if (
          Cookies.get("anonymousCartToken") &&
          Cookies.get("anonymousCartId") &&
          !Cookies.get("accountCartId")
        ) {
          if (id && quantity) {
            this.setState({ isLoading1: true });
            let resp = await fetchRemoveItemsCart(
              Cookies.get("anonymousCartId"),
              id,
              quantity,
              Cookies.get("anonymousCartToken")
            );
            if (resp) {
              this.setState({ isLoading1: false });
              this.getCartData();
            }
          }
        } else {
          if (id && quantity) {
            this.setState({ isLoading1: true });
            let resp = await fetchRemoveItemsCart(
              Cookies.get("accountCartId"),
              id,
              quantity
            );
            if (resp) {
              this.setState({ isLoading1: false });
              this.getCartData();
            }
          }
        }
      } else {
        this.removeItemFromCart(id);
      }
    } catch (error) {
      this.setState({ isLoading1: false });
    }
  };
  render() {
    let cartItem = getValue(
      this.props,
      "cart.cart.myCart.cart.items",
      []
    ).filter((item) => {
      if (item.item) {
        if (getValue(this.props, "product._id", "") === item.item.id) {
          return item;
        }
      }
    });
    // var converter = new showdown.Converter();
    // let description = converter.makeHtml(
    //   getValue(this.props, "product.reward.description")
    // );
    // let productescription = converter.makeHtml(
    //   getValue(this.props, "product.product.description")
    // );
    return (
      <>
        <Container fluid={true} className="d-none d-md-block">
          <div className="campaign-product campaign-product11">
            <ul className="campaign-multiple">
              {getValue(this.props, "product.product.images", []).map(
                (image) => {
                  return (
                    <li>
                      <Button
                        color="nothing"
                        onClick={() => this.replaceImageProduct(image.url)}
                        style={{
                          backgroundImage: `url(${API_URL}${image.url})`,
                        }}
                      ></Button>
                    </li>
                  );
                }
              )}
            </ul>
            {/* <ul className="campaign-multiple1">
              {getValue(this.props, "product.reward.images", []).map(
                (image) => {
                  return (
                    <li>
                      <Button
                        color="nothing"
                        onClick={() => this.replaceImage(image.url)}
                        style={{
                          backgroundImage: `url(${API_URL}${image.url})`,
                        }}
                      ></Button>
                    </li>
                  );
                }
              )}
            </ul> */}
            <div className="product-card-full">
              <div className="product-actions">
                {getValue(this.props, "product.tags[0].title", "") ? (
                  <h3>{getValue(this.props, "product.tags[0].title", "")}</h3>
                ) : getValue(this.props, "product.status", "") !==
                  "sold_out" ? (
                  <h3>
                    <span className="available-dot"></span> Available
                  </h3>
                ) : (
                  <h3 className="not-av">
                    <span className="not-available-dot"></span> Not Available
                  </h3>
                )}
                <p>
                  {getValue(localStorage, "jwt.length", 0) !== 0 ? (
                    <Button
                      color="nothing"
                      onClick={() => {
                        this.favActive(getValue(this.props, "product._id", ""));
                      }}
                      className={
                        this.state.ids.includes(
                          getValue(this.props, "product._id")
                        )
                          ? "active"
                          : ""
                      }
                    >
                      <FavSVG />
                    </Button>
                  ) : (
                    <Button color="nothing">
                      <div
                        onClick={() =>
                          this.handleWishList(
                            getValue(this.props, "product._id", "")
                          )
                        }
                      >
                        <FavSVG />
                      </div>
                    </Button>
                  )}
                </p>
              </div>
              <div className="number-circle">
                <div className="number-svg">
                  <svg
                    width="160"
                    height="160"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <defs>
                        <linearGradient
                          id="linear"
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="0%"
                        >
                          <stop offset="5.64%" stopColor="#cc0033" />
                          <stop offset="26.64%" stopColor="#fc3" />
                          <stop offset="50.64%" stopColor="#fc3" />
                          <stop offset="77.66%" stopColor="#2DC565" />
                        </linearGradient>
                      </defs>
                      <circle
                        strokeLinecap="round"
                        strokeDasharray="440"
                        // change this bottom value - min(0%) => 440 and max(100%) => 0
                        strokeDashoffset={
                          440 -
                          (Math.floor(
                            (getValue(this.props, "product.sold_units", 0) /
                              getValue(this.props, "product.total_units", 0)) *
                              100
                          ) /
                            100) *
                            440
                        }
                        stroke={
                          ((parseInt(
                            getValue(this.props, "product.sold_units", 1)
                          ) /
                            parseInt(
                              getValue(this.props, "product.total_units", 1)
                            )) *
                            10000) /
                            100 <
                          34
                            ? "#2DC565"
                            : ((parseInt(
                                getValue(this.props, "product.sold_units", 1)
                              ) /
                                parseInt(
                                  getValue(this.props, "product.total_units", 1)
                                )) *
                                10000) /
                                100 <
                              66
                            ? "#fc3"
                            : ((parseInt(
                                getValue(this.props, "product.sold_units", 1)
                              ) /
                                parseInt(
                                  getValue(this.props, "product.total_units", 1)
                                )) *
                                10000) /
                                100 <
                              100
                            ? "#cc0033"
                            : ""
                        }
                        className="circle_animation"
                        r="70"
                        cy="80"
                        cx="80"
                        strokeWidth="10"
                        fill="none"
                      />
                    </g>
                  </svg>
                </div>
                <div className="number-text">
                  {((parseInt(getValue(this.props, "product.sold_units", 1)) /
                    parseInt(getValue(this.props, "product.total_units", 1))) *
                    10000) /
                    100 <
                  100 ? (
                    <>
                      <h2 className="home-heading">
                        {Math.floor(
                          ((parseInt(
                            getValue(this.props, "product.sold_units", 1)
                          ) /
                            parseInt(
                              getValue(this.props, "product.total_units", 1)
                            )) *
                            10000) /
                            100
                        )}
                        %
                      </h2>
                      <h3>SOLD</h3>
                    </>
                  ) : (
                    <h3 className="sould-out">SOLD OUT</h3>
                  )}
                </div>
              </div>

              <div className={"product-box "}>
                <Row>
                  <Col
                    md="4"
                    className={
                      getValue(this.props, "product.status", "") !== "sold_out"
                        ? ""
                        : "opacity05"
                    }
                  >
                    <div className="product-img">
                      <img
                        className="img-fluid"
                        src={
                          this.state.url2value === ""
                            ? API_URL +
                              getValue(
                                this.props,
                                "product.product.images[0].url",
                                ""
                              )
                            : API_URL + this.state.url2value
                        }
                        alt=""
                      />
                    </div>
                    <h4>Buy {getValue(this.props, "product.product.title")}</h4>
                    <h3>
                      ₹ {getValue(this.props, "product.product.price", "")}
                    </h3>
                  </Col>
                  <Col md="4">
                    <div className="product-center">
                      <h5
                        className={
                          getValue(this.props, "product.status", "") !==
                          "sold_out"
                            ? ""
                            : "opacity05"
                        }
                      >
                        <b>{getValue(this.props, "product.sold_units", 0)}</b>{" "}
                        <span style={{ fontWeight: "400" }}>SOLD OUT OF</span>{" "}
                        <b>{getValue(this.props, "product.total_units", 0)}</b>
                      </h5>
                      {!getValue(this.state, "productIds", []).includes(
                        getValue(this.props, "product._id", "")
                      ) ? (
                        <Button
                          color="red"
                          onClick={() => {
                            this.addToCart(
                              getValue(this.props, "product._id", "")
                            );
                          }}
                          className={
                            getValue(this.props, "product.status", "") !==
                            "sold_out"
                              ? ""
                              : "d-none"
                          }
                        >
                          {getValue(this.props, "product.status", "") !==
                          "sold_out"
                            ? "ADD TO CART"
                            : "Sold Out"}
                        </Button>
                      ) : (
                        <>
                          {!(getValue(cartItem[0], "quantity", 0) === 0) ? (
                            <>
                              <div className="btn btn-red btn-plus-minus">
                                <Button
                                  color="nothing"
                                  className="right-plus"
                                  onClick={() => {
                                    this.updateCartQuantity(
                                      getValue(cartItem[0], "id", ""),
                                      parseInt(
                                        getValue(cartItem[0], "quantity", 0) + 1
                                      )
                                    );
                                  }}
                                >
                                  {!this.state.isLoading ? (
                                    <img src="/images/plus.svg" alt="plus" />
                                  ) : (
                                    "..."
                                  )}{" "}
                                </Button>
                                <span className="mid-numb">
                                  {getValue(cartItem[0], "quantity", 0)}
                                </span>
                                <Button
                                  color="nothing"
                                  className="left-minus"
                                  onClick={() => {
                                    this.updateCartQuantityDec(
                                      getValue(cartItem[0], "id", ""),
                                      parseInt(
                                        getValue(cartItem[0], "quantity", 0) - 1
                                      )
                                    );
                                  }}
                                >
                                  {!this.state.isLoading1 ? (
                                    <img src="/images/minus.svg" alt="minus" />
                                  ) : (
                                    "..."
                                  )}{" "}
                                </Button>
                              </div>
                            </>
                          ) : (
                            <Button
                              color="red"
                              onClick={() => {
                                this.addToCart(
                                  getValue(this.props, "product._id", "")
                                );
                              }}
                              className={
                                getValue(this.props, "product.status", "") !==
                                "sold_out"
                                  ? ""
                                  : "d-none"
                              }
                            >
                              {getValue(this.props, "product.status", "") !==
                              "sold_out"
                                ? "ADD TO CART"
                                : "Sold Out"}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    {getValue(this.props, "product.status", "") !==
                    "sold_out" ? (
                      <>
                        <p className="mt-2">
                          Results announced on{" "}
                          <b>
                            {dayjs(
                              getValue(this.props, "product.end_date", "")
                            ).format("MMM DD YYYY")}
                          </b>{" "}
                          or when the campaign is sold out. Whichever is earlier
                        </p>
                      </>
                    ) : (
                      <>
                        <div className="sold-out-div">
                          <h5>Draw Date</h5>
                          <h4>
                            {
                              <b>
                                {getValue(this.props, "product.draw_date", "")
                                  ? dayjs(
                                      getValue(
                                        this.props,
                                        "product.draw_date",
                                        ""
                                      )
                                    ).format("MMM DD YYYY")
                                  : "Will be announced"}
                              </b>
                            }
                          </h4>
                        </div>
                      </>
                    )}
                  </Col>

                  <Col
                    md="4"
                    className={
                      getValue(this.props, "product.status", "") !== "sold_out"
                        ? ""
                        : "opacity05"
                    }
                  >
                    <div className="product-img">
                      <img
                        className="img-fluid"
                        src={
                          this.state.url1value === ""
                            ? API_URL +
                              getValue(
                                this.props,
                                "product.reward.images[0].url",
                                ""
                              )
                            : API_URL + this.state.url1value
                        }
                        alt=""
                      />
                    </div>
                    <h4>Get a chance to WIN</h4>
                    <h3>{getValue(this.props, "product.reward.title")}</h3>
                  </Col>
                </Row>
              </div>
            </div>
            <ul className="campaign-multiple1">
              {getValue(this.props, "product.reward.images", []).map(
                (image) => {
                  return (
                    <li>
                      <Button
                        color="nothing"
                        onClick={() => this.replaceImage(image.url)}
                        style={{
                          backgroundImage: `url(${API_URL}${image.url})`,
                        }}
                      ></Button>
                    </li>
                  );
                }
              )}
            </ul>
          </div>
          <Row className="prod-deta">
            <Col md="6" className="border-right pr-5">
              <h3>Prize Details</h3>
              <p
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{
                  __html: getValue(this.props, "product.reward.description"),
                }}
              />
            </Col>
            <Col md="6" className="pl-5">
              <h3>Product Details</h3>
              <p
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{
                  __html: getValue(this.props, "product.product.description"),
                }}
              />
            </Col>
          </Row>
        </Container>
        <div className="campaign-product-mob d-md-none">
          <div className="bar-div">
            <span className="bar-cont">
              <span
                className="bar-perc"
                style={{
                  width:
                    Math.floor(
                      (getValue(this.props, "product.sold_units", 0) /
                        getValue(this.props, "product.total_units", 0)) *
                        100
                    ) + "%",
                }}
              ></span>
            </span>
            <h5>
              <b>{getValue(this.props, "product.sold_units", 0)}</b> sold out of{" "}
              <b>{getValue(this.props, "product.total_units", 0)}</b>
            </h5>
          </div>
          <div className="clearfix"></div>
          <div
            className={
              "img-prod-2 mb-4" +
              (getValue(this.props, "product.status", "") !== "sold_out"
                ? ""
                : "opacity05")
            }
          >
            {/* responsive image */}
            <img
              className="gift-img1"
              src={
                this.state.url1value === ""
                  ? API_URL + getValue(this.props, "product.image.url", "")
                  : API_URL + this.state.url1value
              }
              alt=""
            />
            {/* <img
              className="prod-img1"
              alt=""
              src={
                API_URL +
                getValue(this.props, "product.product.images[0].url", "")
              }
            /> */}
          </div>
          <h6
            className={
              "text-center" +
              (getValue(this.props, "product.status", "") !== "sold_out"
                ? ""
                : "opacity05")
            }
          >
            {getValue(this.props, "product.status", "") !== "sold_out" ? (
              <>
                <p className="mt-2">
                  Results announced on{" "}
                  <b>
                    {dayjs(getValue(this.props, "product.end_date", "")).format(
                      "MMM DD YYYY"
                    )}
                  </b>{" "}
                  or when the campaign is sold out. Whichever is earlier
                </p>
              </>
            ) : (
              <>
                <div className="sold-out-div">
                  <h5>Draw Date</h5>

                  <h4>
                    {
                      <b>
                        {getValue(this.props, "product.draw_date", "")
                          ? dayjs(
                              getValue(this.props, "product.draw_date", "")
                            ).format("MMM DD YYYY")
                          : "Will be announced"}
                      </b>
                    }
                  </h4>
                </div>
              </>
            )}
          </h6>
          <div className="max-div-left">
            {getValue(this.props, "product.status", "") ? (
              <>
                {getValue(this.props, "product.status", "") !==
                "closing_soon" ? (
                  <h3 class={"resp-a-dot"}>
                    {getValue(this.props, "product.status", "") ===
                    "early_access" ? (
                      <img
                        src="/images/erlylogo.svg"
                        className="erlyblink"
                        alt=""
                        width="20"
                        height="20"
                      />
                    ) : (
                      <span className="available-dot"></span>
                    )}{" "}
                    {getValue(this.props, "product.status", "") ===
                    "early_access" ? (
                      <span className="text-black">Early Access</span>
                    ) : getValue(this.props, "product.status", "") ===
                      "closing_soon" ? (
                      "Closing Soon"
                    ) : getValue(this.props, "product.status", "") ===
                      "sold_out" ? (
                      <>Sold Out</>
                    ) : (
                      getValue(this.props, "product.status", "")
                    )}
                  </h3>
                ) : (
                  <h3 class="close-soon">
                    <span class="closing-soon-dot"></span> Closing Soon
                  </h3>
                )}
              </>
            ) : getValue(this.props, "product.status", "") !== "sold_out" ? (
              <h3>
                <span className="available-dot"></span> Available
              </h3>
            ) : (
              <h3 className="not-av">
                <span className="not-available-dot"></span> Not Available
              </h3>
            )}
          </div>
          <p className="action-div">
            {getValue(localStorage, "jwt.length", 0) !== 0 ? (
              <Button
                color="nothing"
                onClick={() => {
                  this.favActive(getValue(this.props, "product._id", ""));
                }}
                className={
                  this.state.ids.includes(getValue(this.props, "product._id"))
                    ? "active"
                    : ""
                }
              >
                <FavSVG />
              </Button>
            ) : (
              <Button color="nothing">
                <div
                  onClick={() =>
                    this.handleWishList(getValue(this.props, "product._id", ""))
                  }
                >
                  <FavSVG />
                </div>
              </Button>
            )}
          </p>
          <div className="clearfix"></div>
          <Row>
            <Col md={{ size: "8", offset: "2" }}>
              <div className="campaign-tabs">
                <ul>
                  <li>
                    <Button
                      color="nothing"
                      onClick={this.toggleTab1}
                      className={this.state.tabActive === 1 ? "active" : ""}
                    >
                      Prize Details
                    </Button>
                  </li>
                  <li>
                    <Button
                      color="nothing"
                      onClick={this.toggleTab2}
                      className={this.state.tabActive === 2 ? "active" : ""}
                    >
                      Product Details
                    </Button>
                  </li>
                </ul>

                {this.state.tabActive === 2 && (
                  <div className="campaign-desc campdes">
                    {/* <p className="mb-0">On purchase of</p> */}
                    <h5>{getValue(this.props, "product.product.title")}</h5>
                    {/* <h3>Product Description</h3> */}
                    <p
                      style={{ whiteSpace: "pre-line" }}
                      dangerouslySetInnerHTML={{
                        __html: getValue(
                          this.props,
                          "product.product.description"
                        ),
                      }}
                    />{" "}
                    {/* children={getValue(this.props, "product.product.description")} /> */}
                    {/* <h3>Product Specification</h3> */}
                    <p>
                      {/* {getValue(this.props, "product.product.specification")} */}
                    </p>
                  </div>
                )}
                {this.state.tabActive === 1 && (
                  <div className="campaign-desc campdes">
                    <h5>{getValue(this.props, "product.reward.title")}</h5>
                    {/* <h3>Reward Details</h3> */}
                    <p>
                      <p
                        style={{ whiteSpace: "pre-line" }}
                        dangerouslySetInnerHTML={{
                          __html: getValue(
                            this.props,
                            "product.reward.description"
                          ),
                        }}
                      />{" "}
                    </p>
                    {/* <h3>Reward Specification</h3> */}
                    <p>
                      {/* {getValue(this.props, "product.reward.specification")} */}
                    </p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
        <div class="mob-camp  d-md-none">
          <div class="d-flex justify-content-between">
            <h4>Buy {getValue(this.props, "product.product.title")}</h4>
            <h4>₹ {getValue(this.props, "product.product.price", "")}</h4>
          </div>
          <div>
            {!getValue(this.state, "productIds", []).includes(
              getValue(this.props, "product._id", "")
            ) ? (
              <Button
                block
                color="red"
                onClick={() => {
                  this.addToCart(getValue(this.props, "product._id", ""));
                }}
                className={
                  getValue(this.props, "product.status", "") !== "sold_out"
                    ? ""
                    : "d-none"
                }
              >
                {getValue(this.props, "product.status", "") !== "sold_out"
                  ? "ADD TO CART"
                  : "Sold Out"}
              </Button>
            ) : (
              <>
                {!(getValue(cartItem[0], "quantity", 0) === 0) ? (
                  <>
                    <div className="btn btn-red btn-plus-minus">
                      <Button
                        color="nothing"
                        className="right-plus"
                        onClick={() => {
                          this.updateCartQuantity(
                            getValue(cartItem[0], "id", ""),
                            parseInt(getValue(cartItem[0], "quantity", 0) + 1)
                          );
                        }}
                      >
                        {!this.state.isLoading ? (
                          <img src="/images/plus.svg" alt="plus" />
                        ) : (
                          "..."
                        )}{" "}
                      </Button>
                      <span className="mid-numb">
                        {getValue(cartItem[0], "quantity", 0)}
                      </span>
                      <Button
                        color="nothing"
                        className="left-minus"
                        onClick={() => {
                          this.updateCartQuantityDec(
                            getValue(cartItem[0], "id", ""),
                            parseInt(getValue(cartItem[0], "quantity", 0) - 1)
                          );
                        }}
                      >
                        {!this.state.isLoading1 ? (
                          <img src="/images/minus.svg" alt="minus" />
                        ) : (
                          "..."
                        )}{" "}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    color="red"
                    onClick={() => {
                      this.addToCart(getValue(this.props, "product._id", ""));
                    }}
                    className={
                      getValue(this.props, "product.status", "") !== "sold_out"
                        ? ""
                        : "d-none"
                    }
                  >
                    {getValue(this.props, "product.status", "") !== "sold_out"
                      ? "ADD TO CART"
                      : "Sold Out"}
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
    cart: cartList(state),
  };
};

export default connect(mapStateToProps)(CampaignProduct);
