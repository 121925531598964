export default `
mutation removeItemFromCart($input:removeItemFromCartInput!) { 
    removeItemFromCart(input:$input){ 
        cart{
            items{
              item{
                title
                product{
                  title
                }
              }
            }
          }
    }
}
`;
