import React from 'react';
class WishlistSVG extends React.Component {
    render() {
        return (
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.87516 2.23338L10.4127 2.78599L10.9503 2.23338C13.4375 -0.323303 17.8933 0.596601 19.4937 3.72207C20.2692 5.23637 20.3958 7.34651 19.0862 9.93017C17.8041 12.4598 15.1468 15.4279 10.4127 18.6227C5.67865 15.4282 3.02143 12.4602 1.73928 9.93071C0.429724 7.34712 0.556337 5.23694 1.33177 3.72255C2.93227 0.596854 7.38803 -0.323246 9.87516 2.23338Z" stroke="#7D7D7D" strokeWidth="1.5" />
            </svg>
        )
    }
}
export default WishlistSVG