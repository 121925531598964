// ******************************************** //
// ***********  Cart Actions  ***************** //
// ******************************************** //

import * as actionTypes from "../_constants/index";
import graphQLRequest from "../../services/graphQlRequest";
import myCart from "../../services/Queries/cart/common/myCart";
import Cookies from "js-cookie";

const cartFetchBegin = () => {
  return {
    type: actionTypes.FETCH_CART_BEGIN,
  };
};

const cartFetchSuccess = (item) => {
  return {
    type: actionTypes.FETCH_CART_SUCCESS,
    payload: item,
  };
};

const cartFetchFailure = (item) => {
  return {
    type: actionTypes.FETCH_CART_FAILURE,
    payload: item,
  };
};

const fetchCartData = () => async (dispatch) => {
  if (Cookies.get("accountCartId")) {
    dispatch(cartFetchBegin());
    try {
      const data = await graphQLRequest(myCart);
      dispatch(cartFetchSuccess(data));
    } catch (error) {
      dispatch(cartFetchFailure(error));
    }
  }
};

const fetchAnonymousCartData = (input) => async (dispatch) => {
  // if(Cookies.get("anonymousCartId") || Cookies.get("accountCartId") || Cookies.get("anonymousCartToken")){
  dispatch(cartFetchBegin());
  try {
    const data = await graphQLRequest(myCart, input);
    dispatch(cartFetchSuccess(data));
  } catch (error) {
    dispatch(cartFetchFailure(error));
    // }
  }
};

export { fetchCartData, fetchAnonymousCartData };

// export const addItemToCart = item => {
//   return {
//     type: actionTypes.ADD_ITEM_TO_CART,
//     payload: item
//   };
// };

// export const removeItemInCart = item => {
//   return {
//     type: actionTypes.REMOVE_ITEM_IN_CART,
//     payload: item
//   };
// };

// export const increaseItemQuantity = item => {
//   return {
//     type: actionTypes.INCREASE_ITEM_QUANTITY,
//     payload: item
//   };
// };

// export const decreseItemQuantity = item => {
//   return {
//     type: actionTypes.DECRESE_ITEM_QUANTITY,
//     payload: item
//   };
// };
