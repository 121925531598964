import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import MapContact from "../../_components/sections/MapContact";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import sitePages from "../../services/Queries/sitePages/sitePages";
import { getValue } from "../../utils/lodash";
// import showdown from "showdown";

class CampaignTC extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      siteInfo: [],
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getInfo();
  }
  getInfo = async () => {
    let resp = await graphQLRequestGlobal(sitePages);
    this.setState({
      siteInfo: resp.sitePages.filter(
        (item) => item.slug === "terms-and-conditions"
      ),
    });
  };
  render() {
    // var converter = new showdown.Converter();
    return (
      <>
        <Container fluid={true}>
          <Row className="faq-page page-policy">
            <Col md={{ size: "8", offset: "2" }}>
              <h2 className="home-heading mt-md-5 mt-3 mb-4">
                MOD – Campaign Draw Terms &amp; Conditions
              </h2>
              {getValue(this.state, "siteInfo", "").map((item) => {
                return (
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{
                      // __html: converter.makeHtml(getValue(item, "content", "")),
                      __html: getValue(item, "content", ""),
                    }}
                  />
                );
              })}
            </Col>
          </Row>
        </Container>
        <div className="d-none d-md-block">
          <MapContact />
        </div>
      </>
    );
  }
}
export default CampaignTC;
