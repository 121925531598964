
export const selectCurrentUser = state => state.auth; //this function is called input Selector because it doesn't use createSelector
export const selectCurrentUserStatus = state => state.user;
// product
export const campaignList = state => state.products;
export const campaignListSpecific = state => state.product;

// categories
export const categoriesList = state => state.categories

// cart
export const cartList = state => state.cart