import * as actionTypes from "../_constants/index";

const initialState = {
  loading: false,
  products: [],
  error: null
};

const productReducer = (currentState = initialState, action) => {
  switch (action.type) {

    // FETCH ITEMS:
    case actionTypes.FETCH_PRODUCTS_BEGIN:
      return {
        ...currentState,
        loading: true
      };
    case actionTypes.FETCH_PRODUCTS_SUCCESS:
      return {
        ...currentState,
        loading: false,
        products: action.payload
      }
    case actionTypes.FETCH_PRODUCTS_FAILURE:
      return {
        ...currentState,
        loading: false,
        error: action.payload
      }

    default:
      return currentState;
  }
};

export default productReducer;