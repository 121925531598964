import React from "react";
import { API_URL } from "../../env";
import { getValue } from "../../utils/lodash";
class WinnerCard extends React.Component {
  render() {
    return (
      <div className="winner-card">
        <h5>Congratulations</h5>
        <h2>{getValue(this.props, "firstname", "")}{" "}{getValue(this.props, "lastname", "")}</h2>
        <h5 className="t-blue">on winning {getValue(this.props, "reward")}</h5>
        <div className="winner-padding">
          <div
            className="profile-img"
            style={{ backgroundImage: `url(${API_URL}${this.props.image})` }}
          ></div>
          <div
            className="gift-img"
            style={{
              backgroundImage: `url(${API_URL}${this.props.campaignImage})`,
            }}
          ></div>
        </div>
      </div>
    );
  }
}
export default WinnerCard;
