import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import { Container } from "reactstrap";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import sitePages from "../../services/Queries/sitePages/sitePages";
import { getValue } from "../../utils/lodash";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
// import showdown from "showdown";

class Charities extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      siteInfo: [],
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getInfo();
  }
  getInfo = async () => {
    let resp = await graphQLRequestGlobal(sitePages);
    this.setState({
      siteInfo: resp.sitePages.filter((item) => item.slug === "charities"),
    });
  };
  render() {
    // var converter = new showdown.Converter();
    return (
      <>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: "10", offset: "1" }}>
              <h2 className="home-heading mt-5 mb-3">Charities We Support</h2>
            </Col>
          </Row>
        </Container>
        <div className="banner-img">
          <img src="/images/charities.png" alt="" className="img-fluid" />
        </div>
        <Container fluid={true}>
          <Row className="charity-page-css">
            <Col md={{ size: "8", offset: "2" }} className="py-5">
              {getValue(this.state, "siteInfo", "").map((item) => {
                return (
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{
                      // __html: converter.makeHtml(getValue(item, "content", "")),
                      __html: getValue(item, "content", ""),
                    }}
                  />
                );
              })}
              <h3>Our charity partners</h3>
              <Row className="resp-mobile-client">
                <Col xs="6" md="3" className="mb-3">
                  <a href="https://makeadiff.in/" target="_blank"><img src="/images/client1.png" alt="" className="img-fluid" /></a>
                </Col>
                <Col xs="6" md="3" className="mb-3">
                  <a href="https://www.bhumi.ngo/" target="_blank"><img src="/images/client2.png" alt="" className="img-fluid" /></a>
                </Col>
                <Col xs="6" md="3" className="mb-3">
                  <a href="https://nanmafoundation.org/" target="_blank"><img src="/images/client3.png" alt="" className="img-fluid" /></a>
                </Col>
                <Col xs="6" md="3" className="mb-3">
                  <a href="https://letsbethechange.in/" target="_blank"><img src="/images/client4.png" alt="" className="img-fluid" /></a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {/* <Footer /> */}
      </>
    );
  }
}
export default Charities;
