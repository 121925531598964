import React from "react";
import { Container, Button, Row, Col } from "reactstrap";
import ProductCardFull from "../cards/ProductCardFull";
import {
  fetchProductsList,
  fetchProductsBasedOnCategory,
} from "../../redux/_actions/productActions";
import { connect } from "react-redux";
import {
  campaignList,
  categoriesList,
  cartList,
} from "../../redux/_selectors/userSelectors";
import { fetchCategoriesList } from "../../redux/_actions/categoryAction";
import { getValue } from "../../utils/lodash";
import myProfile from "../../services/Queries/profile/myProfile";
import graphQLRequest from "../../services/graphQlRequest";
import Cookies from "js-cookie";
import {
  fetchAnonymousCartData,
  fetchCartData,
} from "../../redux/_actions/cartAcition";
import FullLoader from "../loader/full-loader";
import ComponentLoader from "../loader/component-loader";

class HomeCategory extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTab: -1,
      wishListIds: [],
      ids: [],
      productIds: [],
    };
    this.myRef = React.createRef()
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.props.dispatch(fetchProductsList());
    this.props.dispatch(fetchCategoriesList());
    this.getData();
    this.getCart();
  }

  getCart = () => {
    if (Cookies.get("anonymousCartToken") || Cookies.get("anonymousCartId")) {
      let input = {
        anonymous_cart_token: Cookies.get("anonymousCartToken"),
      };
      this.props.dispatch(fetchAnonymousCartData(input));
    } else {
      this.props.dispatch(fetchCartData());
    }
  };

  getData = async () => {
    if (Cookies.get("accountCartId")) {
      let resp = await graphQLRequest(myProfile);
      if (resp) {
        this.setState({
          wishListIds: getValue(resp, "myProfile.wishlist", []),
        });
        this.setState({ ...this.state });
        // this.wishlistIds();
      }
    }
  };

  wishlistIds = async () => {
    if (this.state.wishListIds) {
      this.state.wishListIds.map((item) => this.state.ids.push(item._id));
      this.setState({ ...this.state });
    }
  };

  specificCategory = (id, key) => {
    this.setState({ activeTab: key });
    if (id) {
      this.props.dispatch(fetchProductsBasedOnCategory(id));
    }
  };
  campaignListFun = () => {
    this.setState({ activeTab: -1 });
    this.props.dispatch(fetchProductsList());
  };
  static getDerivedStateFromProps(props, state) {
    props.cart &&
      getValue(props, "cart.cart.myCart.cart.items", []).map((pro) => {
        if(pro.item){
        if (!state.productIds.includes(pro.id)) {
          state.productIds.push(pro.item.id);
        }
        }
      });
  }
  scrollToMyRef = () => { window.scrollTo(0, this.myRef.current.offsetTop) }


  render() {
    let ids = this.state.wishListIds&&this.state.wishListIds.map((item) => item._id);
    return (
      <section className="home-category mt-5">
        {
          !getValue(this.props, "products.loading", false) ? (
            <Container fluid={true}>
              <hr />
              <Row>
                <Col md="6">
                  <h2 className="home-heading" onClick={this.scrollToMyRef}>Live Campaigns</h2>
                </Col>
                <Col md="6">
                  <ul className="home-category-ul">
                    <li>
                      <Button
                        color="nothing"
                        onClick={() => {
                          this.campaignListFun();
                        }}
                        className={this.state.activeTab === -1 ? "active" : ""}
                      >
                        All
                      </Button>
                    </li>
                    {getValue(this.props, "categories.categories", []).map(
                      (category, key) => {
                        return (
                          <>
                            <li>
                              <Button
                                color="nothing"
                                className={
                                  this.state.activeTab === key ? "active" : ""
                                }
                                onClick={() => {
                                  this.specificCategory(category.id, key);
                                }}
                              >
                                {category.title}
                              </Button>
                            </li>
                          </>
                        );
                      }
                    )}
                  </ul>
                </Col>
              </Row>
              {getValue(this.props, "products.products.length", []) !== 0
                ? getValue(this.props, "products.products", []).map(
                  (product) => {
                    // let earlybird = product.tags.filter(
                    //   (item) => item.title === "Closing Soon"
                    // );
                    // if (earlybird.length === 0) {
                      return (
                        <div ref={this.myRef}>
                          <ProductCardFull
                            product={product}
                            ids={ids}
                            productId={this.state.productIds}
                          />
                        </div>
                      );
                    // }
                  }
                )
                : 
                <h4 className="text-center">No Campaigns found</h4>
                }
            </Container>
          ) : (
              <FullLoader />
            )
          // <ComponentLoader />
        }
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: campaignList(state),
    categories: categoriesList(state),
    cart: cartList(state),
  };
};

export default connect(mapStateToProps)(HomeCategory);
