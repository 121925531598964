import graphQLRequest from "../../graphQlRequest.js";
import updateCartBasicInfo from "./updateCartBasicInfo";

export default async function fetchUpdateCartBasicInfo(cart_id,basic_info) {
  // if (!is_donated) return { cart: {} };
  const resp = await graphQLRequest(updateCartBasicInfo, {
    input: {
      cart_id,
      basic_info,
    },
  });

  return resp && resp;
}
