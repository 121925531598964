import graphQLRequest from "../../graphQlRequest.js";
import updateProfile from "./updateEmail";

/**
 * Fetch a profile by its token
 *
 * @param {String} input - The inpu to update
 * @returns {Object} profilw - the fetched profilw
 */ 

export default async function fetchUpdateEmail(email){
    if (!email) return { updateMyProfile: {} };
    const resp = await graphQLRequest(updateProfile, {
        input:{
            email
        }
    });

    return resp && resp;
}