export default `
query myProfile{ 
  myProfile{
        _id
        firstname
        lastname
        gender
        language
        nationality
        country
        phone
        country_code
        user{
          email
          username
        }
        image{
          url
        }
        addresses{
          _id
          address_line_1
          address_line_2
          city
          state
          country
          pincode
        }
        wishlist{
          _id
        }
        wallet_info{
        _id
        points_by_purchase
        points_by_referral
        spent_points
        available_points
        }
      }
  }
`;
