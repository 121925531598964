export default `
mutation updateCartShippingInfo($input:updateCartShippingInfoInput!) { 
    updateCartShippingInfo(input:$input){ 
        cart{
            shipping_info{
              pincode
              country
              state
              city
            }
          }
    }
}
`;
