import removeItemFromCart from "./removeItemFromCart.js";
import graphQLRequest from "../../../graphQlRequest.js";

/**
 *   Delete item by its Id
 *
 * @param {String} Cart_id - The input to update
 * @returns {Object} Cart - the delted Cart
 */

export default async function fetchDeleteItemsCart(cart_id, cart_item_id, anonymous_cart_token) {
    if (!cart_id) return { cart: {} };

    const resp = await graphQLRequest(removeItemFromCart, {
        input: {
            cart_id,
            cart_item_id,
            anonymous_cart_token
        }
    });

    return resp && resp;
}