
// ******************************************** //
// *********** Register page   **************** //
// ******************************************** //


import React, { Component } from 'react';
import { userRegister } from '../../redux/_actions/userAction';
import { connect } from 'react-redux';
import { selectCurrentUser, selectCurrentUserStatus } from '../../redux/_selectors/userSelectors'
import { Col, Container, Row } from 'reactstrap';
import Header from '../../_components/header/header';
import MapContact from '../../_components/sections/MapContact';
import Footer from '../../_components/footer/footer';
import SimpleReactValidator from "simple-react-validator";
import { RequestModel } from '../../_components/common/RequestModel';
import { handleOnChange } from '../../utils/handleOnChange';
import InputField from '../../_components/common/inputField';
import { getValue } from '../../utils/lodash';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import countriesData from "country-telephone-currency-data";
import Flag from "react-world-flags";


class Register extends Component {
    constructor(props, context) {
        super(props, context);
        this.validator = new SimpleReactValidator();
        this.state = {
            request: RequestModel.REGISTER_REQUEST,
            countryCode: '+91',
            confirmPassword: "",
            hide_show: false,
            hide_show_confirm: false
        }
    }
    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/")
        }
        this.setCountryCode()
    }
    setCountryCode = async () => {
        let countriesDatas = countriesData.dialCodeValue.filter(
            (item) => item.value === "+91")
        this.setState({
            countryCode: {
                id: getValue(countriesDatas[0], "id", ""),
                label: (
                    <>
                        <Flag code={getValue(countriesDatas[0], "id", "")} height="16" />{" "}
                        {getValue(countriesDatas[0], "value", "")}
                    </>
                ),
                value: getValue(countriesDatas[0], "value", ""),
            },
        });
    }
    componentDidUpdate() {
        if (this.props.auth.isAuthenticated === true) {
            this.props.history.push("/")
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            if (this.state.request.password === this.state.confirmPassword) {
                let req = {
                    username: this.state.request.firstname.toLowerCase() + this.state.request.lastname.toLowerCase(),
                    firstname: this.state.request.firstname,
                    lastname: this.state.request.lastname,
                    email: this.state.request.email,
                    password: this.state.request.password,
                    phone: this.state.request.phone
                }

                this.props.userRegister(req.username, req.firstname, req.lastname, req.phone, req.email, req.password)
            } else {
                toast.error("Password and Confirm Password should be same")
            }
        } else {
            this.validator.showMessages();
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.forceUpdate();
        }
    }
    handleChange = (e, path) => {
        handleOnChange(e, path, this.state.request)
        this.setState({
            ...this.state
        })
    }
    handlePasswordChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    handleContactDetails = (value) => {
        this.setState({
            countryCode: value,
        });
    };
    render() {
        return (
            <>
                <div className="d-none d-md-block">
                    {/* <Header /> */}
                </div>
                <Container fluid={true}>
                    <div className="login-container register-c">
                        <h3 className="d-none d-md-block">Create Account</h3>
                        <div className="d-md-none mb-3"><NavLink to="/login"><img src="/images/back.svg" alt="" /></NavLink></div>
                        <h3 className="d-md-none mb-2">Create Account</h3>
                        {/* <form onSubmit={this.handleSubmit}> */}
                        <Row className="custom-row">
                            <Col sm="6" className="custom-col">
                                <div className="form-group">
                                    <InputField
                                        inputType="TEXT"
                                        validator={this.validator} placeholder="&nbsp;"
                                        name="firstname"
                                        id="firstname"
                                        value={getValue(this.state, "request.firstname", "")}
                                        label="First Name"
                                        setPath="firstname"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col sm="6" className="custom-col">
                                <div className="form-group">
                                    <InputField
                                        inputType="TEXT"
                                        validator={this.validator} placeholder="&nbsp;"
                                        name="lastname"
                                        id="lastname"
                                        value={getValue(this.state, "request.lastname", "")}
                                        label="Last Name"
                                        setPath="lastname"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col sm="6" className="custom-col">
                                <div className="form-group">
                                    <InputField
                                        inputType="EMAIL"
                                        validator={this.validator} placeholder="&nbsp;"
                                        name="email"
                                        id="email"
                                        value={getValue(this.state, "request.email", "")}
                                        label="Email"
                                        setPath="email"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col md="2" className="custom-col">
                                <InputField
                                    inputType="INPUT_REACT_SELECT_COUNTYCODE"
                                    label="Country code"
                                    placeholder="&nbsp;"
                                    validator={this.validator}
                                    onChange={(e) => this.handleContactDetails(e)}
                                    id="countryCode"
                                    name="countryCode"
                                    value={getValue(this.state, "countryCode", "")}
                                />
                            </Col>
                            <Col sm="4" className="custom-col">
                                <div className="form-group">
                                    <InputField
                                        inputType="NUMBER"
                                        validator={this.validator} placeholder="&nbsp;"
                                        name="phone"
                                        id="phone"
                                        value={getValue(this.state, "request.phone", "")}
                                        label="Phone Number"
                                        setPath="phone"
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </Col>
                            <Col sm="6" className="custom-col">
                                <div className="form-group form-show-hide">
                                    <InputField
                                        inputType="PASSWORD"
                                        validator={this.validator} placeholder="&nbsp;"
                                        name="password"
                                        hide_show={this.state.hide_show}
                                        id="password"
                                        value={getValue(this.state, "request.password", "")}
                                        label="Password"
                                        setPath="password"
                                        onChange={this.handleChange}
                                    />
                                    <button className="form-show-hide-button" onClick={() => { this.setState({ hide_show: !this.state.hide_show }) }}>
                                        {this.state.hide_show ?
                                            <img src="/images/eye-hide.svg" alt="" className="eye-hide" />
                                            :
                                            <img src="/images/eye-show.svg" alt="" className="eye-show" />
                                        }
                                    </button>
                                </div>
                            </Col>
                            <Col sm="6" className="custom-col">
                                <div className="form-group form-show-hide">
                                    <InputField
                                        inputType="PASSWORD"
                                        validator={this.validator}
                                        placeholder="&nbsp;"
                                        hide_show={this.state.hide_show_confirm}
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        value={getValue(this.state, "confirmPassword", "")}
                                        label="Confirm Password"
                                        setPath="confirmPassword"
                                        onChange={this.handlePasswordChange}
                                    />
                                    <button className="form-show-hide-button" onClick={() => { this.setState({ hide_show_confirm: !this.state.hide_show_confirm }) }}>
                                        {this.state.hide_show_confirm ?
                                            <img src="/images/eye-hide.svg" alt="" className="eye-hide" />
                                            :
                                            <img src="/images/eye-show.svg" alt="" className="eye-show" />
                                        }
                                    </button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <div className="form-group mt-4 text-center">
                                    <input className="btn btn-red"
                                        type="submit"
                                        onClick={this.handleSubmit}
                                        value={this.props.user.loading === false ? "Create Account" : "loading.."}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {/* </form> */}
                        <Row>
                            <Col md="12" className="d-md-none mt-3">
                                <p className="text-center">By creating a new account, you are agreeing to our <NavLink to="/privacy-policy" className="text-blue">privacy policy</NavLink> and <NavLink to="/campaign-tc" className="text-blue">terms and conditions</NavLink></p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="d-none d-md-block">
                    <MapContact />
                    {/* <Footer /> */}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userRegister: (username, firstname, lastname, phone, email, password) => dispatch(userRegister(username, firstname, lastname, phone, email, password)),
    };
};

const mapStateToProps = (state) => {
    return {
        auth: selectCurrentUser(state),
        user: selectCurrentUserStatus(state)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
