// ******************************************** //
// ***********  Cart Page   ******************* //
// ******************************************** //

import React from "react";
import { Col, Container, Row } from "reactstrap";
import CartLeft from "../../_components/cards/CartLeft";
import CartRight from "../../_components/cards/CartRight";
import Footer from "../../_components/footer/footer";
import Header from "../../_components/header/header";
import { getValue, setValue } from "../../utils/lodash";
import Cookies from "js-cookie";
import fetchRemoveItemsCart from "../../services/Queries/cart/common/fetchUpdateItemCart";
import fetchDeleteItemsCart from "../../services/Queries/cart/common/fetchRemoveItemToCart";
import fetchReconcileCart from "../../services/Queries/cart/AccountCart/fetchReconcileCart";
import {
  selectCurrentUser,
  selectCurrentUserStatus,
  cartList,
} from "../../redux/_selectors/userSelectors";
import { connect } from "react-redux";
import {
  fetchCartData,
  fetchAnonymousCartData,
} from "../../redux/_actions/cartAcition";
import fetchUpdateCartDonation from "../../services/Queries/cart/fetchUpdateCartDonation";
import Button from "reactstrap/lib/Button";
import { NavLink } from "react-router-dom";
import myProfile from "../../services/Queries/profile/myProfile";
import graphQLRequest from "../../services/graphQlRequest";
import fetchUpdateCartWalletDiscount from "../../services/Queries/cart/fetchUpdateCartWalletDiscount";
import { toast } from "react-toastify";
import fetchAddToWishlist from "../../services/Queries/wishlist/fetchAddToWishlist";

class Cart extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      subTotalPrice: "",
      totalPrice: "",
      couponsLength: 0,
      status: false,
      walletInfo: {},
      walletInput: "",
      toggleinput: true,
      profileInfo: [],
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getCartData();
    this.fetchReconcileApi();
    if (Cookies.get("accountCartId")) {
      this.getWalletInfo();
    }
    if (
      (Cookies.get("isActive") === "true" && Cookies.get("accountCartId")) ||
      (!getValue(localStorage, "jwt") &&
        Cookies.get("anonymousCartId") &&
        Cookies.get("isActive") === "true")
    ) {
      this.updateCoupans(true);
    }
  }

  getWalletInfo = async () => {
    let resp = await graphQLRequest(myProfile);
    if (resp) {
      this.setState({
        walletInfo: getValue(resp, "myProfile.wallet_info", {}),
        profileInfo: getValue(resp, "myProfile", []),
      });
    }
  };
  componentDidUpdate() {
    if (!Cookies.get("accountCartId") && this.props.auth.isAuthenticated) {
      Cookies.set(
        "accountCartId",
        getValue(this.props, "cart.cart.myCart.cart.id", "")
      );
    }
  }
  fetchReconcileApi = async () => {
    if (Cookies.get("anonymousCartToken") && getValue(localStorage, "jwt")) {
      let resp = await fetchReconcileCart(Cookies.get("anonymousCartToken"));
      this.props.dispatch(fetchCartData());
      if (resp) {
        Cookies.remove("anonymousCartId");
        Cookies.remove("anonymousCartToken");
        this.updateCoupans(true);
      }
    }
  };
  getCartData = async () => {
    if (!this.props.auth.isAuthenticated && Cookies.get("anonymousCartToken")) {
      let input = {
        anonymous_cart_token: Cookies.get("anonymousCartToken"),
      };
      this.props.dispatch(fetchAnonymousCartData(input));
    } else {
      this.props.dispatch(fetchCartData());
    }
  };
  updateCartQuantity = async (id, quantity) => {
    if (
      Cookies.get("anonymousCartToken") &&
      Cookies.get("anonymousCartId") &&
      !Cookies.get("accountCartId")
    ) {
      if (id && quantity) {
        let resp = await fetchRemoveItemsCart(
          Cookies.get("anonymousCartId"),
          id,
          quantity,
          Cookies.get("anonymousCartToken")
        );
        if (resp) {
          this.getCartData();
        }
      }
    } else {
      if (id && quantity) {
        let resp = await fetchRemoveItemsCart(
          Cookies.get("accountCartId"),
          id,
          quantity
        );
        if (resp) {
          this.getCartData();
        }
      }
    }
  };
  updateCartQuantityDec = async (id, quantity) => {
    if (
      Cookies.get("anonymousCartToken") &&
      Cookies.get("anonymousCartId") &&
      !Cookies.get("accountCartId")
    ) {
      if (id && quantity) {
        let resp = await fetchRemoveItemsCart(
          Cookies.get("anonymousCartId"),
          id,
          quantity,
          Cookies.get("anonymousCartToken")
        );
        if (resp) {
          this.getCartData();
        }
      }
    } else {
      if (id && quantity) {
        let resp = await fetchRemoveItemsCart(
          Cookies.get("accountCartId"),
          id,
          quantity
        );
        if (resp) {
          this.getCartData();
        }
      }
    }
  };

  removeItemFromCart = async (id) => {
    if (typeof window !== "undefined") {
      let item = getValue(this.props, "cart.cart.myCart.cart.items", []).filter(
        (items) => items.id === id
      )[0];
      console.log(window.dataLayer);
      let removeItem = [];
      removeItem.push({
        item_name: item.item.product.title,
        item_id: item.id,
        price: item.price,
        quantity: item.quantity,
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          items: removeItem,
        },
      });
    }
    if (
      Cookies.get("anonymousCartToken") &&
      Cookies.get("anonymousCartId") &&
      !Cookies.get("accountCartId")
    ) {
      if (id) {
        let resp = await fetchDeleteItemsCart(
          Cookies.get("anonymousCartId"),
          id,
          Cookies.get("anonymousCartToken")
        );
        if (resp) {
          this.getCartData();
        }
      }
    } else {
      if (id) {
        let resp = await fetchDeleteItemsCart(Cookies.get("accountCartId"), id);
        if (resp) {
          toast.error("Removed successfully");
          this.getCartData();
        }
      }
    }
  };

  // toggle donate coupons
  updateCoupans = async (value) => {
    this.setState({
      toggleinput: !this.state.toggleinput,
    });
    let cartId = Cookies.get("accountCartId")
      ? Cookies.get("accountCartId")
      : Cookies.get("anonymousCartId");
    let resp = await fetchUpdateCartDonation(
      value,
      cartId,
      Cookies.get("anonymousCartToken")
    );
    if (resp) {
      this.getCartData();
      if (Cookies.get("isActive")) {
        Cookies.remove("isActive");
      }
    }
  };

  updateWallet = async () => {
    const resp = await fetchUpdateCartWalletDiscount(
      Cookies.get("accountCartId"),
      parseFloat(this.state.walletInput)
    );
    if (resp) {
      this.getCartData();
    }
  };
  handleChange = (e) => {
    this.setState({
      walletInput: e.target.value,
    });
  };

  moveToWishList = async (id, removeId) => {
    if (getValue(localStorage, "jwt")) {
      let resp = await fetchAddToWishlist(id);
      if (resp) {
        let response = await fetchDeleteItemsCart(
          Cookies.get("accountCartId"),
          removeId
        );
        if (response) {
          toast.success("Move to wishlist successfully");
          this.getCartData();
        }
      } else {
        // toast.success("Something went wrong");
      }
    }
  };

  handleRedirectToLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    return (
      <>
        {/* <Header productPage={"d-none d-md-block"} /> */}
        <Container fluid={true}>
          <section className="dashboard-page dashboard-page-resp">
            <h2 className="dashboard-heading">Cart</h2>
            <Row>
              <Col md={{ size: "8" }}>
                {getValue(
                  this.props,
                  "cart.cart.myCart.cart.items.length",
                  0
                ) !== 0 ? (
                  getValue(this.props, "cart.cart.myCart.cart.items", []).map(
                    (product, key) => {
                      return (
                        <div key={key}>
                          <CartLeft
                            product={product}
                            updateCartQuantity={this.updateCartQuantity}
                            updateCartQuantityDec={this.updateCartQuantityDec}
                            removeItemFromCart={this.removeItemFromCart}
                            moveToWishList={this.moveToWishList}
                            handleRedirectToLogin={this.handleRedirectToLogin}
                          />
                        </div>
                      );
                    }
                  )
                ) : (
                  <>
                    <div className="cart-empty">
                      <img src="/images/empty-cart.PNG" alt="" width="110" />
                    </div>
                    <h6 className="text-center mt-5">
                      Your currently have no items in your cart, Explore our
                      products <br />
                      categories and get your chances to win amazing luxury
                      prizes
                    </h6>
                    <div className="mt-5 text-center">
                      <NavLink to="/campaigns">
                        <Button color="white-light" className="px-5 py-2">
                          See All Campaigns
                        </Button>
                      </NavLink>
                    </div>
                  </>
                )}
              </Col>
              {getValue(this.props, "cart.cart.myCart.cart.items.length", 0) !==
              0 ? (
                <Col md={{ size: "4" }}>
                  <CartRight
                    toggleinput={this.state.toggleinput}
                    totalPrice={getValue(
                      this.props,
                      "cart.cart.myCart.cart.total_price",
                      0
                    )}
                    subTotalPrice={getValue(
                      this.props,
                      "cart.cart.myCart.cart.subtotal_price",
                      0
                    )}
                    cartItems={getValue(
                      this.props,
                      "cart.cart.myCart.cart.items",
                      []
                    )}
                    cart={getValue(this.props, "cart.cart.myCart.cart", {})}
                    // static
                    updateWallet={this.updateWallet}
                    couponsLength={this.state.couponsLength}
                    status={this.state.status}
                    updateCoupans={this.updateCoupans}
                    walletInfo={this.state.walletInfo}
                    walletInput={this.state.walletInput}
                    handleChange={this.handleChange}
                    profileInfo={this.state.profileInfo}
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
          </section>
        </Container>
        <div className="d-none d-md-block">{/* <Footer /> */}</div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
    user: selectCurrentUserStatus(state),
    cart: cartList(state),
  };
};

export default connect(mapStateToProps)(Cart);
