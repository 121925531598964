import * as actionTypes from "../_constants/index";

const initialState = {
    loading: false,
    categories: [],
    error: null
};

const categoriesReducer = (currentState = initialState, action) => {
    switch (action.type) {

        // FETCH ITEMS:
        case actionTypes.FETCH_CATEGORY_BEGIN:
            return {
                ...currentState,
                loading: true
            };
        case actionTypes.FETCH_CATEGORY_SUCCESS:
            return {
                ...currentState,
                loading: false,
                categories: action.payload
            }
        case actionTypes.FETCH_CATEGORY_FAILURE:
            return {
                ...currentState,
                loading: false,
                error: action.payload
            }
        default:
            return currentState;
    }
};

export default categoriesReducer;