import React from "react";
import { Row, Col, Button, Input, Label } from "reactstrap";
import { getValue } from "../../utils/lodash";
import InputField from "../common/inputField";
import Container from "reactstrap/lib/Container";
import { NavLink } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Cookies from "js-cookie";

class BasicInfoCard extends React.Component {
  render() {
    return (
      <Container fluid={true}>
        <section className="dashboard-page">
          <h2 className="dashboard-heading">Basic Details</h2>
          <Row>
            <Col md={{ size: "9" }}>
              <h4 className="text-red-light">
                <b>Update Basic Info</b>
              </h4>
              <Row>
                <Col md="4">
                  <InputField
                    inputType="TEXT"
                    validator={this.props.validator}
                    name="firstname"
                    id="firstname"
                    value={getValue(this.props.request, "firstname", "")}
                    label="First Name"
                    setPath="firstname"
                    onChange={this.props.handleChange}
                    placeholder="&nbsp;"
                  />
                </Col>
                <Col md="4">
                  <InputField
                    inputType="TEXT"
                    validator={this.props.validator}
                    name="lastname"
                    id="lastname"
                    value={getValue(this.props.request, "lastname", "")}
                    label="Last Name"
                    setPath="lastname"
                    onChange={this.props.handleChange}
                    placeholder="&nbsp;"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <InputField
                    inputType="INPUT_REACT_SELECT_COUNTYCODE"
                    label="Country code"
                    placeholder="&nbsp;"
                    // validator={this.validator}
                    onChange={(e) => this.props.handleContactDetails(e)}
                    id="country_code"
                    name="country_code"
                    value={getValue(this.props, "countryCode", "")}
                  />
                  <div className="form-error">
                    {this.props.validator.message(
                      "Contry Code",
                      getValue(this.props, "countryCode", ""),
                      "required"
                    )}
                  </div>
                </Col>
                <Col md="4">
                  <InputField
                    // className="form-control"
                    inputType="NUMBER"
                    placeholder="&nbsp;"
                    value={getValue(this.props.request, "phone", "")}
                    onChange={this.props.handleChange}
                    name="phone"
                    setPath="phone"
                    label="Mobile Number"
                    validator={this.props.validator}
                  />
                </Col>
                <Col md="4">
                  <InputField
                    inputType="EMAIL"
                    validator={this.props.validator}
                    name="email"
                    id="email"
                    value={getValue(this.props.request, "email", "")}
                    label="Email"
                    setPath="email"
                    onChange={this.props.handleChange}
                    placeholder="&nbsp;"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mt-4">
                  <Button color="red" onClick={this.props.handleSubmit}>
                    Save and Continue
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </Container>
    );
  }
}
export default BasicInfoCard;
