import anonymousCreateCart from "./anonymousCreateCart";
import graphQLRequest from "../../../graphQlRequest";

/**
 * Fetch a Anonymous cart by its token
 *
 * @param {String} input - The input to update
 * @returns {Object} Anonymous cart - the fetched Anonymous cart
 */

export default async function fetchCreateAnonymousCart(data) {
    if (!data) return { anonymous_cart_token: {} };

    const resp = await graphQLRequest(anonymousCreateCart, {
        input: {
            campaign_id: data.campaign_id,
            quantity: data.quantity
        }
    });

    return resp && resp;
}