export default `
mutation placeOrder($input:placeOrderInput!) { 
    placeOrder(input:$input){ 
        appId
        customerEmail
        customerPhone
        customerName
        orderId
        orderCurrency
        orderAmount
        orderNote
        notifyUrl
        returnUrl
        signature
    }
}
`;
