import graphQLRequest from "../../graphQlRequest.js";
import updateCartWalletDiscount from "./updateCartWalletDiscount";

/**
 * Fetch a Add items cart by  ids
 *
 * @param {String} Id's - The input to update
 * @returns {Object} Cart - the fetched Add items to cart
 */

export default async function fetchUpdateCartWalletDiscount(
  cart_id,
  wallet_discount_points
) {
  if (!cart_id) return { cart: {} };
  const resp = await graphQLRequest(updateCartWalletDiscount, {
    input: {
      cart_id,
      wallet_discount_points,
    },
  });

  return resp && resp;
}
