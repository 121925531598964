// ******************************************** //
// ***********    Login page   **************** //
// ******************************************** //

import React, { Component } from "react";
import { userLogin } from "../../redux/_actions/userAction";
import { connect } from "react-redux";
import {
  selectCurrentUser,
  selectCurrentUserStatus,
} from "../../redux/_selectors/userSelectors";
import Header from "../../_components/header/header";
import MapContact from "../../_components/sections/MapContact";
import Footer from "../../_components/footer/footer";
import { Button, Col, Container, Row } from "reactstrap";
import { NavLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import configApi from "../../utils/configApi";
import { RequestModel } from "../../_components/common/RequestModel";
import { handleOnChange } from "../../utils/handleOnChange";
import InputField from "../../_components/common/inputField";
import { getValue } from "../../utils/lodash";
import Cookies from "js-cookie";

class Login extends Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator();
    this.state = {
      request: RequestModel.LOGIN_REQUEST,
      hide_show: false
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  componentDidUpdate() {
    if (this.props.auth.isAuthenticated === true) {
      this.props.history.push("/");
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.userLogin(
        this.state.request.email,
        this.state.request.password
      );
    } else {
      this.validator.showMessages();
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.forceUpdate();
    }
  };

  // google login
  googleLogin = (provider) => {
    Cookies.set("google", "google");
    Cookies.remove("facebook");
    const link = configApi.authenticateStrapiWithProvider(provider);
    window.location.href = link;
  };

  // facebook login
  facebookLogin = (provider) => {
    Cookies.set("facebook", "facebook");
    Cookies.remove("google");
    const link = configApi.authenticateStrapiWithProvider(provider);
    window.location.href = link;
  };

  handleChange = (e, path) => {
    handleOnChange(e, path, this.state.request);
    this.setState({
      ...this.state,
    });
  };
  render() {
    return (
      <>
        <Container fluid={true}>
          <div className="login-container">
            <h3 className="d-none d-md-block text-center mb-3"><img className="img-fluid" src="/images/logo-mob.PNG" alt="" /></h3>
            <div className="d-md-none mb-4 text-center">
              <img src="images/logo-mob.PNG" alt="" className="img-fluid" />
            </div>
            {/* <form onSubmit={this.handleSubmit}> */}
            <Row>
              <Col sm="12">
                <div className="form-group form-icon">
                  <img src="/images/username.svg" alt="" />
                  <InputField
                    inputType="EMAIL"
                    validator={this.validator}
                    name="email"
                    placeholder="&nbsp;"
                    id="email"
                    value={getValue(this.state, "request.email", "")}
                    label="Email"
                    setPath="email"
                    onChange={this.handleChange}
                  />
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group form-icon form-show-hide">
                  <img src="/images/password.svg" alt="" />
                  <InputField
                    inputType="PASSWORD"
                    hide_show={this.state.hide_show}
                    validator={this.validator}
                    placeholder="&nbsp;"
                    name="password"
                    id="password"
                    value={getValue(this.state, "request.password", "")}
                    label="Password"
                    setPath="password"
                    onChange={this.handleChange}
                  />
                  <button className="form-show-hide-button" onClick={() => { this.setState({ hide_show: !this.state.hide_show }) }}>
                    {this.state.hide_show ?
                      <img src="/images/eye-hide.svg" alt="" className="eye-hide" />
                      :
                      <img src="/images/eye-show.svg" alt="" className="eye-show" />
                    }
                  </button>
                </div>
              </Col>
              <Col sm="12" className="mb-2">
                <Row>
                  {/* <Col>
                      <label className="checkbox-container pt-1 pl-4">
                        {" "}
                        Remember Me
                        <input type="checkbox" />
                        <span className="checkmark"></span>
                      </label>
                    </Col> */}
                  <Col className="text-right">
                    <p>
                      <NavLink to="/forgot-password" className="text-blue">
                        Forgot Password?
                        </NavLink>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col sm="12">
                <div className="form-group">
                  <input
                    className="btn btn-red btn-block"
                    type="submit"
                    onClick={this.handleSubmit}
                    value={
                      this.props.user.loading === false
                        ? "Sign In"
                        : "loading.."
                    }
                  />
                </div>
                <div className="form-group">
                  <NavLink to="/register">
                    <input
                      className="btn btn-red btn-block"
                      type="button"
                      value="Create Account"
                    />
                  </NavLink>
                </div>
              </Col>
            </Row>
            {/* </form> */}
            <p className="line-text">
              <span>or</span>
            </p>
            <Row>
              <Col md="12">
                <Button
                  onClick={() => {
                    this.googleLogin("google");
                  }}
                  color="border"
                  block
                >
                  <img src="/images/google.svg" alt="" />
                  SIGN in with google
                </Button>
              </Col>

              <Col md="12" className="mt-2">
                <Button
                  onClick={() => {
                    this.facebookLogin("facebook");
                  }}
                  color="border"
                  block
                >
                  <img src="/images/facebook.svg" alt="" />
                  SIGN in with facebook
                </Button>
              </Col>

              <Col md="12" className="d-md mt-3">
                <p className="text-center">
                  <b>Don't want to sign up now?{" "}
                    <NavLink to="/" className="text-blue">
                      SKIP
                  </NavLink> </b>
                </p>
              </Col>
              <Col md="12" className="d-md mt-5">
                <p className="text-center space">
                  <b>By creating a new account, you are agreeing to our{" "} <br />
                    <NavLink to="/privacy-policy" className="text-blue">
                      privacy policy
                  </NavLink>{" "}
                  and{" "}
                    <NavLink to="/terms-condition" className="text-blue">
                      terms and conditions
                  </NavLink></b>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="d-none d-md-block">
          <MapContact />
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    userLogin: (email, password) => dispatch(userLogin(email, password)),
  };
};

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
    user: selectCurrentUserStatus(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
