export default `
query siteFaq{ 
    siteFaq{
        _id
        faqs{
          question
          answer
          section
        }
    }
  }
`;
