// ******************************************** //
// *********** Basic Info page   **************** //
// ******************************************** //

import React, { Component } from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import BasicInfoCard from "../../_components/cards/BasicInfoCard";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";
import { getValue } from "../../utils/lodash";
import fetchUpdateCartBasicInfo from "../../services/Queries/cart/fetchUpdateCartBasicInfo";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { RequestModel } from "../../_components/common/RequestModel";
import SimpleReactValidator from "simple-react-validator";
import { handleOnChange } from "../../utils/handleOnChange";
import graphQLRequest from "../../services/graphQlRequest";
import myProfile from "../../services/Queries/profile/myProfile";
import myCart from "../../services/Queries/cart/common/myCart";
import countriesData from "country-telephone-currency-data";
import Flag from "react-world-flags";

class BasicInfo extends Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator();
    this.state = {
      request: RequestModel.BASIC_INFO,
      countryCode: {},
      is_donated: true,
      cart: [],
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getBasicInfo();
    this.getCart();
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    }
  }

  // handle change
  handleChange = (e, path) => {
    handleOnChange(e, path, this.state.request);
    this.setState({
      ...this.state,
    });
  };

  getCart = async () => {
    let resp = await graphQLRequest(myCart);
    if (resp) {
      this.setState({
        is_donated: getValue(resp, "myCart.cart.is_donated", false),
        cart: getValue(resp, "myCart.cart.items"),
      });
    }
  };

  getBasicInfo = async () => {
    let resp = await graphQLRequest(myProfile);
    if (resp) {
      let countriesDatas = countriesData.dialCodeValue.filter(
        (item) =>
          item.value ===
          (getValue(resp, "myProfile.country_code", "")
            ? getValue(resp, "myProfile.country_code", "")
            : "+91")
      );
      this.setState({
        countryCode: {
          id: getValue(countriesDatas[0], "id", ""),
          label: (
            <>
              <Flag code={getValue(countriesDatas[0], "id", "")} height="16" />{" "}
              {getValue(countriesDatas[0], "value", "")}
            </>
          ),
          value: getValue(countriesDatas[0], "value", ""),
        },
      });
      this.setState((prevState) => ({
        ...prevState,
        request: {
          ...prevState.request,
          firstname: getValue(resp, "myProfile.firstname", ""),
          lastname: getValue(resp, "myProfile.lastname", ""),
          email: getValue(resp, "myProfile.user.email", ""),
          phone: getValue(resp, "myProfile.phone", ""),
        },
      }));
      this.setState({ ...this.state });
    }
  };
  componentDidUpdate() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
    }
    // if (this.state.request.firstname && !this.state.is_donated) {
    //   Cookies.set("basicInfo", true);
    //   this.props.history.push("/shipping");
    // }
  }

  // handle submit
  handleSubmit = async (e) => {
    e.preventDefault();
    let req = {
      firstname: this.state.request.firstname,
      lastname: this.state.request.lastname,
      email: this.state.request.email,
      phone: this.state.request.phone,
      country_code: this.state.countryCode.value,
    };
    // validation
    if (this.validator.allValid()) {
      let resp = await fetchUpdateCartBasicInfo(
        Cookies.get("accountCartId"),
        req
      );
      if (resp) {
        toast.success("Updated successfully");
        {
          setTimeout(() => {
            this.props.history.replace("/shipping");
          }, 1000);
          // getValue(this.state, "is_donated") &&
          // getValue(this.state, "is_donated")
          //   ? setTimeout(() => {
          //       this.props.history.replace("/payment");
          //     }, 1000)
          //   : setTimeout(() => {
          //       this.props.history.replace("/shipping");
          //     }, 1000);
        }
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  handleNumberChange = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      request: {
        ...prevState.request,
        phone: value,
      },
    }));
  };
  handleContactDetails = (value) => {
    this.setState({
      countryCode: value,
    });
  };

  render() {
    return (
      <div>
        {/* <Header /> */}
        <BasicInfoCard
          handleSubmit={this.handleSubmit}
          validator={this.validator}
          handleChange={this.handleChange}
          request={this.state.request}
          handleNumberChange={this.handleNumberChange}
          is_donated={this.state.is_donated}
          handleContactDetails={this.handleContactDetails}
          countryCode={this.state.countryCode}
        />
        {/* <Footer /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};

export default connect(mapStateToProps)(BasicInfo);
