// ******************************************** //
// ***********    Header page  **************** //
// ******************************************** //

import React from "react";
import { NavLink } from "react-router-dom";
import CartSVG from "../svg/cart-svg";
import { Button, Container } from "reactstrap";
import UserSVG from "../svg/user-svg";
import { connect } from "react-redux";
import {
  selectCurrentUser,
  cartList,
} from "../../redux/_selectors/userSelectors";
import { userLogout } from "../../redux/_actions/userAction";
import LogoutSVG from "../svg/logout-svg";
import { getValue } from "../../utils/lodash";
import Cookies from "js-cookie";
import {
  fetchCartData,
  fetchAnonymousCartData,
} from "../../redux/_actions/cartAcition";
import { withRouter } from "react-router";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      headerScrolled: false,
      cartQuantity: 0,
    };
    this.headerScroll = this.headerScroll.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === true) {
    }
    this.getMyCart();
    window.addEventListener("scroll", this.headerScroll);
  }

  getMyCart = async () => {
    if (Cookies.get("anonymousCartToken") && !this.props.auth.isAuthenticated) {
      let input = {
        anonymous_cart_token: Cookies.get("anonymousCartToken"),
      };
      this.props.dispatch(fetchAnonymousCartData(input));
    } else {
      this.props.dispatch(fetchCartData());
    }
  };

  componentDidUpdate() {
    if (Cookies.get("anonymousCartToken") && !Cookies.get("anonymousCartId")) {
      Cookies.set(
        "anonymousCartId",
        getValue(this.props, "cart.cart.myCart.cart.id", "")
      );
    }
  }

  headerScroll = () => {
    if (window.scrollY > 1) {
      this.setState({
        headerScrolled: true,
      });
    }
    if (window.scrollY === 0) {
      this.setState({
        headerScrolled: false,
      });
    }
  };

  onLogout = (e) => {
    e.preventDefault();
    this.props.dispatch(userLogout());
  };
  render() {
    const { productPage } = this.props;
    let count = getValue(this.props, "cart.cart.myCart.cart.items", []).map(
      (item) => item.quantity
    );
    let totalCount = count && count.reduce((a, b) => a + b, 0);
    return (
      <>
        <header
          className={
            productPage + (this.state.headerScrolled ? " is-fixed" : "")
          }
        >
          <Container fluid={true}>
            <div className="header-left">
              <div className="logo">
                <NavLink to="/">
                  <img src="/images/logo.svg" className="logo-desk" alt="" />
                  <img
                    src="/images/logo-resp.svg"
                    className="logo-resp"
                    alt=""
                  />
                </NavLink>
              </div>
              {/* <ul className="header-links">
                <li>
                  <NavLink to="/campaigns">Products</NavLink>
                </li>
                <li>
                  <NavLink to="/campaigns">Campaigns</NavLink>
                </li>
              </ul> */}
            </div>
            <div className="header-right">
              <ul className="header-links">
                {/* <li className="notification">
                                    <NavLink to="/notification"><NotificationSVG /><span className="notification-dot"></span></NavLink>
                                </li> */}
                {/* <li className="search">
                                    <NavLink to="/search"><SearchSVG /></NavLink>
                                </li> */}
                <li className="cart">
                  <NavLink to="/cart">
                    <CartSVG />
                    <span className="cart-num">
                      {totalCount ? totalCount : 0}
                    </span>
                  </NavLink>
                </li>
                <li className="login">
                  {this.props.auth.isAuthenticated === true ? (
                    <>
                      {this.props.auth.user.email ? (
                        <NavLink to="/personal-details">
                          <UserSVG /> Hi,{" "}
                          {this.props.auth.user.email.split("@")[0]}{" "}
                        </NavLink>
                      ) : this.props.auth.isAuthenticated === true ? (
                        <NavLink to="/personal-details">
                          <UserSVG /> Profile
                        </NavLink>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <NavLink to="/login">
                      <span className="d-none d-md-inline-block">Sign in</span>
                    </NavLink>
                  )}
                </li>
                {/* {this.props.auth.isAuthenticated === true && (
                  <li className="logout d-none d-md-inline-block">
                    <Button color="nothing" onClick={this.onLogout}>
                      <span className="d-md-inline-block">Logout</span>
                    </Button>
                  </li>
                )} */}
              </ul>
            </div>
            <div className="clearfix"></div>
          </Container>
        </header>
        <div className={"header-margin " + productPage}></div>
        <div className="mobile-bottom">
          <ul>
            <li>
              <NavLink to="/">
                {window.location.pathname === "/" ? (
                  <span className="mb-icon">
                    <img
                      src="/images/mb-logo-active.svg"
                      className="active-img"
                      alt=""
                    />
                  </span>
                ) : (
                  <span className="mb-icon">
                    <img
                      src="/images/mb-logo.svg"
                      className="active-img"
                      alt=""
                    />
                  </span>
                )}
                {/* <span className="mb-icon">
                  <img
                    src="/images/mb-logo-active.svg"
                    className="non-active"
                    alt=""
                  />
                  <img
                    src="/images/mb-logo-active.svg"
                    className="active-img"
                    alt=""
                  />
                </span> */}
                <span className="mb-text">Home</span>
              </NavLink>
            </li>
            <li>
              {getValue(localStorage, "jwt") ? (
                <NavLink to="/wishlist">
                  <span className="mb-icon">
                    <img
                      src="/images/mb-wishlist.svg"
                      className="non-active"
                      alt=""
                    />
                    <img
                      src="/images/mb-wishlist-active.svg"
                      className="active-img"
                      alt=""
                    />
                  </span>
                  <span className="mb-text">Wishlist</span>
                </NavLink>
              ) : (
                <NavLink to="/login">
                  <span className="mb-icon">
                    <img
                      src="/images/mb-wishlist.svg"
                      className="non-active"
                      alt=""
                    />
                    <img
                      src="/images/mb-wishlist.svg"
                      className="active-img"
                      alt=""
                    />
                  </span>
                  <span className="mb-text">Wishlist</span>
                </NavLink>
              )}
            </li>
            <li>
              <NavLink
                to={
                  this.props.auth.isAuthenticated === true
                    ? "/personal-details"
                    : "/login"
                }
              >
                <span className="mb-icon">
                  <img
                    src="/images/mb-profile.svg"
                    className="non-active"
                    alt=""
                  />
                  <img
                    src="/images/mb-profile-active.svg"
                    className="active-img"
                    alt=""
                  />
                </span>
                {this.props.auth.isAuthenticated === true ? (
                  <span className="mb-text">Profile</span>
                ) : (
                  <span className="mb-text">Login</span>
                )}
              </NavLink>
            </li>
            <li>
              {getValue(localStorage, "jwt") ? (
                <NavLink to="/coupons">
                  <span className="mb-icon">
                    <img
                      src="/images/mb-coupons.svg"
                      className="non-active"
                      alt=""
                    />
                    <img
                      src="/images/mb-coupons-active.svg"
                      className="active-img"
                      alt=""
                    />
                  </span>
                  <span className="mb-text">Coupons</span>
                </NavLink>
              ) : (
                <NavLink to="/login">
                  <span className="mb-icon">
                    <img
                      src="/images/mb-coupons.svg"
                      className="non-active"
                      alt=""
                    />
                    <img
                      src="/images/mb-coupons.svg"
                      className="active-img"
                      alt=""
                    />
                  </span>
                  <span className="mb-text">Coupons</span>
                </NavLink>
              )}
            </li>
            <li>
              <NavLink to="/cart">
                <span className="mb-icon">
                  <img
                    src="/images/mb-cart.svg"
                    className="non-active"
                    alt=""
                  />
                  <img
                    src="/images/mb-cart-active.svg"
                    className="active-img"
                    alt=""
                  />
                  <span className="cart-count-mobile">
                    {totalCount ? totalCount : 0}
                  </span>
                </span>
                <span className="mb-text">Cart</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
    cart: cartList(state),
  };
};
export default connect(mapStateToProps)(withRouter(Header));
