import graphQLRequest from "../../graphQlRequest.js";
import addToWishList from "./addToWishlist";

/**
 * add a wishlist by its campiagnid
 *
 * @param {String} campiagnid - The campiagnid to update
 * @returns {Object} wishlist - add to wishlist
 */

export default async function fetchAddToWishlist(campaign_id) {
    if (!campaign_id) return { addToWishlist: {} };

    const resp = await graphQLRequest(addToWishList, {
        campaign_id
    });

    return resp && resp;
}