export default `
query siteInfo{ 
  siteInfo{
        website_name,
        facebook_link
        instagram_link
        linkedin_link
        twitter_link
        youtube_link
        phone
        email
        address
        banner_images{
          url
        }
      }
  }
`;

