import graphQLRequest from "../../../graphQlRequest.js";
import reconcileCart from "./reconcileCart.js";

/**
 * Fetch a Reconsile cart by  anonymous_cart_token
 *
 * @param {String} anonymous_cart_token - The input to update
 * @returns {Object} Cart - the fetched ReconsileCart
 */ 

export default async function fetchReconcileCart(anonymous_cart_token){
    if (!anonymous_cart_token) return { reconciled: {} };

    const resp = await graphQLRequest(reconcileCart, {
        input:{
            anonymous_cart_token
        }
    });

    return resp && resp;
}