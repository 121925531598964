export const RequestModel = {
  LOGIN_REQUEST: {
    email: "",
    password: "",
  },
  REGISTER_REQUEST: {
    firstname: "",
    lastname:"",
    phone:"",
    email: "",
    password: "",
  },
  PROFILE_REQUEST: {
    firstname: "",
    lastname: "",
    // gender: "",
    // language: "",
    // nationality: "",
    // country: "",
    phone: "",
    country_code: "",
    // image:""
  },
  SITE_CREATION_REQUEST: {
    name: "",
  },
  BASIC_INFO: {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    country_code: "",
  },
  SHIPPING_ADDRESS: {
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  },
};
