import React from "react";
import {
  Container,
  Button,
  UncontrolledCollapse,
  Card,
  CardBody,
} from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import MapContact from "../../_components/sections/MapContact";
import siteFaqs from "../../services/Queries/faqs/siteFaqs";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import { getValue } from "../../utils/lodash";
import FAQCollapse from "../../_components/cards/faq-collapse";
// import showdown from "showdown";

class FAQ extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      siteFaqs: [],
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getInfo();
  }
  getInfo = async () => {
    let resp = await graphQLRequestGlobal(siteFaqs);
    this.setState({
      siteFaqs: getValue(resp, "siteFaq.faqs", []),
    });
  };
  render() {
    // var converter = new showdown.Converter();

    return (
      <>
        <Container fluid={true} className="resp-fa">
          <Row className="faq-page">
            <Col md={{ size: "8", offset: "2" }}>
              <h2 className="home-heading mt-md-5 mt-3 mb-4">FAQs</h2>
              <h3 className="mb-1 mt-4">General</h3>
              <hr className="mt-1 mb-4" />
              {getValue(this.state, "siteFaqs", "").map((item, i) => {
                if (item.section === "General") {
                  return (
                    <FAQCollapse
                      i={i}
                      dangerouslySetInnerHTML={{
                        __html: getValue(item, "question", ""),
                      }}
                      dangerouslySetInnerHTML2={{
                        __html: getValue(item, "answer", ""),
                      }}
                    />
                  );
                }
              })}
              <h3 className="mb-1 mt-4">Account</h3>
              <hr className="mt-1 mb-4" />
              {getValue(this.state, "siteFaqs", "").map((item, i) => {
                if (item.section === "Account") {
                  return (
                    <FAQCollapse
                      i={i}
                      dangerouslySetInnerHTML={{
                        __html: getValue(item, "question", ""),
                      }}
                      dangerouslySetInnerHTML2={{
                        __html: getValue(item, "answer", ""),
                      }}
                    />
                  );
                }
              })}
              <h3 className="mb-1 mt-4">Campaigns</h3>
              <hr className="mt-1 mb-4" />
              {getValue(this.state, "siteFaqs", "").map((item, i) => {
                if (item.section === "Campaigns") {
                  return (
                    <FAQCollapse
                      i={i}
                      dangerouslySetInnerHTML={{
                        __html: getValue(item, "question", ""),
                      }}
                      dangerouslySetInnerHTML2={{
                        __html: getValue(item, "answer", ""),
                      }}
                    />
                  );
                }
              })}
              <h3 className="mb-1 mt-4">Purchase</h3>
              <hr className="mt-1 mb-4" />
              {getValue(this.state, "siteFaqs", "").map((item, i) => {
                if (item.section === "Purchase") {
                  return (
                    <FAQCollapse
                      i={i}
                      dangerouslySetInnerHTML={{
                        __html: getValue(item, "question", ""),
                      }}
                      dangerouslySetInnerHTML2={{
                        __html: getValue(item, "answer", ""),
                      }}
                    />
                  );
                }
              })}
            </Col>
          </Row>
        </Container>
        <div className="d-none d-md-block">
          <MapContact />
        </div>
      </>
    );
  }
}
export default FAQ;
