import React from "react";
import { Container } from "reactstrap";
import Slider from "react-slick";
import WinnerCard from "../cards/WinnerCard";
import { getValue } from "../../utils/lodash";
class HomeWinners extends React.Component {
  render() {
    var settings = {
      arrows: true,
      dots: false,
      speed: 800,
      slidesToShow: 1,
      variableWidth: true,
      slidesToScroll: 1,
      centerMode: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: true,
            dots: false,
            speed: 800,
            slidesToShow: 1,
            variableWidth: false,
            slidesToScroll: 1,
            centerMode: false,
          },
        },
      ],
    };
    return (
      <section className="home-winners">
        <Container fluid={true}>
          <h2 className="home-heading mb-4">Recent Winners</h2>
        </Container>
        <Slider {...settings} className="home-winners-slider">
          {getValue(this.props, "winners", []).map((item) => {
            return (
              <div className="home-winner-padding">
                <WinnerCard
                  image={getValue(item, "image.url", "")}
                  firstname={getValue(item, "firstname")}
                  lastname={getValue(item, "lastname")}
                  reward={getValue(item, "campaign.reward.title", "")}
                  campaignImage={getValue(
                    item,
                    "campaign.reward.images[0].url",
                    ""
                  )}
                />
              </div>
            );
          })}

          {/* <div className="home-winner-padding">
                        <WinnerCard />
                    </div>
                    <div className="home-winner-padding">
                        <WinnerCard />
                    </div>
                    <div className="home-winner-padding">
                        <WinnerCard />
                    </div> */}
        </Slider>
      </section>
    );
  }
}
export default HomeWinners;
