import React from "react";
import ContactUsSection from "../../_components/sections/ContctUs";
import SimpleReactValidator from "simple-react-validator";
import { handleOnChange } from "../../utils/handleOnChange";
import { getValue, setValue } from "../../utils/lodash";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import createEnquiry from "../../services/Queries/Enquiry/createEnquery";
import { toast } from "react-toastify";
import { categoriesList } from "../../redux/_selectors/userSelectors";
import { connect } from "react-redux";
import { fetchCategoriesList } from "../../redux/_actions/categoryAction";

class ContactUs extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator();
    this.state = {
      categoriesList:[{value:"General",name:"General"},{value:"Campaigns",name:"Campaigns"},{value:"Technical",name:"Technical"}],
      request: {
        fullname: "",
        email: "",
        phone: "",
        category: "",
        message: "",
      },
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.props.dispatch(fetchCategoriesList());
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let request={
        input:{
        data:this.state.request}
      }
      let resp = await graphQLRequestGlobal(createEnquiry, request);
      if (resp) {
        toast.success("Submitted successfully");
      }
    } else {
      this.validator.showMessages();
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.forceUpdate();
    }
  };

  handleChange = (e, path) => {
    setValue(this.state, `request[${e.target.name}]`, e.target.value);
    this.setState({ ...this.state });
  };

  render() {
    // let categoriesLists = [];
    // getValue(this.props, "categories.categories", []).map((item) => {
    //   categoriesLists.push({
    //     value: item.title,
    //     name: item.title,
    //   });
    // });
    return (
      <div className="contact-form">
        <ContactUsSection
          {...this.state}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          validator={this.validator}
          categoriesList={this.state.categoriesList}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    categories: categoriesList(state),
  };
};

export default connect(mapStateToProps)(ContactUs);
