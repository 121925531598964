
// ******************************************** //
// ***********  Product Actions *************** //
// ******************************************** //


import * as actionTypes from "../_constants/index";
import campaignsQuery from "../../services/Queries/campaigns/campaigns";
import campaignsCategoriesQuery from "../../services/Queries/campaigns/campaignWithCategory";
import graphQLRequest from "../../services/graphQlRequest";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";

// ACTION TYPES
const fetchProductsegin = () => {
  return {
    type: actionTypes.FETCH_PRODUCTS_BEGIN
  };
};
const fetchProductsSuccess = (products) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    payload: products
  };
};
const fetchProductsFailure = (products) => {
  return {
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    payload: products
  };
};



// ACTION CREATERS
const fetchProductsList = () => async dispatch => {
  dispatch(fetchProductsegin());
  try {
    let sort={
      sort:"status:asc"
    }
    const data = await graphQLRequestGlobal(campaignsQuery,sort);
    dispatch(fetchProductsSuccess(data.campaigns));
  } catch (error) {
    dispatch(fetchProductsFailure(error));
  }
};

const fetchProductsBasedOnCategory = (id) => async dispatch => {
  dispatch(fetchProductsegin());
  try {
    let categoryId = {
      id: id,
      sort:"status:asc"
    }
    const data = await graphQLRequestGlobal(campaignsCategoriesQuery, categoryId);
    dispatch(fetchProductsSuccess(data.campaigns));
  } catch (error) {
    dispatch(fetchProductsFailure(error));
  }
};


export {
  fetchProductsList,
  fetchProductsBasedOnCategory
};
