
// ******************************************** //
// *********** Campaign page   **************** //
// ******************************************** //


import React from 'react';
// import Header from '../../_components/header/header';
// import Footer from '../../_components/footer/footer';
import CampaignProduct from '../../_components/sections/CampaignProduct';
import campaign from '../../services/Queries/campaigns/campaigns';
import { getValue } from '../../utils/lodash';
import graphQLRequestGlobal from '../../services/graphQlRequestGlobal';

class Campaigns extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            product: []
        }
    }

    componentDidMount = async () => {
        window.scroll({ top: 0, behavior: "smooth" });
        let data = {
            where:{
                 slug: this.props.match.params.id
                 }
            }
        let resp = await graphQLRequestGlobal(campaign, data)
        if(resp){
        this.setState({
            product: getValue(resp, "campaigns", [])
        })
    }
    }
    render() {
        return (
            <>
                {/* <Header productPage={"d-none d-md-block"} /> */}
                <CampaignProduct product={this.state.product[0]} />
                <div className="d-none d-md-block">
                    {/* <Footer /> */}
                </div>
            </>
        )
    }
}

export default Campaigns;