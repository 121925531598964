import React, { Component } from "react";
import { toast } from "react-toastify";
import Button from "reactstrap/lib/Button";
import Container from "reactstrap/lib/Container";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import SimpleReactValidator from "simple-react-validator";
import graphQLRequest from "../../services/graphQlRequest";
import forgotPassword from "../../services/Queries/forgotPassword/forgotPassword";
import { getValue } from "../../utils/lodash";

class ForgotPassword extends Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator();
    this.state = {
      email: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      let email = {
        email: this.state.email,
      };
      let resp = await graphQLRequest(forgotPassword, email);
      if (resp) {
        this.props.history.push("/email-sent");
      } else {
        // toast.error("Something went wrong,Please try later");
      }
    } else {
      this.validator.showMessages();
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.forceUpdate();
    }
  };

  render() {
    return (
      <Container fluid={true}>
        <div className="login-container">
          <h3 className="d-none d-md-block">Forgot Password</h3>
          <div className="form-group form-icon">
            <img src="/images/username.svg" alt="" />
            <Input
              type="email"
              name="email"
              placeholder="&nbsp;"
              id="email"
              value={getValue(this.state, "email", "")}
              label="Email"
              onChange={this.handleChange}
            />
            <Label htmlFor="email" className="on-hover">
              Enter email address
            </Label>
            {
              <span className="form-error">
                {this.validator.message("Email", this.state.email, "required")}
              </span>
            }
            <div className="form-group mt-4">
              <Button block color="red" onClick={this.handleSubmit}>
                Send
              </Button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default ForgotPassword;
