import addItemToCart from "./addItemToCart.js";
import graphQLRequest from "../../../graphQlRequest.js";

/**
 * Fetch a Add items cart by  ids
 *
 * @param {String} Id's - The input to update
 * @returns {Object} Cart - the fetched Add items to cart
 */

export default async function fetchAddItemsCart(cart_id, campaign_id, quantity, anonymous_cart_token) {
    if (!cart_id) return { cart: {} };

    const resp = await graphQLRequest(addItemToCart, {
        input: {
            anonymous_cart_token,
            cart_id,
            campaign_id,
            quantity,
        }
    });

    return resp && resp;
}