// ******************************************** //
// ***********  Wishlist Page  **************** //
// ******************************************** //

import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import { Col, Row, Container } from "reactstrap";
import LeftMenu from "../../_components/cards/LeftMenu";
import ProductCardFull from "../../_components/cards/ProductCardFull";
import graphQLRequest from "../../services/graphQlRequest";
import myProfile from "../../services/Queries/profile/myProfile";
import { getValue } from "../../utils/lodash";
import campaigns from "../../services/Queries/campaigns/campaigns";
import { NavLink } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";
class Wishlist extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      wishListIds: [],
      ids: [],
      campaignsArray: [],
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getData();
    this.campaignList();
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    }
  }
  getData = async () => {
    let resp = await graphQLRequest(myProfile);
    if (resp) {
      this.setState({
        wishListIds: getValue(resp, "myProfile.wishlist"),
      });
      this.setState({ ...this.state });
      this.wishlistIds();
    }
  };
  campaignList = async () => {
    let resp = await graphQLRequest(campaigns);
    if (resp) {
      this.setState({
        campaignsArray: resp.campaigns,
      });
    }
  };
  wishlistIds = async () => {
    if (this.state.wishListIds) {
      this.state.wishListIds.map((item) => this.state.ids.push(item._id));
      this.setState({ ...this.state });
    }
  };
  render() {
    const productList = getValue(this.state, "campaignsArray", []).filter(
      (product) => {
        if (this.state.ids.includes(product._id)) {
          return product;
        }
      }
    );
    return (
      <div className="homewhite">
        {/* <Header /> */}
        <Container fluid={true}>
          <section className="dashboard-page dashboard-page-resp">
            <h2 className="dashboard-heading">My Wishlist</h2>
            <Row><Col md={{ size: "3" }} className="d-none d-md-block">
                <LeftMenu />
              </Col>
              <Col md={{ size: "9" }}>
                {productList.length !== 0 ? (
                  productList &&
                  productList.map((product) => {
                    return (
                      <div>
                        <ProductCardFull
                          cname="cart-wishlist"
                          wishlistIds={this.state.ids}
                          status="true"
                          product={product}
                        />
                      </div>
                    );
                  })
                ) : (
                  <>
                    <div className="cart-empty">
                      <img src="/images/whishlist.svg" alt="" width="110" />
                    </div>
                    <h6 className="text-center mt-5">
                        You currently have no items in your Wishlist, Explore our
                        <br />
                        products and get your chance to win amazing luxury prizes
                      </h6>

                      <div className="mt-5 text-center">
                      <NavLink to="/campaigns">
                        <Button color="white-light" className="px-5 py-2">
                        See All Campaigns
                        </Button>
                      </NavLink>
                    </div>
                  </>
                )}
              </Col>
              
            </Row>
          </section>
        </Container>
        {/* <Footer /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};
export default connect(mapStateToProps)(Wishlist);