import * as serviceWorker from "./serviceWorker";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import store from "./redux/configureStore";
import { Provider } from "react-redux";

// Configure cache
const cache = new InMemoryCache();

const client = new ApolloClient({
  uri: "https://backend.madoverdreams.com/graphql",
  cache: cache.restore(window.__APOLLO_STATE__ || {}),
});

// console.log(store.getState())
// console.log(client)

ReactDOM.hydrate(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
