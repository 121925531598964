export default `
mutation updateCartDonation($input:updateCartDonationInput!) { 
    updateCartDonation(input:$input){ 
        cart{
            id
            is_donated
            total_coupons
            items{
              id
              item{
                id
                title
                product{
                  slug
                  title
                  images{
                    url
                  }
                }
                reward{
                  slug
                  _id
                  title
                  description
                  price
                  images{
                    url
                  }
      
                }
              }
         
              price
              quantity
            }
            currency_code
            total_price
            subtotal_price
            subtotal_price
            total_price
          }
    }
}
`;
