import React, { Component } from "react";
import Button from "reactstrap/lib/Button";
import Container from "reactstrap/lib/Container";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import { getValue } from "../../utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import { toast } from "react-toastify";
import resetPassword from "../../services/Queries/forgotPassword/resetPassword";

class ResetPassword extends Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator();
    this.state = {
      password: "",
      confirmPassword: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.validator.allValid()) {
      if (this.state.password === this.state.confirmPassword) {
        let code = window.location.href.split("/");
        let code1 = code[3].split("?");
        let code2 = code1[1].split("=");
        let data = {
          password: this.state.password,
          passwordConfirmation: this.state.confirmPassword,
          code: code2[1],
        };
        let resp = await graphQLRequestGlobal(resetPassword, data);
        if (resp) {
          toast.success("updated successfully");
          this.props.history.push("/password-reset");
        } else {
          // toast.error("Something went wrong,Please try later");
        }
      } else {
        toast.error("Passwords should be match");
      }
    } else {
      this.validator.showMessages();
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.forceUpdate();
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <Container fluid={true}>
        <div className="login-container">
          <h3 className="d-none d-md-block">Forgot Password</h3>
          <div className="form-group form-icon">
            <img src="/images/password.svg" alt="" />
            <Input
              type="password"
              name="password"
              placeholder="&nbsp;"
              id="password"
              value={getValue(this.state, "password", "")}
              label="Password"
              onChange={this.handleChange}
            />
            <Label htmlFor="password" className="on-hover">
              Enter Password
            </Label>

            {
              <span className="form-error">
                {this.validator.message(
                  "Password",
                  this.state.password,
                  "required"
                )}
              </span>
            }
          </div>
          <div className="form-group form-icon">
            <img src="/images/password.svg" alt="" />
            <Input
              type="password"
              name="confirmPassword"
              placeholder="&nbsp;"
              id="confirmPassword"
              value={getValue(this.state, "confirmPassword", "")}
              label="confirmPassword"
              onChange={this.handleChange}
            />
            <Label htmlFor="password" className="on-hover">
              Confirm Password
            </Label>

            {
              <span className="form-error">
                {this.validator.message(
                  "confirmPassword",
                  this.state.confirmPassword,
                  "required"
                )}
              </span>
            }
          </div>

          <div className="form-group mt-4">
            <Button block color="red" onClick={this.handleSubmit}>
              Send
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}

export default ResetPassword;
