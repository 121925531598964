import React, { Component } from 'react';
import { setCurrentUser } from '../../redux/_actions/userAction';
import setAuthToken from '../../utils';
import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../../redux/_selectors/userSelectors';
import configApi from '../../utils/configApi';
import axios from 'axios';
import Cookies from 'js-cookie';
import FullLoader from '../../_components/loader/full-loader';


class SocialLogin extends Component {
    componentDidMount() {
        
            Cookies.get("google") ? this.google() : this.facebook()
        
    }

    google = async () => {
        let token = {
            access_token: this.props.location.search.split("?")[1].split("access_token=")[1].split("raw")[0].split("&")[0]
        }
        let resp = configApi.authenticateAppWithStrapi("google", token.access_token)
        if (resp) {

            let jwt = await axios({
                method: "GET",
                url: `${resp}`,
            })
                .then(res => res.data.jwt)
            localStorage.setItem("jwt", jwt);
            setAuthToken(jwt)
            const decoded = jwt_decode(jwt);
            this.props.dispatch(setCurrentUser(decoded))
        }
    }

    facebook = async () => {
        let token = {
            access_token: this.props.location.search.split("?")[1].split("access_token=")[1].split("raw")[0].split("&")[0]
        }
        let resp = configApi.authenticateAppWithStrapi("facebook", token.access_token)
        if (resp) {

            let jwt = await axios({
                method: "GET",
                url: `${resp}`,
            })
                .then(res => res.data.jwt)
            localStorage.setItem("jwt", jwt);
            setAuthToken(jwt)
            const decoded = jwt_decode(jwt);
            this.props.dispatch(setCurrentUser(decoded))
        }
    }


    componentDidUpdate() {
        if (this.props.auth.isAuthenticated === true && !Cookies.get("accountCartId")) {
            window.location.href = ("/")
        } else {
            console.log("Hey something went wrong")
        }
    }
    render() {
        return (
            <div>
                <FullLoader />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: selectCurrentUser(state),
    }

}

export default connect(mapStateToProps)(SocialLogin);
