import React from "react";
import { Button, Col, Row } from "reactstrap";
import { API_URL } from "../../env";
import { getValue } from "../../utils/lodash";
import Moment from "react-moment";

class ActiveCouponsCard extends React.Component {
  render() {
    const { className, coupon } = this.props;
    return (
      <div className={"coupon-card-full " + className}>
        <div className="coupon-card-top">
          <Row>
            <Col md="4">
              <img
                src={`${API_URL}${getValue(
                  coupon,
                  "campaign.reward.images[0].url",
                  ""
                )}`}
                alt=""
                className="img-fluid"
              />
            </Col>
            <Col md="8" className="d-flex flex-column justify-content-center">
              <h2>{getValue(coupon, "campaign.reward.title", "")}</h2>
              {className === "half" ? (
                ""
              ) : (
                <>
                  <p>
                    <Moment format="DD MMM YYYY">
                      {getValue(coupon, "updatedAt", "")}
                    </Moment>
                  </p>
                  <p>
                    <Moment format="hh:mm">
                      {getValue(coupon, "updatedAt", "")}
                    </Moment>
                  </p>
                </>
              )}
            </Col>
          </Row>
        </div>

        <div className="coupon-card-bottom">
          <Row>
            <Col md="6">
              <h2>{getValue(coupon, "coupon_id", "")}</h2>
              {className !== "half" ? (
                <>
                  <p className="d-none">
                    <Moment format="DD MMM YYYY">
                      {getValue(coupon, "updatedAt", "")}
                    </Moment>
                  </p>
                  <p className="d-none">
                    <Moment format="hh:mm">
                      {getValue(coupon, "updatedAt", "")}
                    </Moment>
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <Moment format="DD MMM YYYY">
                      {getValue(coupon, "updatedAt", "")}
                    </Moment>
                  </p>
                  <p>
                    <Moment format="hh:mm">
                      {getValue(coupon, "updatedAt", "")}
                    </Moment>
                  </p>
                </>
              )}
            </Col>
            {/* <Col md="6" className="text-md-right">
              <Button color="white">WATCH DRAW</Button>
            </Col> */}
          </Row>
        </div>
      </div>
    );
  }
}
export default ActiveCouponsCard;
