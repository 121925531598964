import React from "react";
import { Row, Col, Button, Input, Label } from "reactstrap";
import { getValue } from "../../utils/lodash";
import InputField from "../common/inputField";
import Container from "reactstrap/lib/Container";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";

class ShippingCard extends React.Component {
  render() {
    return (
      <Container fluid={true}>
        <section className="dashboard-page">
          <h2 className="dashboard-heading">Checkout</h2>

          <Row>
            <Col md={{ size: "9" }}>
              {/* {
                getValue(this.props, "addresses.firstname") &&
                  getValue(this.props, "addresses.lastname") &&
                  getValue(this.props, "addresses.user.email") &&
                  getValue(this.props, "addresses.phone")
                  ?
                  <>
                    <ul className="shipping-steps">
                      <li>
                        <NavLink to="/shipping">
                          <span className="ship-numb">1</span>
                          <span className="ship-text">Shipping Details</span>
                        </NavLink>
                      </li>
                      <li>
                        <Button color="nothing">
                          <span className="ship-numb">2</span>
                          <span className="ship-text">Review & Place Order</span>
                        </Button>
                      </li>
                    </ul>
                  </>
                  :
                  <ul className="shipping-steps">
                    <li>
                      <NavLink to="/basic-info">
                        <span className="ship-numb">1</span>
                        <span className="ship-text">Basic Details</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/shipping">
                        <span className="ship-numb">2</span>
                        <span className="ship-text">Shipping Details</span>
                      </NavLink>
                    </li>
                    <li>
                      <Button color="nothing">
                        <span className="ship-numb">3</span>
                        <span className="ship-text">Review & Place Order</span>
                      </Button>
                    </li>
                  </ul>
              } */}


              <h4 className="text-red-light">
                <b>{!getValue(this.props,"isDonate",'')?"Shipping Address":"Billing Address"}</b>
              </h4>
              {getValue(this.props, "addresses.addresses", []) === 0
                ? ""
                : getValue(this.props, "addresses.addresses", []).map(
                  (addr, index) => {
                    if (index < 4) {
                      return (
                        <div className="address-card">
                          <h5 className="mb-0">
                            <b>
                              {getValue(this.props, "addresses.firstname", "")}{" "}
                              {getValue(this.props, "addresses.lastname", "")}
                            </b>
                          </h5>
                          <h5 className="mb-3">
                            Phone{getValue(this.props, "addresses.phone", "")}
                            <br></br>
                            Pincode :{getValue(addr, "pincode", "")}
                            {". "}
                            <br></br>
                            {getValue(addr, "address_line_1", "")}
                            {", "}
                            {getValue(addr, "address_line_2", "")}
                            {". "}
                            <br></br>
                            {getValue(addr, "country", "")}
                            {", "}
                            {getValue(addr, "state", "")}
                            {", "}
                            {getValue(addr, "city", "")}
                            {"."}
                            <br></br>
                          </h5>
                          <Button
                            color="red"
                            className="mb-2 py-2"
                            onClick={() => {
                              this.props.handleEditAddress(addr._id);
                            }}
                            block
                          >
                            {!getValue(this.props,"isDonate",'')?"Deliver to this address":"Billing in this Address"}
                          </Button>
                          <Row>
                            <Col>
                              <Button
                                className="p-1"
                                color="border"
                                onClick={() => {
                                  this.props.handleEditAddress(addr._id);
                                }}
                                block
                              >
                                Edit
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                className="p-1"
                                color="border"
                                onClick={() => {
                                  this.props.handleRemoveAddress(addr._id);
                                }}
                                block
                              >
                                Delete
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  }
                )}
              {/* <h4 className="text-red-light">
                <b>Other Addresses</b>
              </h4>
              <div className="address-card">
                <h5 className="mb-0">
                  <b>Pranav Kumar</b>
                </h5>
                <h5 className="mb-3">
                  Flat No.901, 9th Floor, The Gardenia, AK Block, Shanthi
                  Colony, Bangalore Karnataka-600040 India
                </h5>
                <Button color="red" className="mb-2 py-2" block>
                  Deliver to this address
                </Button>
                <Row>
                  <Col>
                    <Button className="p-1" color="border" block>
                      Edit
                    </Button>
                  </Col>
                  <Col>
                    <Button className="p-1" color="border" block>
                      Delete
                    </Button>
                  </Col>
                </Row>
              </div>
              <div className="address-card">
                <h5 className="mb-0">
                  <b>Pranav Kumar</b>
                </h5>
                <h5 className="mb-3">
                  Flat No.901, 9th Floor, The Gardenia, AK Block, Shanthi
                  Colony, Bangalore Karnataka-600040 India
                </h5>
                <Button color="red" className="mb-2 py-2" block>
                  Deliver to this address
                </Button>
                <Row>
                  <Col>
                    <Button className="p-1" color="border" block>
                      Edit
                    </Button>
                  </Col>
                  <Col>
                    <Button className="p-1" color="border" block>
                      Delete
                    </Button>
                  </Col>
                </Row>
              </div>
             */}
              {/* <h4 className="text-red-light">
                <b>Add new address</b>
              </h4> */}
              <Row className="personal-form-card">
                <Col md="5">
                  <InputField
                    inputType="TEXT"
                    validator={this.props.validator}
                    name="address_line_1"
                    id="address_line_1"
                    value={getValue(this.props.request, "address_line_1", "")}
                    label="Address Line 1"
                    setPath="address_line_1"
                    onChange={this.props.handleChange}
                    placeholder="&nbsp;"
                  />
                </Col>
                <Col md={{ size: "5", offset: "1" }}>
                  <InputField
                    inputType="TEXT"
                    validator={this.props.validator}
                    name="address_line_2"
                    id="address_line_2"
                    value={getValue(this.props.request, "address_line_2", "")}
                    label="Address Line 2"
                    setPath="address_line_2"
                    onChange={this.props.handleChange}
                    placeholder="&nbsp;"
                  />
                </Col>
                <Col md="5">
                  <InputField
                    inputType="NUMBER"
                    validator={this.props.validator}
                    name="pincode"
                    id="pincode"
                    value={getValue(this.props.request, "pincode", "")}
                    label="Pincode"
                    setPath="pincode"
                    onChange={this.props.handleChange}
                    placeholder="&nbsp;"
                  />
                </Col>
                <Col md={{ size: "5", offset: "1" }}>
                  <div className="form-group">
                    <Input type="select" onChange={this.props.handleCountries}>
                      <option value={0}>Select Country</option>
                      {this.props.countries &&
                        this.props.countries.map((ele, i) => {
                          return (
                            <option
                              key={i}
                              value={ele.id}
                              selected={ele.id === this.props.selectedCountry}
                            >
                              {ele.name}
                            </option>
                          );
                        })}
                    </Input>
                    <Label className="on-hover">Country</Label>
                    <div className="form-error">
                      {this.props.validator.message(
                        "country",
                        this.props.request.country,
                        "required"
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="5">
                  <div className="form-group">
                    <Input type="select" onChange={this.props.handleRegion}>
                      <option value={0} default>
                        Select State
                      </option>
                      {this.props.states &&
                        this.props.states.map((ele, i) => {
                          return (
                            <option
                              key={i}
                              value={ele.id}
                              selected={ele.id === this.props.selectedState}
                            >
                              {ele.name}
                            </option>
                          );
                        })}
                    </Input>
                    <Label className="on-hover">State</Label>
                    <div className="form-error">
                      {this.props.validator.message(
                        "State",
                        this.props.request.state,
                        "required"
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={{ size: "5", offset: "1" }}>
                  <div className="form-group">
                    <Input type="select" onChange={this.props.handleCity}>
                      <option value={0}>Select City</option>
                      {this.props.cities &&
                        this.props.cities.map((ele, x) => {
                          return (
                            <option
                              key={x}
                              value={ele.id}
                              selected={ele.id === this.props.selectedCity}
                            >
                              {ele.name}
                            </option>
                          );
                        })}
                    </Input>
                    <Label className="on-hover">City</Label>
                    <div className="form-error">
                      {this.props.validator.message(
                        "city",
                        this.props.request.city,
                        "required"
                      )}
                    </div>
                  </div>
                </Col>
                <Col md="12" className="mt-4">
                  <Button color="red" onClick={this.props.handleSubmit}>
                    Save And Continue
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </Container>
    );
  }
}
export default ShippingCard;
