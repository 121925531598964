export default `
mutation createEnquiry($input:createEnquiryInput) { 
    createEnquiry(input:$input){ 
        enquiry{
                fullname,
                email,
                phone,
                category,
                message
            }
        }
    }
`;