import { combineReducers } from "redux";
import userReducer from "./userReducer";
import productReducer from "./productReducer";
import authReducer from "./authReducer";
import categoriesReducer from './categoryReducer'
import cartReducer from "./cartFetchReducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  cart: cartReducer,
  products:productReducer,
  categories:categoriesReducer,
  cartReducer:cartReducer
});