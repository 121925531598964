export default `
query myOrders(
    $limit: Int!
    $start: Int!
    $sort:String
    ){ 
    myOrders(
        limit: $limit
        start: $start
        sort : $sort
    ) {
        orders{
        order_status
        _id
        order_id
        email
        firstname
        lastname
        phone
        tracking_number
        tracking_link
        is_donated
        shipping_price
        shipping_info{
            address_line_1
            address_line_2
            city
            state
            country
            pincode
        }
        items{
            quantity
            coupons
            price
            campaign{
                image{
                    url
                }
                title
                draw_date
                end_date
                product{
                    createdAt
                    updatedAt
                    title
                    description
                    price
                    specification
                    currency_code
                    slug
                    images{
                        url
                    }
                }
                reward{
                    createdAt
                    updatedAt
                    title
                    description
                    price
                    specification
                    currency_code
                    slug
                    images{
                        url
                    }
                }
            }
        }
        }

        limit
        start
        total_count
    }
  }
`;