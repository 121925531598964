import React from 'react';
class UserSVG extends React.Component {
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                <g id="Login_Icon" data-name="Login Icon" transform="translate(-1769 -40)">
                    <circle id="Ellipse_196" data-name="Ellipse 196" cx="5" cy="5" r="5" transform="translate(1775 40)" fill="#fff" />
                    <path id="Path_111" data-name="Path 111" d="M7.825,0l3.028-.141L14,0c2.761,0,8,2.239,8,5V7a3,3,0,0,1-3,3H3A3,3,0,0,1,0,7V5C0,2.239,5.064,0,7.825,0Z" transform="translate(1769 52)" fill="#fff" />
                </g>
            </svg>
        )
    }
}
export default UserSVG