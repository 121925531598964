
// ******************************************** //
// ***********  Category Actions  ************* //
// ******************************************** //


import * as actionTypes from "../_constants/index";
import categoriesQuery from "../../services/Queries/categories/categories";
// import graphQLRequest from "../../services/graphQlRequest";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";

// ACTION TYPES
const fetchCategorybegin = () => {
  return {
    type: actionTypes.FETCH_CATEGORY_BEGIN
  };
};
const fetchCategorySuccess = (products) => {
  return {
    type: actionTypes.FETCH_CATEGORY_SUCCESS,
    payload: products
  };
};
const fetchCategoryFailure = (products) => {
  return {
    type: actionTypes.FETCH_CATEGORY_FAILURE,
    payload: products
  };
};



// ACTION CREATERS
const fetchCategoriesList = () => async dispatch => {
  dispatch(fetchCategorybegin());
  try {
    const data = await graphQLRequestGlobal(categoriesQuery);
    dispatch(fetchCategorySuccess(data.categories));
  } catch (error) {
    dispatch(fetchCategoryFailure(error));
  }
};


export {
    fetchCategoriesList
};
