export default `
query winners{ 
    winners{
       firstname
       lastname
        image{
          url
        }
        campaign{
            reward{
                title
              images{
                url
              }
            }
          }
      }
  }
`;

