export default `
query products{ 
    products {
      id
      title
      description
      price
      currency_code
      slug
      _id
      title
      images{
          url
      }    
    }
  }
`;

