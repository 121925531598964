import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import { Container } from "reactstrap";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import sitePages from "../../services/Queries/sitePages/sitePages";
import { getValue } from "../../utils/lodash";
import MapContact from "../../_components/sections/MapContact";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import HomeApp from "../../_components/sections/HomeApp";
// import showdown from "showdown";

class About extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      siteInfo: [],
    };
  }

  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.getInfo();
  }
  getInfo = async () => {
    let resp = await graphQLRequestGlobal(sitePages);
    this.setState({
      siteInfo: resp.sitePages.filter((item) => item.slug === "about-mod"),
    });
  };

  render() {
    // var converter = new showdown.Converter();
    return (
      <>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: "10", offset: "1" }}>
              <h2 className="home-heading my-5">About Us</h2>
            </Col>
          </Row>
        </Container>
        <div className="banner-img">
          {/* <img src="/images/about.png" alt="" className="img-fluid" /> */}
          <img src="/images/ABOUT-US.png" alt="" className="img-fluid" />
        </div>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: "8", offset: "2" }} className="pt-5">
              {getValue(this.state, "siteInfo", "").map((item) => {
                return (
                  <p
                    className="abtcnt"
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{
                      __html: getValue(item, "content", ""),
                    }}
                  />
                );
              })}
            </Col>
          </Row>
        </Container>
        <MapContact />
        <HomeApp />
      </>
    );
  }
}
export default About;
