export default `
query myCoupons(
    $limit: Int!
    $start: Int!
    $sort:String
    ){ 
        myCoupons(
        limit: $limit
        start: $start
        sort : $sort
    ) {
        coupons{
            createdAt
            updatedAt
            coupon_id
            campaign{
              image{
                url
              }
              title
              end_date
              draw_date
              status
              product{
                title
                price
                images{
                  url
                }
              }
              reward{
                title
                price
                images{
                  url
                }
              }
            }
      
          }
          total_count
          limit
          start
    }
  }
`;