import {API_URL} from '../env'

const authenticateStrapiWithProvider = (provider) => {
    return `${API_URL}/connect/${provider}`;
  } 
  
  const authenticateAppWithStrapi = (provider, search) => {
    return `${API_URL}/auth/${provider}/callback?access_token=${search}`;
  }
  
  export default {
    authenticateStrapiWithProvider,
    authenticateAppWithStrapi
  }