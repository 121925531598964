// ******************************************** //
// ***********    Login page   **************** //
// ******************************************** //

import React, { Component } from "react";
import Header from "../../_components/header/header";
import { Button, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { selectCurrentUser } from "../../redux/_selectors/userSelectors";
import { getValue } from "../../utils/lodash";
import graphQLRequest from "../../services/graphQlRequest";
import myOrder from "../../services/Queries/myOders/myOrder";
import { NavLink } from "react-router-dom";

class OrderPlaced extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      myOrder: {},
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
    if (
      !this.props.auth.isAuthenticated &&
      getValue(localStorage, "jwt.length", 0) === 0
    ) {
      this.props.history.push("/");
    }
    this.getOrderDetails();
  }

  getOrderDetails = async () => {
    let id = {
      id: this.props.match.params.order_id,
    };
    let resp = await graphQLRequest(myOrder, id);
    if (resp) {
      this.setState({
        myOrder: getValue(resp, "myOrder.order", {}),
      });
    }
  };

  componentDidUpdate() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <>
        {/* <Header /> */}
        <Container fluid={true}>
          <div className="order-placed">
            <div className="mb-4">
              <img src="/images/successs.svg" width="30" alt="" />
            </div>
            <h2 className="text-green mb-0">Order Placed Successfully</h2>
            {/* <h4>
              You will receive an email confirmation to{" "}
              {getValue(this.state, "myOrder.email")}
              <br />
              with the reciept
            </h4>
            <h5>Order Id : {getValue(this.state, "myOrder.order_id")}</h5> */}
            <div className="mt-4">
            <p>That was an exciting purchase.<br /> Stay tuned to win the draw!</p>
            </div>
            
            <div className="mt-5">
            <NavLink to="/coupons">
                <Button color="red" className="px-4 py-2 mb-2">
                  View Coupon
                </Button>
              </NavLink>
              </div>
              <div className="mt-2">
              <NavLink to="/campaigns">
                <button class="px-5 py-2 btn btn-white-light">Continue Shopping</button>
              </NavLink>
              
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};

export default connect(mapStateToProps)(OrderPlaced);
