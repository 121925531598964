import React from 'react';
class FullLoader extends React.Component {
    render() {
        return (
            <div className="full-loader">
                <div className="loader-container">
                    <img src="/images/loading.gif" alt="" />
                </div>
            </div>
        )
    }
}
export default FullLoader