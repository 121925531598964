export default `
query myOrder($id:ID!){ 
    myOrder(id:$id) {
        order{
        _id
        order_id
        email
        firstname
        lastname
        phone
        tracking_number
        }
    }
  }
`;
