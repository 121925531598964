import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import Container from "reactstrap/lib/Container";

class PasswordReset extends Component {
  render() {
    return (
      <Container fluid={true}>
        <div className="login-container text-center bg-white">
          <img src="/images/success.gif" alt="" className="mt-4" />
          <h4 className="text-center text-blue mt-3">
            Password resetted successfully
          </h4>
          {/* <p className="text-center">
            Please check your registered email id fro the reset link.
          </p> */}
          <NavLink to="/Login">
            <Button color="red">Back to Login</Button>
          </NavLink>
        </div>
      </Container>
    );
  }
}

export default PasswordReset;
