import * as actionTypes from "../_constants/index";

const initialState = {
  loading: false,
  cart: [],
  error: null
};

const cartReducer = (currentState = initialState, action) => {
  switch (action.type) {

    // FETCH ITEMS:
    case actionTypes.FETCH_CART_BEGIN:
      return {
        ...currentState,
        loading: true
      };
    case actionTypes.FETCH_CART_SUCCESS:
      return {
        ...currentState,
        loading: false,
        cart: action.payload
      }
    case actionTypes.FETCH_CART_FAILURE:
      return {
        ...currentState,
        loading: false,
        error: action.payload
      }

    default:
      return currentState;
  }
};

export default cartReducer;