export default `
mutation addItemToCart($input:addItemToCartInput!) { 
    addItemToCart(input:$input){ 
        cart{
            items{
              id
              item{
                title
              }
              quantity
              price
            }
          }
    }
}
`;
