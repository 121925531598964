export default `
query verifyOrder($payment_order_id:String!,$payment_id:String!,$payment_signature:String!){ 
    verifyOrder(payment_order_id:$payment_order_id,payment_id:$payment_id,payment_signature:$payment_signature) {
        order{
          id
          order_id
          email
          firstname
          lastname
          phone
          tracking_number
        }    
    }
  }
`;

