// ******************************************** //
// ***********  Home Page   ******************* //
// ******************************************** //

import React from "react";
import Header from "../../_components/header/header";
import BannerHome from "../../_components/banners/BannerHome";
import HomeCategory from "../../_components/sections/HomeCategory";
import Footer from "../../_components/footer/footer";
import HomeWinners from "../../_components/sections/HomeWinners";
import HomeAllWinners from "../../_components/sections/HomeAllWinners";
import MapContact from "../../_components/sections/MapContact";
import HomeApp from "../../_components/sections/HomeApp";
import HomeCharities from "../../_components/sections/HomeCharities";
import siteSetting from "../../services/Queries/social/links";
import graphQLRequest from "../../services/graphQlRequest";
import { getValue } from "../../utils/lodash";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import {
  campaignList,
  cartList,
  selectCurrentUser,
  selectCurrentUserStatus,
} from "../../redux/_selectors/userSelectors";
import myCart from "../../services/Queries/cart/common/myCart";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import FullLoader from "../../_components/loader/full-loader";
import ComponentLoader from "../../_components/loader/component-loader";
import EarlyBirdCategory from "../../_components/sections/EarlyBirdCategory";
import winners from "../../services/Queries/winners/winners";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";

class Home extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      winners: [],
    };
  }
  componentDidMount = async () => {
    window.scroll({ top: 0, behavior: "smooth" });
    let resp = await graphQLRequestGlobal(winners);
    if (resp) {
      this.setState({
        winners: getValue(resp, "winners", []),
      });
      Cookies.set("isActive", true);
    }
    this.getCartId();
  };

  getCartId = async () => {
    if (Cookies.get("anonymousCartToken") && !this.props.auth.isAuthenticated) {
      let anonymous_cart_token = {
        anonymous_cart_token: Cookies.get("anonymousCartToken"),
      };
      let resp = await graphQLRequest(myCart, anonymous_cart_token);
      if (resp) {
        Cookies.set("anonymousCartId", getValue(resp, "myCart.cart.id", ""));
      }
    } else {
      if (this.props.auth.isAuthenticated) {
        let resp = await graphQLRequest(myCart);
        if (resp) {
          Cookies.set("accountCartId", getValue(resp, "myCart.cart.id", ""));
        }
      }
    }
  };

  componentDidUpdate() {
    if (getValue(localStorage, "jwt") && Cookies.get("anonymousCartToken")) {
      window.location.href = "/cart";
    }
    if (
      Cookies.get("wishlistId") &&
      Cookies.get("wishlistIdset") &&
      getValue(localStorage, "jwt")
    ) {
      window.location.href = "/wishlist";
    }
  }

  render() {
    // let list = getValue(this.props, "products.products", []).filter(
    //   (item) => item.status === "closing_soon"
    // );
    return (
      <div className="homewhite">
        <BannerHome />
        {/* {list.length !== 0 ? <EarlyBirdCategory /> : ""} */}
        <HomeCategory />
        {/* <HomeWinners winners={this.state.winners} /> */}
        <HomeAllWinners />
        <HomeCharities />
        <Container fluid={true} className="my-5">
          {/* <div className="d-md-none"> */}
          <Row>
            <Col md={{ size: "10", offset: "1" }}>
              <h3 className="mt-3 mt-md-0">Our charity partners</h3>
              <Row className="resp-mobile-client">
                <Col xs="6" md="3" className="mb-3">
                  <a href="https://makeadiff.in/" target="_blank"><img src="/images/client1.png" alt="" className="img-fluid" /></a>
                </Col>
                <Col xs="6" md="3" className="mb-3">
                <a href="https://www.bhumi.ngo/" target="_blank"><img src="/images/client2.png" alt="" className="img-fluid" /></a>
                </Col>
                <Col xs="6" md="3" className="mb-3">
                <a href="https://nanmafoundation.org/" target="_blank"> <img src="/images/client3.png" alt="" className="img-fluid" /></a>
                </Col>
                <Col xs="6" md="3" className="mb-3">
                <a href="https://letsbethechange.in/" target="_blank"><img src="/images/client4.png" alt="" className="img-fluid" /></a>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* </div> */}
          {/* <Row>
              <Col md={{ size: "10", offset: "1" }}>
                <h3 className="mt-3 mt-md-0">Our charity partners</h3>
                <div className="d-none d-md-block">
                  <div className="resp-mobile-client">
                    <div className="d-inline-block mb-3 mr-2">
                      <img src="/images/client1.png" alt="" className="img-fluid" />
                    </div>
                    <div className="d-inline-block mb-3 mr-2">
                      <img src="/images/client2.png" alt="" className="img-fluid" />
                    </div>
                    <div className="d-inline-block mb-3 mr-2">
                      <img src="/images/client3.png" alt="" className="img-fluid" />
                    </div>
                    <div className="d-inline-block mb-3 mr-2">
                      <img src="/images/client4.png" alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}
        </Container>
        <MapContact />
        <HomeApp />
      </div >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
    user: selectCurrentUserStatus(state),
    products: campaignList(state),
  };
};

export default connect(mapStateToProps)(Home);
