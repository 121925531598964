import React from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { getValue } from "../../utils/lodash";
import siteSetting from "../../services/Queries/social/links";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
class Footer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      social: [],
    };
  }

  componentDidMount = async () => {
    let resp = await graphQLRequestGlobal(siteSetting);
    if (resp) {
      this.setState({
        social: getValue(resp, "siteInfo", []),
      });
    }
  };

  render() {
    return (
      <footer>
        <Container fluid={true}>
          <Row className="d-none d-md-flex">
            <Col md="2">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <NavLink to="/about">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/personal-details">My Account</NavLink>
                </li>
                <li>
                  <NavLink to="/coupons">Active Coupons</NavLink>
                </li>
                <li>
                  <NavLink to="/campaigns">Campaigns</NavLink>
                </li>
                <li>
                  <NavLink to="/campaigns">Products</NavLink>
                </li>
              </ul>
            </Col>
            <Col md="4">
              <h4>Customer Service</h4>
              <ul>
                <li>
                  <NavLink to="/how-it-works">How it works</NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us">Help Center</NavLink>
                </li>
                <li>
                  <NavLink to="/faqs">FAQs</NavLink>
                </li>
                <li>
                  <NavLink to="/terms-condition">Campaigns Terms</NavLink>
                </li>

                <li>
                  <NavLink to="/charities">Charities</NavLink>
                </li>
              </ul>
            </Col>
            <Col md="6" className="border-left">
              <div className="social-media">
                <h4>Socialize with us!</h4>
                <p className="social-icons">
                  <a
                    href={getValue(this.state, "social.facebook_link", "")}
                    target="_blank"
                  >
                    <img src="/images/fb.svg" width="20" alt="" />
                  </a>
                  <a
                    href={getValue(this.state, "social.twitter_link", "")}
                    target="_blank"
                  >
                    <img src="/images/twitter.svg" width="20" alt="" />
                  </a>
                  <a
                    href={getValue(this.state, "social.instagram_link", "")}
                    target="_blank"
                  >
                    <img src="/images/insta.svg" width="20" alt="" />
                  </a>
                  <a
                    href={getValue(this.state, "social.youtube_link", "")}
                    target="_blank"
                  >
                    <img src="/images/youtube.svg" width="20" alt="" />
                  </a>
                </p>
                <p>
                  {" "}
                  For a better shopping
                  <br />
                  experience download our app{" "}
                  <span className="resp-down">
                    <a href="https://play.google.com/store">
                      <img
                        src="/images/Google-play-store.png"
                        alt=""
                        className="app-image2"
                      />
                    </a>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <Row className="d-md-none">
            <Col md="12" className="border-left">
              <div className="social-media">
                <h4>Socialise with us!</h4>
                <p className="social-icons">
                  <a
                    href={getValue(this.state, "social.facebook_link", "")}
                    target="_blank"
                  >
                    <img src="/images/fb.svg" width="20" alt="" />
                  </a>
                  <a
                    href={getValue(this.state, "social.twitter_link", "")}
                    target="_blank"
                  >
                    <img src="/images/twitter.svg" width="20" alt="" />
                  </a>
                  <a
                    href={getValue(this.state, "social.instagram_link", "")}
                    target="_blank"
                  >
                    <img src="/images/insta.svg" width="20" alt="" />
                  </a>
                  <a
                    href={getValue(this.state, "social.youtube_link", "")}
                    target="_blank"
                  >
                    <img src="/images/youtube.svg" width="20" alt="" />
                  </a>
                </p>
              </div>
            </Col>
            <Col md="12">
              <div className="ftr-link">
                <ul>
                  <li>
                    <NavLink to="/about">About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/how-it-works">How it works</NavLink>
                  </li>
                  <li>
                    <NavLink to="/campaigns">Campaigns</NavLink>
                  </li>
                  <li>
                    <NavLink to="/campaigns">Products</NavLink>
                  </li>
                  <li>
                    <NavLink to="/faqs">FAQs</NavLink>
                  </li>
                  <li>
                    <NavLink to="/charities">Charities</NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms-condition">Campaign Terms</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact-us">Help Center</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="ftrcopyright">
                <p>
                  {" "}
                  For a better shopping <br />
                  experience download our app
                  <span className="resp-f-img">
                    <a href="https://play.google.com/store">
                      <img
                        src="/images/Google-play-store.png"
                        alt=""
                        className="app-image2"
                      />
                    </a>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
          <div className="footer-copyright d-md-none">
            <ul>
              <li>
                <img src="/images/ftrmobile.svg" alt="" />
                &copy; 2021. All rights reserved
              </li>
              <br />
              <li>
                <NavLink to="/user-agreement">User Agreement</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
              <li>
                <NavLink to="/cancellation-policy">Cancellation Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-copyright d-none d-md-flex">
            <ul>
              <li>
                <img src="/images/footer.svg" alt="" />{" "}
              </li>
              <li>&copy; 2021. All rights reserved</li>
              <li>
                <NavLink to="/user-agreement">User Agreement</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
              <li>
                <NavLink to="/cancellation-policy">Cancellation Policy</NavLink>
              </li>
            </ul>
          </div>
        </Container>
      </footer>
    );
  }
}
export default Footer;
