import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import rootReducer from "./_reducers/index";
import thunk from "redux-thunk";

// logger is a middleware which logs every activity of the application into the console
// const middlewares = [logger];

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(
      thunk
      //  ...middlewares
    ),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
      compose
  )
);
export default store;

// import { applyMiddleware, createStore, compose } from 'redux';
// import rootReducer from './_reducers/index';

// export default function configureStore(initialState = {}) {
//   const middleware = [];
//   let devTools = next => next;

//   if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
//     devTools = window.__REDUX_DEVTOOLS_EXTENSION__();
//   }

//   return createStore(
//     rootReducer,
//     initialState,
//     compose(
//       applyMiddleware(...middleware),
//       devTools
//     ),
//   );
// }
