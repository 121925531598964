import React from "react";
import Header from "../../_components/header/header";
import Footer from "../../_components/footer/footer";
import MapContact from "../../_components/sections/MapContact";
import { Container } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import HomeApp from "../../_components/sections/HomeApp";
class HowWeWork extends React.Component {
  componentDidMount() {
    window.scroll({ top: 0, behavior: "smooth" });
  }
  render() {
    return (
      <>
        <Container fluid={true}>
          <Row>
            <Col md={{ size: "10", offset: "1" }}>
              <h2 className="home-heading my-md-5 my-3">How it works</h2>
              <iframe title="video" className="how-we-work-iframe" src="https://www.youtube.com/embed/5BUMXUrtN4k"></iframe>
            </Col>
          </Row>
        </Container>
        <MapContact />
        <HomeApp />
      </>
    );
  }
}
export default HowWeWork;
