import React from "react";
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Input from "reactstrap/lib/Input";
import { API_URL } from "../../env";
import { getValue } from "../../utils/lodash";
import RightArrowSVG from "../svg/right-arrow-svg";
import { connect } from "react-redux";
import { userLogout } from "../../redux/_actions/userAction";
import {
  selectCurrentUser
} from "../../redux/_selectors/userSelectors";
// import Dropzone from 'react-dropzone';

class PersonalDetailsCard extends React.Component {
  onLogout = (e) => {
    e.preventDefault();
    this.props.dispatch(userLogout());
  };
  render() {
    return (
      <>
        <div className="card personal-details-card">
          <Row>
            <Col md="7" className="d-flex align-items-center">
              <div
                className="person-image"
                style={{
                  backgroundImage: getValue(
                    this.props,
                    "accountInfo.image.url",
                    ""
                  )
                    ? `url(${API_URL}${getValue(
                      this.props,
                      "accountInfo.image.url",
                      ""
                    )})`
                    : `url("/images/default-user.png")`,
                }}
              >
             {/* <Dropzone onDrop={this.props.onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>Drag</p>
                      </div>
                      <aside>

                      </aside>
                    </section>
                  )}
                </Dropzone> */}

                <Input
                  type="file"
                  onChange={this.props.onSubmit}
                  // accept='image/*'
                  // capture='environment'
                />
                <span class="upload-icon"><img src="/images/camera.svg" alt="" /></span>
              </div>
              {/* {console.log(
                API_URL + getValue(this.props, "accountInfo.image.url", "")
              )} */}
              <div>
                {getValue(this.props, "accountInfo.firstname", "") ? (
                  <>
                    <h3>
                      {getValue(this.props, "accountInfo.firstname", "")}{" "}
                      {getValue(this.props, "accountInfo.lastname", "")}
                    </h3>
                    <p className="mb-0">{getValue(this.props, "email", "")}</p>
                  </>
                ) : (
                    <h3>{getValue(this.props, "email", "")}</h3>
                  )}
              </div>
              <div className="clearfix"></div>
            </Col>
            <Col
              md="5"
              className="d-flex align-items-center justify-content-end"
            >
              <div className="wallet">
                <h4 className="mb-0">
                  {getValue(
                    this.props,
                    "accountInfo.wallet_info.available_points",
                    0
                  )}{" "}
                  <span>Points</span>
                  <img src="/images/wallet.png" alt="" width="40" />
                </h4>
              </div>
            </Col>
          </Row>
        </div>
        {/* resp */}
        <div className="left-menu d-md-none">
          <ul>
            <li>
              <NavLink to="/order-details">
                My Orders
                <span>
                  <RightArrowSVG />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/wallet">
                Wallet
                <span>
                  <RightArrowSVG />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/coupons">
                Active Coupons
                <span>
                  <RightArrowSVG />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/wishlist">
                My Wishlist
                <span>
                  <RightArrowSVG />
                </span>
              </NavLink>
            </li>
            <li>
              <a onClick={this.onLogout}>Logout<span><RightArrowSVG /></span></a>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: selectCurrentUser(state),
  };
};
export default connect(mapStateToProps)(PersonalDetailsCard);
