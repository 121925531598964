import graphQLRequest from "../../graphQlRequest.js";
import updateProfile from "./updateProfile.js";

/**
 * Fetch a profile by its token
 *
 * @param {String} input - The inpu to update
 * @returns {Object} profilw - the fetched profilw
 */ 

export default async function fetchUpdateProfile(data){
    if (!data) return { updateMyProfile: {} };

    const resp = await graphQLRequest(updateProfile, {
        input:{
            data
        }
    });

    return resp && resp;
}