import graphQLRequest from "../../graphQlRequest.js";
import placeOrderMution from "./placeOrder";

/**
 * Fetch a Add items cart by  ids
 *
 * @param {String} Id's - The input to update
 * @returns {Object} Cart - the fetched Add items to cart
 */

export default async function fetchPlaceOrder(cart_id) {
  // if (!is_donated) return { cart: {} };
  const resp = await graphQLRequest(placeOrderMution, {
    input: {
        cart_id,
    },
  });

  return resp && resp;
}
