import graphQLRequest from "../../graphQlRequest.js";
import updateCartDonation from "./updateCartDonation";

/**
 * Fetch a Add items cart by  ids
 *
 * @param {String} Id's - The input to update
 * @returns {Object} Cart - the fetched Add items to cart
 */

export default async function fetchUpdateCartDonation(
  is_donated,
  cart_id,
  anonymous_cart_token
) {
  // if (!is_donated) return { cart: {} };
  const resp = await graphQLRequest(updateCartDonation, {
    input: {
      is_donated,
      cart_id,
      anonymous_cart_token,
    },
  });

  return resp && resp;
}
