import React from "react";
import { Row, Col, Button } from "reactstrap";
import { getValue } from "../../utils/lodash";
import { API_URL } from "../../env";
class PlaceOrderCart extends React.Component {
  render() {
    let { product } = this.props;
    return (
      <>
        {getValue(this.props, "product", []).map((product) => {
          return (
            <>
              <div className="cart-details mr-0">
                <Row className="d-none d-md-flex">
                  <Col
                    md="6"
                    className="d-flex align-items-center flex-column border-right"
                  >
                    <Row className="w-100">
                      <Col md="6">
                        <div
                          className="cart-img1"
                          style={{
                            backgroundImage: `url(${API_URL}${getValue(
                              product,
                              "item.product.images[0].url",
                              ""
                            )})`,
                          }}
                        ></div>
                        {/* <img src={``} alt="" className="img-fluid cart-img1" /> */}
                      </Col>
                      <Col
                        md="6"
                        className="d-flex flex-column justify-content-center"
                      >
                        <h3 className="text-blue mb-2">
                          ₹ {getValue(product, "price", "")}
                        </h3>
                        <p class="mb-0">Product</p>
                        <h4 className="mb-0">
                          {getValue(product, "item.product.title", "")}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6" className="d-flex align-items-center flex-column">
                    <Row className="w-100">
                      <Col md="6">
                        <div
                          className="cart-img1"
                          style={{
                            backgroundImage: `url(${API_URL}${getValue(
                              product,
                              "item.reward.images[0].url",
                              ""
                            )})`,
                          }}
                        ></div>
                        {/* <img src={``} alt="" className="img-fluid " /> */}
                      </Col>
                      <Col
                        md="6"
                        className="d-flex flex-column justify-content-center"
                      >
                        <p className="mb-0">Prize</p>
                        <h4 className="mb-0">
                          {getValue(product, "item.reward.title")}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="d-md-none">
                  <Col
                    xs="4"
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="img-prod-2">
                      <img
                        className="gift-img1"
                        src={API_URL + getValue(product, "item.image.url", "")}
                        alt=""
                      />
                      {/* <img
                        className="prod-img1"
                        src={
                          API_URL +
                          getValue(product, "item.product.images[0].url", "")
                        }
                        alt=""
                      /> */}
                    </div>
                  </Col>
                  <Col
                    xs="8"
                    className="d-flex flex-column justify-content-center"
                  >
                    <h4 className="mb-0">
                      {getValue(product, "item.reward.title")}
                    </h4>
                    <h5 className="mb-0">
                      {getValue(product, "item.product.title", "")}
                    </h5>
                    <h4 className="mb-0 text-blue">
                      ₹ {getValue(product, "price", "")}
                    </h4>
                    <div className="coupon-co">
                      <img src="/images/coupn.svg" alt="" />
                      {getValue(product, "coupons", "")} coupons{" "}
                      <span>for this purchase</span>
                    </div>
                  </Col>
                </Row>
                <div className="coupon-co">
                  {getValue(product, "coupons", "")} coupons
                </div>
              </div>
              <div className="cart-options">
                <Row>
                  <Col>
                    <h4>
                      <span>Qty: {getValue(product, "quantity", 1)}</span>{" "}
                    </h4>
                  </Col>
                  <Col className="text-right">
                    <h5 className="text-left text-black">
                      <b>
                        Product Cost:{" "}
                        <span className="text-red">
                          ₹ {getValue(product, "price", "")}
                        </span>
                      </b>
                    </h5>
                  </Col>
                </Row>
              </div>
            </>
          );
        })}
      </>
    );
  }
}
export default PlaceOrderCart;
