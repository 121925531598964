import { GraphQLClient } from "graphql-request";
import { toast } from "react-toastify";
import { getValue } from "../utils/lodash";
const ISSERVER = typeof window === "undefined";


/**
 * Executes an arbitrary GraphQL query against the Reaction API
 *
 * @param {Object} query - The GraphQL query to execute
 * @param {Object} variables - The query's variables
 * @returns {Object} data - the resulting query data
 */


export const authHeader = () => {
    const authToken = !ISSERVER ? localStorage.jwt : "";
    if (authToken) {
        return { 'Authorization': 'Bearer ' + authToken };
    } else {
        return {};
    }
}

export default async function graphQLRequest(query, variables) {
    const endpoint = "https://backend.madoverdreams.com/graphql";
    try {
        const graphQLClient = new GraphQLClient(endpoint, {
            headers: authHeader(),
            timeout: 20000
        });
        const data = await graphQLClient.request(query, variables);
        return data;
    } catch (error) {
        toast.error(getValue(error, "response.errors[0].message", "Something went wrong,Please try later"))
        // eslint-disable-next-line no-console
        console.error("error-fetching-graphql", error);
        return null;
    }
}
