import updateCartItemQuantity from "./updateCartItemQuantity.js";
import graphQLRequest from "../../../graphQlRequest.js";

/**
 *   Increase/Decrease cart item by its Id
 *
 * @param {String} Cart_id - The input to update
 * @returns {Object} Increase/Decrease - the fetched Increase/Decrease cart items
 */


export default async function fetchRemoveItemsCart(cart_id, cart_item_id, quantity, anonymous_cart_token) {
    if (!cart_id) return { cart: {} };

    const resp = await graphQLRequest(updateCartItemQuantity, {
        input: {
            cart_id,
            cart_item_id,
            quantity,
            anonymous_cart_token
        }
    });

    return resp && resp;
}