import React from "react";
import { Row, Col, Button } from "reactstrap";
import { getValue } from "../../utils/lodash";
import { API_URL } from "../../env";
import { Modal } from "reactstrap";

class CartLeft extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      modal: false,
      id: "",
    };
  }

  toggle = (id) => {
    this.setState({
      modal: !this.state.modal,
      id: id,
    });
    // if(id){
    // removeItemFromCart(getValue(product, "id", ""));
    // }
  };
  removeCart = () => {
    if (this.state.id) {
      this.props.removeItemFromCart(this.state.id);
      this.setState({
        modal: !this.state.modal,
      });
    } else {
      this.setState({
        modal: !this.state.modal,
      });
    }
  };


  render() {
    let {
      product,
      updateCartQuantity,
      updateCartQuantityDec,
      removeItemFromCart,
    } = this.props;
    return (
      <>
        <div className="cart-details">
          {getValue(this.props, "product.item.status") !== "sold_out" ? (
            ""
          ) : (
            <h5 className="not-av d-none d-md-block">
              <span className="not-available-dot"></span> Not Available
            </h5>
          )}
          <Row className="d-none d-md-flex">
            <Col
              md="6"
              className="d-flex align-items-center flex-column border-right"
            >
              <Row className="w-100">
                <Col md="6">
                  <div
                    className="cart-img1"
                    style={{
                      backgroundImage: `url(${API_URL}${getValue(
                        product,
                        "item.product.images[0].url",
                        ""
                      )})`,
                    }}
                  ></div>
                </Col>
                <Col
                  md="6"
                  className="d-flex flex-column justify-content-center"
                >
                  <h3 className="text-blue mb-2">
                    ₹ {getValue(product, "item.product.price", "")}
                  </h3>
                  <p className="mb-0">Product</p>
                  <h4 className="mb-0">
                    {getValue(product, "item.product.title", "")}
                  </h4>
                </Col>
              </Row>
            </Col>
            <Col md="6" className="d-flex align-items-center flex-column">
              <Row className="w-100">
                <Col md="6">
                  <div
                    className="cart-img1"
                    style={{
                      backgroundImage: `url(${API_URL}${getValue(
                        product,
                        "item.reward.images[0].url",
                        ""
                      )})`,
                    }}
                  ></div>
                    {/* <div className="coupon">
                <img src="/images/coupn.svg" alt="" />
                {getValue(product, "coupons", "")} coupons{" "}
                <span>for this purchase</span>
                </div> */}
                  
                  {/* <img src={``} alt="" className="img-fluid " /> */}
                </Col>
                <Col
                  md="6"
                  className="d-flex flex-column justify-content-center"
                >
                  <p className="mb-0">Reward</p>
                  <h4 className="mb-0 text-ellipac">
                    {getValue(product, "item.reward.title")}
                  </h4>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="coupon-co">
                  {getValue(product, "coupons", "")} coupons
                </div>
          <Row className="d-md-none">
            <Col xs="4" className="d-flex flex-column justify-content-center">
              {getValue(this.props, "product.item.status") !== "sold_out" ? (
                ""
              ) : (
                <h5 className="not-av d-md-block">
                  <span className="not-available-dot"></span> Not Available
                </h5>
              )}
              <div className="img-prod-2">
                <img
                  className="gift-img1"
                  src={API_URL + getValue(product, "item.image.url", "")}
                  alt=""
                />
              </div>
            </Col>
            <Col xs="8" className="d-flex flex-column justify-content-center">
              <h4 className="mb-0">{getValue(product, "item.reward.title")}</h4>
              <h5 className="mb-0">
                {getValue(product, "item.product.title", "")}
              </h5>
              <h4 className="mb-0 text-blue">
                ₹ {getValue(product, "item.product.price", "")}
              </h4>
              <div className="coupon-co">
                <img src="/images/coupn.svg" alt="" />
                {getValue(product, "coupons", "")} coupons{" "}
                <span>for this purchase</span>
              </div>
            </Col>
          </Row>
          <div className="cart-count">
            <Button
              color="nothing"
              className="plus"
              onClick={() => {
                updateCartQuantity(
                  getValue(product, "id", ""),
                  parseInt(getValue(product, "quantity", "") + 1)
                );
              }}
            >
              <img src="/images/plus.svg" alt="plus" />
            </Button>
            <h5>{getValue(product, "quantity", 1)}</h5>
            <Button
              disabled={getValue(product, "quantity", 0) === 1}
              color="nothing"
              className="minus"
              onClick={() => {
                updateCartQuantityDec(
                  getValue(product, "id", ""),
                  parseInt(getValue(product, "quantity", "") - 1)
                );
              }}
            >
              <img src="/images/minus-red.svg" alt="minus" />
            </Button>
          </div>
        </div>
        <div className="cart-options d-none d-md-block">
          <Row>
            <Col>
            {getValue(localStorage, "jwt") ? (
              <Button
                color="nothing"
                onClick={() => {
                  this.props.moveToWishList(
                    getValue(product, "item.id", ""),
                    getValue(product, "id", "")
                  );
                }}
              >
                Move to Wishlist
              </Button>):(
                  <Button
                  color="nothing"
                  onClick={() => {
                    this.props.handleRedirectToLogin();
                  }}
                >
                  Move to Wishlist
                </Button>
              )}
              <span className="mx-2">|</span>
              <Button
                color="nothing"
                onClick={() => {
                  this.toggle(getValue(product, "id", ""));
                }}
              >
                Remove from cart
              </Button>
            </Col>
            <Col className="text-right">
              <h5 className="text-left text-black">
                <b>
                  Product Cost:{" "}
                  <span className="text-red">
                    ₹ {getValue(product, "price", "")}
                  </span>
                </b>
              </h5>
            </Col>
          </Row>
        </div>
        <Button
          className="cart-options-mobie add d-md-none"
          color="nothing"
          onClick={() => {
            this.props.moveToWishList(
              getValue(product, "item.id", ""),
              getValue(product, "id", "")
            );
          }}
        >
          Move to Wishlist
        </Button>
        {/* <span className="mx-2">|</span> */}
        <Button
          className="cart-options-mobie add d-md-none"
          color="nothing"
          onClick={() => {
            this.toggle(getValue(product, "id", ""));
          }}
        >
          Remove from cart
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="remove-mdal"
        >
          <div className="campaign-product popup">
            <div className="campaign-details">
              <h5>This product could be your golden chance to win, are you sure you want to delete this?</h5>
              <div className="d-flex justify-content-between mt-3">
                <button
                  className="btn btn-nothing text-red"
                  onClick={this.removeCart}
                >
                  Yes, Remove
                </button>
                <button
                  className="btn btn-nothing text-blue"
                  onClick={this.toggle}
                >
                  Keep
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
export default CartLeft;
