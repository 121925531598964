// ******************************************** //
// ***********  Campaign List   **************** //
// ******************************************** //

import React from "react";
import Header from "../../_components/header/header";
import HomeCategory from "../../_components/sections/HomeCategory";
import FeaturedCampaigns from "../../_components/sections/FeaturedCampaigns";
import Footer from "../../_components/footer/footer";
import HomeWinners from "../../_components/sections/HomeWinners";
import HomeAllWinners from "../../_components/sections/HomeAllWinners";
import graphQLRequest from "../../services/graphQlRequest";
import siteSetting from "../../services/Queries/social/links";
import { getValue } from "../../utils/lodash";
import EarlyBirdCategory from "../../_components/sections/EarlyBirdCategory";
import winners from "../../services/Queries/winners/winners";
class CampaignsList extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      winners: [],
    };
  }
  componentDidMount = async () => {
    let resp = await graphQLRequest(winners);
    if (resp) {
      this.setState({
        winners: getValue(resp, "winners", []),
      });
    }
    window.scroll({ top: 0, behavior: "smooth" });
  };
  render() {
    return (
      <>
        {/* <Header className="is-fixed" /> */}
        {/* <FeaturedCampaigns /> */}
        {/* <EarlyBirdCategory /> */}
        <HomeCategory />
        <HomeAllWinners />
        {/* <HomeWinners winners={this.state.winners} /> */}
        {/* <Footer social={this.state.social} /> */}
      </>
    );
  }
}
export default CampaignsList;
