import React, { useState, useEffect } from "react";
import { Input, Label } from "reactstrap";
import countriesData from "country-telephone-currency-data";
import Flag from "react-world-flags";
import Select from "react-select";

const InputField = (props) => {
  const [option, setOption] = useState([]);
  useEffect(() => {
    let options = [];
    countriesData.dialCodeValue.map((item) => {
      options.push({
        id: item.id,
        label: (
          <>
            <Flag code={item.id} height="16" /> {item.value}
          </>
        ),
        value: item.value,
      });
    });
    setOption(options);
  }, []);

  switch (props.inputType) {
    case "INPUT_REACT_SELECT_COUNTYCODE":
      return (
        <div className="form-group form-react-select">
          <Select
            type="select"
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            options={option}
            placeholder="Select"
            value={props.value}
          ></Select>
          {/* <Label className="on-hover on-top">Country Code</Label> */}
          {props.validator && (
            <span className="form-error">
              {props.validator.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
    case "TEXT":
      return (
        <div className="form-group">
          <Input
            type="text"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            autoComplete="off"
          />
          <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
    case "TEXTALPHA":
      return (
        <div className="form-group">
          <Input
            type="textarea"
            id={props.id}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            autoComplete="off"
            placeholder={props.placeholder}
            rows={props.rows}
          />
          <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
    case "EMAIL":
      return (
        <div className="form-group">
          <Input
            type="email"
            id={props.id}
            name={props.name}
            autoComplete="off"
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
          />
          <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.message(
                props.label,
                props.value,
                "required|email"
              )}
            </span>
          )}
        </div>
      );
    case "NUMBER":
      return (
        <div className="form-group">
          <Input
            type="number"
            id={props.id}
            name={props.name}
            autoComplete="off"
            onChange={(e) => props.onChange(e, props.setPath)}
            value={props.value}
            placeholder={props.placeholder}
          />
          <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.message(
                props.label,
                props.value,
                "required|integer"
              )}
            </span>
          )}
        </div>
      );
    case "PASSWORD":
      return (
        <div className="form-group">
          <Input
            type={!props.hide_show?"password":'text'}
            id={props.id}
            autoComplete="off"
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
            placeholder={props.placeholder}
          />
          <label className="on-hover" htmlFor={props.id}>
            {props.label}
          </label>
          {props.validator && (
            <span className="form-error">
              {props.validator.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
    case "INPUT_SELECT":
      return (
        <div className="form-group">
          <Input
            type="select"
            multiple={props.isMulti}
            name={props.name}
            onChange={(e) => props.onChange(e, props.setPath)}
          >
            <option>Select {props.label}</option>
            {props.options.map((items) => (
              <option
                value={items[props.valueInput]}
                selected={items.value === props.selectedItem ? true : false}
              >
                {items.value}
              </option>
            ))}
          </Input>
          <Label className="on-hover">{props.label}</Label>
          {props.validator && (
            <span className="form-error">
              {props.validator.message(props.label, props.value, "required")}
            </span>
          )}
        </div>
      );
    case "CHECKBOX":
      return (
        <label className="checkbox-container">
          {props.label}
          <input
            type="checkbox"
            onChange={() => props.onChange(props.list)}
            value={props.value}
            checked={props.checked(props.list)}
          />
          <span className="checkmark"></span>
        </label>
      );
    default:
      return <h1>Input not valid</h1>;
  }
};
export default InputField;
