import React from "react";
import { Button, Container } from "reactstrap";
import { NavLink } from "react-router-dom";
import graphQLRequestGlobal from "../../services/graphQlRequestGlobal";
import links from "../../services/Queries/social/links";
import { getValue } from "../../utils/lodash";
import { API_URL } from "../../env";
class BannerHome extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      banerImages: [],
    };
  }
  componentDidMount() {
    this.getInfo();
  }

  getInfo = async () => {
    let resp = await graphQLRequestGlobal(links);
    this.setState({
      banerImages: getValue(resp, "siteInfo.banner_images", []),
    });
  };

  render() {
    return (
      // <div className="banner" style={{ backgroundImage: "url('/images/banner.png')" }}>
      <div
        className="banner"
        style={{
          height: "500px",
          backgroundImage: `url(${API_URL}${getValue(
            this.state,
            "banerImages[0].url",
            ""
          )})`,
        }}
      >
        <Container fluid={true}>
          <div className="banner-text">
            <h1>
              Dream.
              <br />
              Shop.
              <br />
              Win.
            </h1>
            <NavLink to="/how-it-works">
              <Button color="white">How it Works</Button>
            </NavLink>
          </div>
        </Container>
      </div>
    );
  }
}
export default BannerHome;
