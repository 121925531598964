import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Button, Row, Col } from 'reactstrap';
class HomeCharities extends React.Component {
    render() {
        return (
            <>
                <section className="home-charities d-none d-md-block">
                    <Container fluid={true}>
                        <Row>
                            <Col md={{ size: "6" }} style={{ backgroundImage: "url('/images/charity.png')" }} className="full-charity-img">
                                {/* <img src="/images/charity.png" alt="" className="img-fluid" /> */}
                            </Col>
                            <Col md={{ size: "6" }}>
                                <div className="charity-right">
                                    <img src="/images/ring.svg" alt="" width="100" />
                                    <h2 className="home-heading1">Experience and share abundance with the power of giving</h2>
                                    <NavLink to="/charities" className="charity-btn"><Button color="white">LEARN more</Button></NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="home-charities d-md-none">
                    <Container className="p-0" fluid={true}>
                        <Row className="dontion">
                            <Col md={{ size: "12" }}>
                                <img src="/images/charity.png" alt="" className="img-fluid" />
                            </Col>
                            <Col md={{ size: "12" }}>
                                <div className="charity-right">
                                    <img src="/images/ring.svg" alt="" width="46" />
                                    <h2 className="home-heading1">Experience and share abundance with the power of giving</h2>
                                    <NavLink to="/charities" className="charity-btn"><Button color="white">LEARN more</Button></NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        )
    }
}
export default HomeCharities